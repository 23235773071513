.navmenu,
.navbar-offcanvas {
  width: 310px;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.navmenu-fixed-left,
.navmenu-fixed-right,
.navbar-offcanvas {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  overflow-y: auto;
  border-radius: 0;
}
.navmenu-fixed-left,
.navbar-offcanvas.navmenu-fixed-left {
  right: auto;
  left: 0;
  border-width: 0 1px 0 0;
}
.navmenu-fixed-right,
.navbar-offcanvas {
  right: 0;
  left: auto;
  border-width: 0 0 0 1px;
}
.navmenu-nav {
  margin-bottom: 10px;
}
.navmenu-nav.dropdown-menu {
  position: static;
  float: none;
  padding-top: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.navbar-offcanvas .navbar-nav {
  margin: 0;
}
@media (min-width: 768px) {
  .navbar-offcanvas {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .navbar-offcanvas.offcanvas {
    position: static;
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-offcanvas .navbar-nav.navbar-left:first-child {
    margin-left: -15px;
  }
  .navbar-offcanvas .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
  .navbar-offcanvas .navmenu-brand {
    display: none;
  }
}
.navmenu-brand {
  display: block;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: 18px;
  line-height: 20px;
}
.navmenu-brand:hover,
.navmenu-brand:focus {
  text-decoration: none;
}
.navmenu-default,
.navbar-default .navbar-offcanvas {
  background-color: #fff;
  border-color: #e7e7e7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.navmenu-default .navmenu-brand,
.navbar-default .navbar-offcanvas .navmenu-brand {
  color: #777;
}
.navmenu-default .navmenu-brand:hover,
.navbar-default .navbar-offcanvas .navmenu-brand:hover,
.navmenu-default .navmenu-brand:focus,
.navbar-default .navbar-offcanvas .navmenu-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
.navmenu-default .navmenu-text,
.navbar-default .navbar-offcanvas .navmenu-text {
  color: #777;
}
.navmenu-default .navmenu-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a:hover .caret,
.navmenu-default .navmenu-nav > .dropdown > a:focus .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a:focus .caret {
  border-top-color: #333;
  border-bottom-color: #333;
}
.navmenu-default .navmenu-nav > .open > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a,
.navmenu-default .navmenu-nav > .open > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:hover,
.navmenu-default .navmenu-nav > .open > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav > .open > a .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a .caret,
.navmenu-default .navmenu-nav > .open > a:hover .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:hover .caret,
.navmenu-default .navmenu-nav > .open > a:focus .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:focus .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}
.navmenu-default .navmenu-nav > .dropdown > a .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}
.navmenu-default .navmenu-nav.dropdown-menu,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu {
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav.dropdown-menu > .divider,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .divider {
  background-color: #f8f8f8;
}
.navmenu-default .navmenu-nav.dropdown-menu > .active > a,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a,
.navmenu-default .navmenu-nav.dropdown-menu > .active > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:hover,
.navmenu-default .navmenu-nav.dropdown-menu > .active > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:focus {
  background-color: #d7d7d7;
}
.navmenu-default .navmenu-nav > li > a,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a {
  color: #777;
}
.navmenu-default .navmenu-nav > li > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:hover,
.navmenu-default .navmenu-nav > li > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:focus {
  color: #333;
  background-color: transparent;
}
.navmenu-default .navmenu-nav > .active > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a,
.navmenu-default .navmenu-nav > .active > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:hover,
.navmenu-default .navmenu-nav > .active > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav > .disabled > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a,
.navmenu-default .navmenu-nav > .disabled > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a:hover,
.navmenu-default .navmenu-nav > .disabled > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navmenu-inverse,
.navbar-inverse .navbar-offcanvas {
  background-color: #222;
  border-color: #080808;
}
.navmenu-inverse .navmenu-brand,
.navbar-inverse .navbar-offcanvas .navmenu-brand {
  color: #999;
}
.navmenu-inverse .navmenu-brand:hover,
.navbar-inverse .navbar-offcanvas .navmenu-brand:hover,
.navmenu-inverse .navmenu-brand:focus,
.navbar-inverse .navbar-offcanvas .navmenu-brand:focus {
  color: #fff;
  background-color: transparent;
}
.navmenu-inverse .navmenu-text,
.navbar-inverse .navbar-offcanvas .navmenu-text {
  color: #999;
}
.navmenu-inverse .navmenu-nav > .dropdown > a:hover .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a:hover .caret,
.navmenu-inverse .navmenu-nav > .dropdown > a:focus .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navmenu-inverse .navmenu-nav > .open > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a,
.navmenu-inverse .navmenu-nav > .open > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:hover,
.navmenu-inverse .navmenu-nav > .open > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:focus {
  color: #fff;
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav > .open > a .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a .caret,
.navmenu-inverse .navmenu-nav > .open > a:hover .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:hover .caret,
.navmenu-inverse .navmenu-nav > .open > a:focus .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navmenu-inverse .navmenu-nav > .dropdown > a .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a .caret {
  border-top-color: #999;
  border-bottom-color: #999;
}
.navmenu-inverse .navmenu-nav.dropdown-menu,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu {
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav.dropdown-menu > .divider,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .divider {
  background-color: #222;
}
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a,
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:hover,
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:focus {
  background-color: #000;
}
.navmenu-inverse .navmenu-nav > li > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a {
  color: #999;
}
.navmenu-inverse .navmenu-nav > li > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a:hover,
.navmenu-inverse .navmenu-nav > li > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}
.navmenu-inverse .navmenu-nav > .active > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a,
.navmenu-inverse .navmenu-nav > .active > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a:hover,
.navmenu-inverse .navmenu-nav > .active > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a:focus {
  color: #fff;
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav > .disabled > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a,
.navmenu-inverse .navmenu-nav > .disabled > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a:hover,
.navmenu-inverse .navmenu-nav > .disabled > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}

.offcanvas {
  display: none;
}
.offcanvas.in {
  display: block;
}
@media (max-width: 767px) {
  .offcanvas-xs {
    display: none;
  }
  .offcanvas-xs.in {
    display: block;
  }
}
@media (max-width: 991px) {
  .offcanvas-sm {
    display: none;
  }
  .offcanvas-sm.in {
    display: block;
  }
}
@media (max-width: 1199px) {
  .offcanvas-md {
    display: none;
  }
  .offcanvas-md.in {
    display: block;
  }
}
.offcanvas-lg {
  display: none;
}
.offcanvas-lg.in {
  display: block;
}
.canvas-sliding {
  -webkit-transition: top .35s, left .35s, bottom .35s, right .35s;
          transition: top .35s, left .35s, bottom .35s, right .35s;
}
.offcanvas-clone {
  position: absolute !important;
  top: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  border: none !important;
  opacity: 0 !important;
}

/*Buscador en navmenu*/

.navmenu ul{}

.navmenu li.help-block{
  padding: 15px;
  margin-bottom: 15px;
}

/*Li en navmenu*/
.navmenu ul li.side-title{
  padding-left: 15px;
  padding-right: 15px;
}

.navmenu ul li a.inline, .navmenu ul li ul li a.inline{
  display: inline-block;
}

.navmenu ul li a{
  padding: 5px 5px;
  border-radius: 0;
  font-weight:bold;
  color:#086cb1;
}

.navmenu ul li a.fav{
  padding:0;
  display:inline;
  margin:5px 5px 0 10px;
  float:left;
}

.navmenu ul li ul li a.fav{
  padding:0;
  margin:3px 5px ;
  float:left;
  display:inline;
}

.navmenu hr{
  margin: 10px 0;
}

.navmenu li ul{
  /*padding:10px 0 10px 10px;*/
  margin:0;
  list-style:none;
  background:#eeeeee;
}

.navmenu li ul li{
  line-height:130%;
}

.navmenu li ul li a{
  font-size:13px;
  color:#0079C1;
  font-weight:normal;
}

/* .navmenu li ul li a:hover{
  color:;
} */

.logo-block{
  /* background:url(../iconstruye.jpg); */
  width:200px;
  height:100px;
}

/*----- Menu Agilice-----*/

.logo-agilice{
  padding:10px;
  opacity:0.2;
  text-align:center;
}

ul.nav-agilice{
  padding:10px;
}
ul.nav-agilice li{
  
}
ul.nav-agilice li a{
  font-size: 1em;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 0.8em;
}

.navmenu ul.nav-agilice li.side-title{
  color: #666;
  font-size: 1em;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0 0 0 5px;
  text-transform: uppercase;
}

/*----- Clases para favoritos ----- */

#MainMenu li a i.fa.fa-star-o{
  color:#999;
}

#MainMenu li a:hover i.fa.fa-star-o{
  color:orange;
}

#MainMenu li a i.fa.fa-star{
  color:orange;
}
