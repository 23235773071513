/*
Flyout
*/

/*Div Flyout*/
.flyoutbox_show #bsa_flyout{
	right: 0;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.5s;
}
.flyoutbox_hide #bsa_flyout{
	right: -382px;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
#bsa_flyout{
	position: fixed;
	z-index: 10001;
	bottom: 0;
	right: -382px;
	border-radius: 3px 0 0 0;
}
a#bsa_close{
	cursor: pointer;
	position: absolute;
	right: 10px;
	/*font-size: 12px;*/
	top: 0;
	line-height: 100%;
	padding: 5px 3px;
	/*border-radius: 3px;
	background: #eee;
	border: 1px solid #ddd;
	border-top: none;*/
	/*color: #666;*/
	text-decoration: none;
	z-index: 10002;
}
a.bsa_creative{
	background-color: #f9f9f9;
	overflow: hidden;
	width: 380px;
	/*height: 125px;*/
	height: 100px;
	display: block;
	border-left: 1px solid #eee;
	border-top: 1px solid #eee;
	border-radius: 3px 0 0 0;
}

/*Para alerta*/
a.bsa_alert{
	background-color: #f2dede;
	overflow: hidden;
	width: 380px;
	/*height: 125px;*/
	height: 100px;
	display: block;
	border-left: 1px solid #ebccd1;
	border-top: 1px solid #ebccd1;
	border-radius: 3px 0 0 0;
}

a.bsa_creative img{
	width: 125px;
	float: left;
	border-radius: 3px 0 0 0;
	max-width: 100%;
	height: auto;
}
a.bsa_creative span.bsa_flyout-content, a.bsa_alert span.bsa_flyout-content{
	padding: 10px 30px 10px 10px;
	width: 254px;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
a.bsa_creative span.bsa_flyout-content h2, a.bsa_alert span.bsa_flyout-content h2 {
	font-size: 1em;
	line-height: 1.5em;
	clear: both;
	margin: 0;
	color: #333;
}
h3.title-extend{
	font-size: 0.7em;
	text-transform: uppercase;
	letter-spacing: .3em;
	margin: 0 0 3px 0;
	color: #333;
	font-weight: bold;
}
.modal-header h3.title-extend{
	font-size: 1.1em;
	margin: 10px 0 20px 0;
}

a.bsa_alert span.bsa_flyout-content h2 {
	color: #a94442;
}
