.flex-text-wrap {
    position: relative;

    zoom: 1;
}

textarea,
.flex-text-wrap {
    outline: 0;
    margin: 0;
    border: none;
    padding: 0;

    padding-bottom: 0 !important;
}

.flex-text-wrap textarea,
.flex-text-wrap pre {
    white-space: pre-wrap;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    white-space: pre;
    word-wrap: break-word;
}

.flex-text-wrap textarea {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    resize: none;

    /* IE7 box-sizing fudge factor */
    height: 94%;
    width: 94%;
}

.flex-text-wrap pre {
    display: block;
    visibility: hidden;
}

textarea,
.flex-text-wrap pre {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    /*
     * Add custom styling here
     * Ensure that typography, padding, border-width (and optionally min-height) are identical across textarea & pre
     */
}
