@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

/*====================================================
PROPUESTA DE ORGANIZACIÓN DE ESTILOS CSS iC
====================================================*/

/* INDICE

TIPOGRAFÍA GLOBAL
MENÚ DE NAVEGACIÓN UNIFICADO
MARGIN HACKS
PADING HACKS
FONDOS DE NIVEL
ETIQUETAS ESTRUCTURALES
CLASES AUXILIARES
COMPONENTES ESPECIALES
ESTILOS AGILICE
ESTILOS ICONSTRUYE
ESTILOS BCI
ESTILOS SITIO DISENO
ESTILOS SITIO PÚBLICO IC
ESTILOS SITIO PÚBLICO AGILICE
ESTILOS DASHBOARD

*/
body {
  background: #f6f7fa;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}
body.dark {
  background: #666;
}
/* ---------- TIPOGRAFÍA GLOBAL -------------------- */

a {
  /*color: #E66819;*/
  /*color: #0072C5;*/
  color: #0994ff;
  cursor: pointer;
  pointer-events: auto;
}
a:hover {
  /*color: #C94702;*/
  color: #0994ff;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: auto;
}
a.tdnone:hover,
a.tdnone:focus {
  text-decoration: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-bottom: 20px;
  line-height: 1.4em;
}
h4,
h5 {
  /*margin-bottom:20px;*/
  line-height: 1.5em;
}
p {
  margin-bottom: 10px;
}
small p {
  margin-bottom: 5px;
}
.text-success {
  color: #5cb85c !important;
}
.text-danger {
  color: #be3535 !important;
}
/*Estilso tipográficos para roboto*/
.bold {
  font-weight: 700;
}
/*p.text-warning,
h3.text-warning,
.text-warning{
	color: #d89e0e !important;
}*/
/*p .text-warning{
	color: #d89e0e;
}*/
pre {
  border: none;
  border-radius: 2px;
  color: #555;
}
code {
  color: #555;
  background: #f5f5f5;
  font-size: 13px;
}
hr {
  border-top-color: #e1e1e1;
}
.dosem {
  font-size: 2em;
}
.title-expanded {
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 1.5em;
}

.text-align-justify {
  text-align: justify;
}

/* ---------- MENÚ DE NAVEGACIÓN UNIFICADO -------------------- */

/* ---------- MARGIN HACKS -------------------- */

.m-3top {
  margin-top: -3px !important;
}
.m-1bottom {
  margin-bottom: -1px !important;
}
.m15left {
  margin-left: 15px !important;
}
.m0right {
  margin-right: 0px !important;
}
.m5right {
  margin-right: 5px;
}
.m5top {
  margin-top: 5px !important;
}
.m25top {
  margin-top: 25px !important;
}
.m49top {
  margin-top: 49px !important;
}
.m50top {
  margin-top: 50px !important;
}
.m100top {
  margin-top: 100px !important;
}
.m55top {
  margin-top: 55px !important;
}
.m0bottom {
  margin-bottom: 0 !important;
}
.m5bottom {
  margin-bottom: 5px !important;
}
.m0top {
  margin-top: 0 !important;
}
.m0left {
  margin-left: 0 !important;
}
.m5left {
  margin-left: 5px !important;
}
.m26left {
  margin-left: 26px !important;
}
.m10left {
  margin-left: 10px !important;
}
.m30left {
  margin-left: 30px !important;
}
.m40left {
  margin-left: 30px !important;
}
.m100left {
  margin-left: 100px !important;
}
.m15right {
  margin-right: 15px !important;
}
.m10bottom {
  margin-bottom: 10px !important;
}
.m15bottom {
  margin-bottom: 15px !important;
}
.m20bottom {
  margin-bottom: 20px !important;
}
.m30bottom {
  margin-bottom: 30px !important;
}
.m10top {
  margin-top: 10px !important;
}
.m15top {
  margin-top: 15px !important;
}
.m30top {
  margin-top: 30px !important;
}
.m20top {
  margin-top: 20px !important;
}
.m25top {
  margin-top: 25px !important;
}
.m35top {
  margin-top: 35px;
}
.m40top {
  margin-top: 40px;
}
.m70top {
  margin-top: 70px !important;
}
.m50top-p {
  margin-top: 50% !important;
}
.m120top {
  margin-top: 120px;
}
.m20left {
  margin-left: 20px !important;
}
.m10right {
  margin-right: 10px !important;
}
.m20right {
  margin-right: 20px !important;
}
.m40left {
  margin-left: 40px !important;
}
.m60left {
  margin-left: 60px !important;
}

/* ---------- PADDING HACKS -------------------- */

.p0 {
  padding: 0 !important;
}
.p5 {
  padding: 5px !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
.p17 {
  padding: 17px;
}
.p20 {
  padding: 20px !important;
}
.p25 {
  padding: 25px !important;
}
.p29 {
  padding: 29px !important;
}
.p30 {
  padding: 30px !important;
}
.p40 {
  padding: 40px !important;
}
/* .pxtop */
.p0top {
  padding-top: 0 !important;
}
.p2top {
  padding-top: 2px !important;
}
.p5top {
  padding-top: 5px !important;
}
.p7top {
  padding-top: 7px !important;
}
.p7top {
  padding-top: 7px !important;
}
.p10top {
  padding-top: 10px !important;
}
.p20top {
  padding-top: 20px !important;
}
.p30top {
  padding-top: 30px !important;
}
.p40top {
  padding-top: 40px;
}
.p100top {
  padding-top: 100px;
}
.p120top {
  padding-top: 120px;
}
/* .pXleft */
.p0left {
  padding-left: 0 !important;
}
.p5left {
  padding-left: 5px !important;
}
.p5left {
  padding-left: 5px;
}
.p10left {
  padding-left: 10px !important;
}
.p15left {
  padding-left: 15px;
}
.p20left {
  padding-left: 20px !important;
}
.p25left {
  padding-left: 25px !important;
}
.p30left {
  padding-left: 30px !important;
}

.p33left {
  padding-left: 33px !important;
}

.p35left {
  padding-left: 35px !important;
}

.p40left {
  padding-left: 40px !important;
}
.p60left {
  padding-left: 60px !important;
}
.p20right {
  padding-right: 20px !important;
}
.b0top {
  border-top: 0 !important;
}
.b1top {
  border-top: 1px solid #eee;
}
.b0bottom {
  border-bottom: none !important;
}
.b1bottom {
  border-bottom: 1px solid #ddd !important;
}
.b1left {
  border-left: 1px solid #ddd !important;
}
.b0 {
  border: none !important;
}
.m1neg {
  margin-top: -1px;
}

/* .pXright */

.p0right {
  padding-right: 0 !important;
}
.p5right {
  padding-right: 5px !important;
}
.p0right {
  padding-right: 0;
}
.p3right {
  padding-right: 3px !important;
}
.p10right {
  padding-right: 10px !important;
}
.p30right {
  padding-right: 30px !important;
}

/* .pXbottom */
.p0bottom {
  padding-bottom: 0px !important;
}
.p2bottom {
  padding-bottom: 2px !important;
}
.p5bottom {
  padding-bottom: 5px !important;
}
.p10bottom {
  padding-bottom: 10px !important;
}
.p25bottom {
  padding-bottom: 25px !important;
}
.p100bottom {
  padding-bottom: 100px;
}

/* ---------- BORDERS HACKS -------------------- */
.b0top {
  border-top: 0 !important;
}
.b0bottom {
  border-bottom: 0 !important;
}
.b0left {
  border-left: 0 !important;
}
.b0right {
  border-left: 0 !important;
}
.b1top {
  border-top: 1px solid #ddd;
}
.b1left {
  border-left: 1px solid #ddd;
}
.b1right {
  border-right: 1px solid #ddd;
}
.b1right-neg {
  border-right: 1px solid #ddd;
  margin-right: -1px;
}
.b1bottom {
  border-bottom: 1px solid #ddd;
}
.w200 {
  width: 200px !important;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
/*============================
Row with equal height columns
==============================*/
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
/* ---------- FONDOS DE NIVEL -------------------- */

.intro {
  min-height: 150px;
  position: relative;
}
.intro.img-bg {
  background: url(/assets/img/bg-construccion3.jpg) no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.intro h2 {
  margin-top: 100px;
}
.intro.img-bg h2 {
  color: #fff;
}
.intro.img-bg a.btn {
  margin-top: 93px;
  border: 1px solid #ddd;
  background-color: none;
  color: #fff;
}
.intro.img-bg a:hover.btn {
  background-color: #fff;
  color: #e66819;
}
.full {
  background: #f5f5f5;
}
.full-light {
  background: #f9f9f9;
}
.full-btn {
  background-color: #f5f5f5;
  border-top: 1px solid #e9e9e9;
  margin-top: 20px;
  padding: 20px 0;
}

/* ---------- ETIQUETAS ESTRUCTURALES -------------------- */

/* ---------- CLASES AUXILIARES -------------------- */

.db {
  display: block;
}
.clear {
  clear: both;
}
.bnonetop {
  border-top: none !important;
}

.w100 {
  width: 100% !important;
}
.w50 {
  width: 50% !important;
}
.w55 {
  width: 55% !important;
}
.w70 {
  width: 70% !important;
}
.w75 {
  width: 75% !important;
}
.w150 {
  width: 150px !important;
}
.w105 {
  width: 105px !important;
}
.w200 {
  width: 200px !important;
}

.w250 {
  width: 250px !important;
}
.w300 {
  width: 300px !important;
}
.w60 {
  width: 60px !important;
}
.w80 {
  width: 80px !important;
}
.h30 {
  height: 30px !important;
}
.h20 {
  height: 20px !important;
}
.vh100 {
  height: 100vh;
}
.align-left {
  text-align: left !important;
}
.fweight-normal {
  font-weight: normal;
}
.valign-middle {
  vertical-align: middle !important;
}
.max-width {
  max-width: 970px !important;
}
.f20 {
  font-size: 20px;
}
.f16 {
  font-size: 16px;
}
.f15 {
  font-size: 15px;
}
.f14 {
  font-size: 14px;
}
.f13 {
  font-size: 13px;
}
.f12 {
  font-size: 12px;
}
.f32 {
  font-size: 32px;
}
.f50 {
  font-size: 50px;
}
.f200 {
  font-size: 200px;
}

.line-height19 {
  line-height: 1.9;
}

.f12 .checkbox input[type="checkbox"],
.f12 .radio input[type="radio"],
.f12 .radio-inline input[type="radio"],
.f12 .checkbox-inline input[type="checkbox"] {
  margin-top: 1px;
}
.f11 {
  font-size: 11px;
}
.f11 .form-control,
.f11 .bootstrap-select .btn {
  font-size: 11px;
}
.f10 {
  font-size: 10px;
}
/*CSS cursor Property*/
.cursor-alias {
  cursor: alias;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-auto {
  cursor: auto;
}
.cursor-cell {
  cursor: cell;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-copy {
  cursor: copy;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-default {
  cursor: default;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-grab {
  cursor: grab;
}
.cursor-grabbing {
  cursor: grabbing;
}
.cursor-help {
  cursor: help;
}
.cursor-move {
  cursor: move;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-no-drop {
  cursor: no-drop !important;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-text {
  cursor: text;
}
/* .cursor-url {cursor: url(myBall.cur),auto;} */
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-wait {
  cursor: wait;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
/* ---------- ELEMENTOS ESPECIALES DE LA INTERFAZ Y RESETS-------------------- */

/*  Botones  */
.btn {
  border-radius: 2px;
  cursor: pointer !important;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  outline: none !important;
}
.btn-primary {
  background-color: #0994FF;
  border-color: #0994FF;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #2a6496;
  border-color: #2a6496;
  color: #fff;
}
.btn-link {
  color: #0072c5;
}
.btn-link:hover {
  color: #2a6496;
  text-decoration: none;
}
.btn-default {
  color: #4b4b4b;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 3px 5px;
  font-size: 11px;
  line-height: 1.6em;
}
.btn-line-green {
  border: 1px solid #47a447;
  color: #47a447;
  background: #fff;
}
.btn-line-green:hover,
.btn-line-green:focus {
  border: 1px solid #47a447;
  color: #fff;
  background: #47a447;
}

/*boton volver en pagehader*/
.btn-back {
  /*border-right: 1px solid #e1e1e1;*/
  padding-right: 5px;
  margin-right: 5px;
  font-size: 90%;
}
.btn-back-sobre {
  padding-right: 5px;
  margin-right: 5px;
  font-size: 90%;
}

/* Reset Select Bootstrap */
.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  padding-left: 20px !important;
}
.bootstrap-select.btn-group .dropdown-menu dt {
  font-weight: normal;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #999;
}
.btn-addon button {
  border-radius: 2px 0 0 2px !important;
  border-right: none;
  min-width: 60px;
}
/* Notificaciones */
.notification {
  position: fixed;
  bottom: 0px;
  right: 20px;
  min-width: 400px;
  z-index: 9;
}
.notification .progress {
  margin-bottom: 0px;
  margin-top: 5px;
  height: 3px;
  width: 100%;
}

/*.notification .progress-bar:hover{
	background: #428bca;
}	*/

/* -------------------- Reset breadcrumb -------------------- */
.breadcrumb {
  border-radius: 0;
  /*margin-top: -20px;*/
  border-bottom: 1px solid #e1e1e1;
  padding: 7px 15px;
  line-height: 0;
}
.breadcrumb.breadcrumb-fixed {
  position: fixed;
  width: 100%;
  z-index: 2;
}
.resultado .breadcrumb {
  margin-top: 0;
}
/* -------------------- Reset Alert -------------------- */
.alert {
  border-radius: 2px;
}
.alert-messages {
  /*margin-top: -250px;*/
  position: fixed;
  top: 48px;
  z-index: 7000;
  left: 15%;
  right: 15%;
  box-shadow: 0px 0px 7px #ccc;
  min-height: 60px;
}
.alert-messages .close {
  padding: 3px 10px;
}
/*.alert-warning{
	color:#d89e0e;
}*/

/*Envoltorio ícono en alert*/
.alert .wrap-icon {
  padding: 15px;
  margin-right: 20px;
  position: absolute;
  left: 0;
  width: 90px;
  bottom: 0;
  top: 0;
  border-radius: 2px 0 0 2px;
  border-right: 1px solid #ddd;
}
.alert .wrap-icon .fa {
  text-align: center;
  display: block;
}
/*Colores para ícono en alert*/
.alert .wrap-icon.danger {
  border-right: 1px solid #ebccd1;
}
.alert .wrap-icon.info {
  border-right: 1px solid #bdd4ea;
}
.alert .wrap-icon.success {
  border-right: 1px solid #d6e9c6;
}
.alert .wrap-icon.warning {
  border-right: 1px solid #faebcc;
}
/*Envoltorio texto en alert*/
.alert .wrap-text {
  padding: 10px 30px 10px 10px;
  margin-left: 100px;
}
.alert-info .sin-info {
  min-height: 170px;
  padding: 30px;
}

/*Container*/
.container-fluid {
  /*padding-left: 40px;
	padding-right: 40px;*/
}

.container-interior {
  padding-left: 15px;
  padding-right: 0;
}

.container-section {
  padding-left: 15px;
  padding-right: 15px;
}

/*Financiamiento > Cesiones > Registro de Cesiones > Motivo Objeción*/
#Motivo blockquote {
  border: none;
  margin-bottom: 0px;
}
#Motivo blockquote p {
  color: #4b4b4b;
  font-size: 0.8em !important;
}
#Motivo blockquote p.lead {
  font-size: 1.1em !important;
  margin-bottom: 5px;
}
#Motivo blockquote .heading {
  font-style: normal;
}

/* -------------------- Financiamiento > Cesiones > Box iguales -------------------- */
.wrap-same {
  height: 280px;
  position: relative;
}
.wrap-same-small {
  height: 180px;
  position: relative;
}
.wrap-same .btn,
.wrap-same-small .btn {
  position: absolute;
  bottom: 20px;
}

/* -------------------- Reset Affix -------------------- */
.affix {
  top: 0px;
  z-index: 999;
  right: 0;
  left: 0;
}
.affix.table-options {
  border-radius: 0;
  border-top: none;
  padding-left: 57px;
  padding-right: 57px;
  border-bottom-width: 2px;
}

/* -------------------- Reset Modals -------------------- */
.modal-header {
  background: #f9f9f9;
}
.modal-content {
  border-radius: 3px;
  border: 1px solid #bbb !important;
  box-shadow: none !important;
}
.bg-modal-content {
  background: #eee !important;
}
.modal-body {
  background: #fff;
}
.modal-backdrop {
  background: #000;
}
.modal-backdrop.in {
  opacity: 0.3;
}
.modal-full {
  width: 96% !important;
  margin-top: 20px;
}
.modal-full .modal-content {
  margin: 0;
  /*margin-right: 3px\9;*/
}
.modal .nav-tabs {
  padding-left: 0px;
}

/* -------------------- Modal Operar -------------------- */
.modal .operar.list-group {
  margin: 0;
}
.modal .operar .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.modal .operar .list-group-item:last-child {
  border-bottom: 0;
  margin-bottom: 1px;
}
.modal .operar .list-group-item.active .text-muted {
  color: #15324b;
}

/* -------------------- Modal Detalle Documento -------------------- */
.modal.wrap-modal-full table tr td:first-child {
  color: #999;
}
.modal.wrap-modal-full .wrap-modal-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  right: 0;
}
.modal.wrap-modal-full .modal-header {
  margin: 0 20px;
  border: 1px solid #bbb;
  margin-top: -1px;
  border-radius: 0;
}
.modal.wrap-modal-full .modal-content {
  margin-top: 50px;
}
.modal.wrap-modal-full .side-notorius {
  border-radius: 2px;
  border-left: 1px solid #ddd;
}

/* -------------------- Reset Panels -------------------- */
.panel {
  box-shadow: none;
  border-radius: 3px;
}
.panel-default > .panel-heading.white {
  background: #fff;
}
/* .panel-footer {
  line-height: 0;
} */
.panel-footer p.resultados {
  line-height: 2em;
  margin-bottom: 0px;
  padding: 1rem;
}
/* -------------------- Red (Inicio Canal) -------------------- */
.panel-bienvenida {
  min-height: 124px;
}
.panel-red {
  margin-right: -2px;
  border-radius: 2px 0 0 2px;
  min-height: 124px;
}

#emision-collapse .panel-heading {
  position: relative;
  top: 0;
}

/* -------------------- Reset Forms -------------------- */
.form-control {
  border-radius: 2px;
}
.form-control:focus {
  border-color: #0072c5;
  box-shadow: none;
}
.form-group {
  margin-bottom: 10px;
}
.form-group label {
  font-size: 12px;
}
.radio-inline {
  line-height: 2em;
}
/* -------------------- Reset padding en label doc-content -------------------- */
.doc-content .form-horizontal .control-label,
.doc-content .form-horizontal .radio,
.doc-content .form-horizontal .checkbox,
.doc-content .form-horizontal .radio-inline,
.doc-content .form-horizontal .checkbox-inline {
  padding-top: 5px;
}
/*.radio, .checkbox{
	margin-bottom: 15px;
}*/

/* -------------------- Reset .radio y .checkbox (divs en tablas) -------------------- */
table .radio,
table .checkbox {
  margin: 0;
}

/* -------------------- Reset Styles para documento en tab -------------------- */
.doc-content.in-tab .form-group {
  margin-bottom: 0 !important;
}
/* -------------------- Reset border radius -------------------- */
.btn-lg,
.btn-group-lg > .btn {
  border-radius: 3px;
}
/* ---------- Reset inputs -------------------- */

.input-xs {
  height: 25px;
  padding: 2px 5px;
  font-size: 11px;
  line-height: 1.5; /* If Placeholder of the input is moved up, rem/modify this. */
  border-radius: 2px;
}
.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
  height: 25px;
  padding: 2px 5px;
  font-size: 11px;
  line-height: 1.5;
  /*min-width: 60px;*/
}
.input-group-addon {
  border-radius: 2px;
}
.input-group-xs > .reduce-btn > .btn {
  min-width: 60px;
}
.filtros .input-group-addon.beautiful input[type="checkbox"],
.filtros .input-group-addon.beautiful input[type="radio"] {
  display: none;
}
/* -------------------- Reset Dropdown -------------------- */
.dropdown-menu {
  border-radius: 2px;
  margin-top: 2px !important;
}
.dropdown-menu > li > a {
  outline: none;
  padding: 3px 10px;
}
.dropdown-header {
  padding: 3px 10px;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #262626;
  text-decoration: none;
  outline: 0;
  background-color: #f5f5f5;
}
.dropdown-menu .divider {
  margin: 5px 0;
}
.dropdown-menu.dropdown-modal-action {
  width: 600px;
  right: 0px !important;
  left: inherit;
}
/* -------------------- Reset color para .text-muted en subtext de bootstrap-select -------------------- */
.dropdown-menu > .active > a:hover .text-muted,
.dropdown-menu > .selected.active > a .text-muted {
  color: #262626 !important;
}
.dropdown-menu.open ul.dropdown-menu li.selected a:focus .text-muted,
.dropdown-menu.open ul.dropdown-menu li a:hover .text-muted {
  color: #262626 !important;
}
/* -------------------- Reset Outline en Bootstrap Select -------------------- */
.bootstrap-select .btn:focus {
  outline: 0 !important;
}
/*Reset input Search*/
.bootstrap-select-searchbox input {
  width: 100% !important;
}

/*Reset Labels*/
.label {
  line-height: 2;
  border: none !important;
}
.label-success {
  border: 1px solid #91cf91;
  color: #449d44;
  background: #eaf6ea;
}
.label-success-rendicion {
  /*border:1px solid #91cf91;*/
  color: #388238;
  background: #cfeacf;
  padding: 7px;
  font-weight: 500;
}
.label-default {
  border: 1px solid #ddd;
  color: #768391;
  background: #eee;
}
.label-default-rendicion {
  color: #768391;
  background: #d1d5db;
  padding: 7px;
  font-weight: 500;
}
.label-default-sm {
  color: #333;
  background: #d1d5db;
  padding: 5px 7px;
  font-weight: 500;
}
.label-primary {
  border: 1px solid #555;
  color: #fff !important;
  background: #666;
  font-weight: normal;
}
.label-danger {
  border: 1px solid #f8dedd;
  color: #d9534f;
  background: #fbeeed;
}
.label-danger-rendicion {
  /*border:1px solid #f8dedd;*/
  color: #d9534f;
  background: #f4d0cd;
  padding: 7px;
  font-weight: 500;
}
.label-warning {
  border: 1px solid #f8dc96;
  color: #d89e0e;
  background: #fdf3dd;
}
.label-info1 {
  /*border:1px solid #9cd9eb;*/
  color: #00314b;
  background: #d5f0ff;
  padding: 7px;
  font-weight: 500;
}
.label-md {
  font-size: 1em;
}
.label-xs {
  text-transform: uppercase;
  padding: 2px 4px 1px 4px;
}
.label-rounded {
  border-radius: 999px;
  padding: 3px 10px;
}
/*Fix bootstrap dialog */
/*.modal.fade.in {
    background: none !important;
    top: 10%;
}*/

/* -------------------- Reset nav-pills -------------------- */
.nav-pills > li > a {
  border-radius: 2px;
}

/* -------------------- Reset navbar-pills -------------------- */
.table-options .nav-pills.nav-pills-dashboard > li.active > a,
.table-options .nav-pills.nav-pills-dashboard > li.active > a:hover,
.table-options .nav-pills.nav-pills-dashboard > li.active > a:focus {
  background: #c94702 !important;
}
.nav-pills.nav-pills-dashboard > li.active > a,
.nav-pills.nav-pills-dashboard > li.active > a:hover,
.nav-pills.nav-pills-dashboard > li.active > a:focus {
  background: transparent !important;
  color: #000 !important;
  font-weight: 700;
}
.nav-pills.nav-pills-dashboard > li.active > a:hover {
  color: #000 !important;
}
.nav-config > li > a {
  padding: 5px 0;
  border-radius: 0px;
}
.nav-config > li > a.active {
  font-weight: 700;
  border-right: 3px solid #0079c1;
  color: #5c5c5c;
}
.nav-config > li > a:hover {
  /*font-weight: 700;
	border-right: 3px solid #0079C1;*/
  color: #5c5c5c;
  background-color: transparent;
}
/* -------------------- New Pills -------------------- */
.sub-pills {
  margin: 10px 0 15px 0;
}
.sub-pills li {
  margin: 0 !important;
}
.sub-pills li a {
  border: 1px solid #e5e5e5;
  padding: 7px 14px;
  border-radius: 0 !important;
  margin-left: -1px;
  font-weight: bold;
}
.sub-pills li:first-child a {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 0 !important;
}
.sub-pills li:last-child a {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 3px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 3px !important;
}
.sub-pills li.active a {
  border-color: #0072c5;
  background: #0072c5;
  color: #fff !important;
  position: relative;
  z-index: 9;
}

/* -------------------- Pills en modal --------------------*/
.nav-tabs-modal li {
  margin: 0 !important;
}
.nav-tabs-modal li a {
  border-right: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
  background: #fff !important;
  color: #555;
  padding: 10px;
}
.nav-tabs-modal li a:hover,
.nav-tabs-modal li.active a:hover,
.nav-tabs-modal li a:focus,
.nav-tabs-modal li.active a:focus {
  background: #f1f1f1 !important;
  color: #0072c5 !important;
}
.nav-tabs-modal li.active a {
  background: #fff !important;
  border-right: 5px solid #0072c5;
  color: #0072c5;
  margin-right: -2px;
}
/* -------------------- Pills en panel --------------------*/
.panel .nav-pills.nav-stacked > li + li {
  margin-top: 0px;
  border-top: 1px solid #ddd;
}
.panel .nav-stacked li.active a {
  border-radius: 0px;
}

/*Reset Tabs*/
.nav-tabs {
  margin-bottom: 0px;
  padding-left: 20px;
}
.nav-tabs > li > a {
  border-radius: 2px 2px 0 0;
  margin-bottom: 0px;
  border: 1px solid #ddd !important;
}
.nav-tabs li a:hover {
  border-bottom: 1px solid #ddd !important;
  background: #f9f9f9;
}
.nav-tabs li.active a {
  color: #000;
  border-bottom: 1px solid #fff !important;
}

.nav-tabs.tab-gastos {
  border-bottom: none !important;
}
.nav-tabs.tab-gastos > li > a {
  border-radius: 2px 2px 0 0;
  margin-bottom: 0px;
  border: none !important;
}
.nav.tab-gastos > li > a {
  padding: 10px 2px;
}
/*.nav-tabs{
	border-bottom: 2px solid #f2f2f2;
}
.nav-tabs li a{
	border-bottom: 2px solid #f2f2f2;
	border-left: none;
	border-right: none;
	border-top: none;
	margin-bottom: -1px;
	border-radius: 0;
	color: #777;
}
.nav-tabs li a:hover{
	border-bottom: 2px solid #ccc;
	border-left: none;
	border-right: none;
	border-top: none;
	background: #fff;
}
.nav-tabs li.active a{
	border-bottom: 4px solid #000;
	margin-top: -2px;
	border-left: none;
	border-right: none;
	border-top: none;
	color: #000;
}
.nav-tabs li.active a:hover{
	border-bottom: 4px solid #000;
	margin-top: -2px;
	border-left: none;
	border-right: none;
	border-top: none;
	color: #000;
}
.nav-tabs li a.historial{
	background: #333;
	color: #fff;
	padding: 5px 10px;
	border-radius:2px;
	border:1px solid #000;
}
.nav-tabs li a.historial:hover{
	background: #111;
}
.nav-tabs li.active a.historial{
	background: #999;
	color: #fff;
	border:1px solid #888;
}*/

/*Canal > Sortable*/
/*ul.source, ul.target {
  min-height: 50px;
  margin: 0px 25px 10px 0px;
  padding: 2px;
  border-width: 1px;
  border-style: solid;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  list-style-type: none;
  list-style-position: inside;
}
ul.source {
  border-color: #f8e0b1;
  margin: 0 !important;
}
ul.source li{
	cursor: move;
}
ul.target {
  border-color: #add38d;
  margin:0 !important;
}
ul.target p{
	padding: 5px;
}
.source li, .target li {
  margin: 5px;
  padding: 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.source li {
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  color: #c09853;
}
.target li {
  background-color: #ebf5e6;
  border: 1px solid #d6e9c6;
  color: #468847;
}
.sortable-dragging {
  border-color: #ccc !important;
  background-color: #fafafa !important;
  color: #bbb !important;
}
.sortable-placeholder {
  height: 40px;
}
.source .sortable-placeholder {
  border: 1px dashed #f8e0b1 !important;
  background-color: #fefcf5 !important;
}
.target .sortable-placeholder {
  border: 1px dashed #add38d !important;
  background-color: #f6fbf4 !important;
}*/

/*Libro > Detalle (Reset label para vista Libros Detalle)*/
.label-top {
  position: relative;
  top: -5px;
}

/* Navbar header y logo */
.navbar-header {
  float: left;
}
.navbar-nav > li {
  font-size: 14px;
}
a.navbar-brand {
  /*color: #C94702 !important;*/
  color: #fff;
  padding: 10px;
  height: auto;
}
.logo-op {
  margin: 75px 0 25px 0;
  max-width: 160px;
}
.logo-op-ic {
  margin: 25px 0;
  max-width: 260px;
}
.navbar-text {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #15324b !important;
}
.navbar-sub .navbar-text {
  margin-top: 12px;
  margin-bottom: 7px;
}

/*Header Título para accesos externos*/
#HeadExt h2,
#HeadExt small {
  color: #ccc;
}
#HeadExt h2 a {
  color: #999;
}
#HeadExt p {
  padding-top: 33px;
  color: #aaa;
}
.wrap-brand h2 {
  display: none;
}
.wrap-brand img {
  width: 230px;
  padding-top: 20px;
}

/*Ajuste de padding en Brand para sitios "externos" a IC; Financiamiento u otros*/
a.navbar-brand.ext {
  padding: 12px 10px 10px 10px;
  height: auto;
  font-size: 14px;
  font-weight: bold;
  color: #999 !important;
  margin: 0 auto;
}

/* Always apply the floated nav */
.navbar-nav {
  float: left;
  margin: 0;
}
.navbar-nav > li {
  float: left;
  margin: 0 1px 0 0;
}
.navbar-nav > li > a {
  padding: 10px;
}

/* Redeclare since we override the float above */
.navbar-nav.navbar-right {
  float: right;
}
.navbar-nav.navbar-right:last-child {
  margin-right: 0;
}
.navbar-nav.navbar-right .dropdown-menu > li > a {
  white-space: normal;
}
/* Reset Dropdowns en menú principal */
.navbar .navbar-nav .open .dropdown-menu {
  position: absolute;
  float: left;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin-top: 5px;
}

/* ---------- CLASES PERSONALIZADAS AGILICE -------------------- */

/* Navbar */
.navbar-agilice {
  background: #0072c5;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  min-height: 0;
}
.navbar-agilice li.user-block a:hover {
  background: none !important;
  color: #2a6496 !important;
}
.navbar-agilice .nav .open > a,
.navbar-agilice .nav .open > a:hover,
.navbar-agilice .nav .open > a:focus {
  background: #2a6496;
  color: #fff !important;
}

.navbar-sub .nav .open > a,
.navbar-sub .nav .open > a:hover,
.navbar-sub .nav .open > a:focus {
  background: none;
  color: #0079c2 !important;
}
.navbar-fixed-top {
  top: 0;
}

/*------*/

/* ---------- CLASES PERSONALIZADAS iConstruye -------------------- */

/* ---------- CLASES PERSONALIZADAS BCI -------------------- */

/* ---------- CLASES SITIO iC -------------------- */

/* ---------- CLASES SITIO DISENO -------------------- */

/* ---------- ESTILOS DASHBOARD -------------------- */

/* -------------------- Div que contiene lista de checkbox -------------------- */
.checkbox-list .checkbox {
  margin: 0;
}

.f15 {
  font-size: 15px;
}
.mw75 {
  min-width: 75px !important;
}
.mh700 {
  min-height: 700px !important;
}
.w90 {
  width: 90px !important;
}
.mw100 {
  min-width: 100px;
}
.mw120 {
  min-width: 120px;
}
.mw155 {
  min-width: 155px;
}
.m10neg {
  margin-bottom: -10px !important;
  position: relative;
}
.p-relative {
  position: relative;
}
.rnone {
  resize: none;
}
.rv {
  resize: vertical;
}
.no-border {
  border: none !important;
}
.btop {
  border-top: 1px solid #ddd;
}

/* -------------------- Reset Close Modal -------------------- */
.modal-header .close {
  margin-top: -5px;
  font-size: 2.1em;
  opacity: 0.7;
}
.close:hover {
  opacity: 0.99;
}
.close:focus {
  outline: none;
}
/*.prev,
.next{
	position: absolute;
	top: 100px;
}
.prev{
	left: -35px;
}
.next{
	right: -35px;
}*/

/*Reset alert-info hr*/
.alert-info hr {
  border-color: #bdd4ea;
}

/*Reset Tooltip*/
/*.tooltip{
	opacity: 1;
	position: static;
	font-size: 0.8em;
	display: inline;
}*/
.tt-custom {
  border-radius: 2px;
  border: 1px solid #ddd !important;
  background: #fff;
  color: #333;
}
.tooltipster-arrow-top span,
.tooltipster-arrow-top .tooltipster-arrow-border,
.tooltipster-arrow-bottom span,
.tooltipster-arrow-bottom .tooltipster-arrow-border {
  bottom: -8px !important;
}
/* Use this next selector to style things like font-size and line-height: */
.tt-custom .tooltipster-content {
  font-size: 12px;
  line-height: 1.3em;
  padding: 8px 10px;
}
.tt-custom .tooltipster-content ul {
  margin-left: 15px;
  margin-bottom: 0;
  padding: 0;
}
.tt-custom .tooltipster-content ul li.dropdown-header {
  padding-left: 0px;
}

/*Menú*/
.navbar-default {
  background: #086cb1;
  /*background: #36a9e1;*/
  /*background: #E66819;*/
  /*background: #74a4c1;*/
  /*background: #7492c1;*/
  /*background: #8baacf;*/
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
  /*transition: all .1s linear;
	-moz-transition: all .1s linear;
	-webkit-transition: all .1s linear;*/
  margin-right: 5px;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  /*background: #C94702;*/
  background: #1d71b8;
  color: #fff;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  /*background: #C94702;
	color: #fff;*/
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  /*background: #C94702;*/
  background: #1d71b8;
  color: #fff;
}
/*.nav .btn-success:hover,
.nav .btn-success:focus{
	background: #1d71b8;
}*/

/*Menú > Form Input Search*/
.navbar-form {
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
}
.navbar-form input {
  width: 300px !important;
}

/*Menú usuario iconstruye*/
li.user-block {
  background: #f5f5f5;
  margin-top: -5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 210px;
}
li.user-block span.empresa {
  color: #999;
}
li.user-block a {
  color: #0072c5 !important;
}

/*Navbar Agilice*/

/*Logo*/
.navbar-agilice .navbar-brand {
  color: #15324b !important;
}
.navbar-agilice a {
  color: #fff;
}
.navbar-agilice li a:hover,
.navbar-agilice li a:focus,
.navbar-agilice li.active a {
  background: #2a6496 !important;
}
.navbar-agilice ul.dropdown-menu li a:hover {
  color: #fff;
}

/*Menú Ayuda*/
li.help-block {
  background: #f5f5f5;
  margin-top: -5px;
  margin-bottom: 5px;
  padding: 0;
  border-bottom: 1px solid #ccc;
  width: 380px;
}
li.help-block a.bsa_creative {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: #f9f9f9;
  white-space: normal !important;
  border-bottom: 1px solid #ddd;
}
li.help-block a.bsa_creative:last-child {
  border-bottom: none;
}
li.help-block a.bsa_creative span.bsa_flyout-content {
  width: 254px;
  padding: 10px;
}
li.help-block a.bsa_creative img {
  border-radius: 0;
  width: 126px;
}
li.help-block a.bsa_creative:first-child {
  border-radius: 1px 0 0 0;
}
.navbar-agilice li.help-block a:hover {
  background: none !important;
}

/*Submenú*/
.navbar-default {
  min-height: 0;
}
.navbar-default.navbar-sub {
  margin-bottom: 20px;
  background: #f8f8f8;
  border-bottom: 1px solid #e1e1e1;
}
.navbar-default.navbar-sub .navbar-nav > li > a {
  color: #555;
  padding: 12px 7px 10px 7px;
  margin-bottom: -1px;
  border-bottom: 1px solid #e1e1e1;
}
.navbar-default.navbar-sub .navbar-nav > li > a:hover,
.navbar-default.navbar-sub .navbar-nav > li > a:focus {
  background: none;
  border-bottom: 1px solid #c94702;
}
.navbar-default.navbar-sub .navbar-nav > .active > a,
.navbar-default.navbar-sub .navbar-nav > .active > a:hover,
.navbar-default.navbar-sub .navbar-nav > .active > a:focus {
  background: none;
  color: #e66819;
  border-bottom: 1px solid #e66819;
}
.navbar-default.navbar-sub .navbar-nav > .active > a,
.navbar-default.navbar-sub .navbar-nav > .active > a:hover,
.navbar-default.navbar-sub .navbar-nav > .active > a:focus {
  color: #0072c5;
}
.navbar-default.navbar-sub .navbar-nav > .active > a,
.navbar-default.navbar-sub .navbar-nav > .active > a:hover,
.navbar-default.navbar-sub .navbar-nav > .active > a:focus,
.navbar-default.navbar-sub .navbar-nav > li > a:hover,
.navbar-default.navbar-sub .navbar-nav > li > a:focus {
  border-bottom: 1px solid #0072c5;
}

/*Sub Menú Scrollable*/
.scrollable-menu {
  height: auto;
  max-height: 140px;
  min-width: 265px;
  overflow-x: hidden;
}
/*Razón Social Activa*/
#RazonSocialActiva {
  margin-top: 12px;
}

/*Sub Submenú*/
.navbar-default.navbar-sub.sub {
  margin-top: 0px;
  margin-bottom: 0;
  background: #fff;
  border-bottom: 1px solid #f1f1f1 !important;
}
.navbar-default.navbar-sub.sub .navbar-nav > li > a:hover,
.navbar-default.navbar-sub.sub .navbar-nav > li > a:focus {
  border-bottom: 1px solid #ccc;
}
.navbar-default.navbar-sub.sub .navbar-nav > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #777;
  font-size: 13px;
  border-bottom: 1px solid #f1f1f1;
}
.navbar-default.navbar-sub.sub .navbar-nav > .active > a,
.navbar-default.navbar-sub.sub .navbar-nav > .active > a:hover,
.navbar-default.navbar-sub.sub .navbar-nav > .active > a:focus {
  border-bottom: 1px solid #333;
  color: #333;
}

/* Reset Dropdowns en Sub Submenú */
.navbar-default.navbar-sub.sub .navbar-nav > li > .dropdown-menu {
  border-top: 1px solid #ccc;
  border-radius: 2px 2px;
}
.navbar-default.navbar-sub.sub .navbar-nav > .open > a,
.navbar-default.navbar-sub.sub .navbar-nav > .open > a:hover,
.navbar-default.navbar-sub.sub .navbar-nav > .open > a:focus {
  background: #f2f2f2;
}
/*Breadcrumb en navbar sub sub*/
.navbar-default.navbar-sub.sub .breadcrumb {
  padding: 0;
  margin: 0;
}

/*Notificaciones*/
.notification-counter {
  position: relative;
  border-radius: 10px !important;
  bottom: 0 !important;
  right: 0;
  font-size: 10px;
  padding: 3px 6px;
  top: -6px !important;
  background: #ed382b;
  margin-left: -5px;
}

.notification-counter:hover {
  background: #ca0814;
}

/*Interna Documento > Dl*/
.dl-info {
  margin-bottom: 0;
}
.dl-info dt {
  float: left;
  min-width: 100px;
}

/*Interna Documento > Side*/
.side {
  padding-top: 20px;
}
.side-notorius {
  background: #f1f1f1;
  border-radius: 0px 5px 5px 0;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding: 10px;
  margin-left: -1px;
}
.side-notorius hr {
  border-color: #ddd;
}
.side-notorius.add-bottom {
  background: #f9f9f9;
  border-radius: 0 0 5px 0;
  padding: 20px 10px;
  margin-top: -5px;
}
.side .list-inline .avatar {
  margin: 0 0 3px 0;
  padding: 0;
  float: none;
}
.side .nav li a {
  margin-bottom: 0px;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  padding: 5px 0px;
  /*color: #888;*/
}
.side .nav li a.btn {
  margin-bottom: 2px;
}
.side .nav li a.btn-lg {
  padding: 10px 16px;
}
.side .nav li a.btn-default:hover {
  background: #e6e6e6;
}
.side .nav li.active a {
  color: #333;
}
.side .nav li a:hover {
  /*background: none;
	color: #333;*/
}
.side .nav li a.btn-danger:hover {
  background: #d2322d;
}
.side .nav .dropdown-header {
  padding: 6px 0px;
}
/*.side .nav li a:hover{
	background: #fff;
	color: #F63D29;
}
.side .nav li.active a{
	color: #F63D29;
}*/
.side .divider {
  display: block;
  margin-bottom: 20px;
}

/*Menú sidebar (en interna)*/
/*.nav-stacked>li+li{
	margin-top: 0;
}*/
.side-menu {
  margin-bottom: 30px;
  /*border-right:1px solid #e1e1e1;*/
  padding-top: 15px;
  /*padding: 15px 0 64px 15px;*/
  /*background: #EFEFEF;*/
  height: 100%;
  min-height: 100%;
}
.side-menu ul.nav-tabs {
  padding: 0px;
}
.side-menu ul.nav-tabs {
  margin: 15px 0px;
}
.side-menu ul.nav-tabs li a {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 0px;
}
.side-menu ul.nav-tabs li.active a {
  background-color: transparent;
  border-bottom: 0px !important;
  font-weight: 600;
  padding: 17px 15px;
}
.side-menu .tab-content {
  padding: 5% 0px;
}
.side-menu hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.side-menu .nav-pills > li > a {
  padding: 5px 10px 5px 10px;
  margin: 2px 0 0 0;
  border-radius: 2px;
}
.side-menu .nav-pills li.active > a {
  background: #0072c5 !important;
}
.side-menu .nav-pills > li > a:hover {
  background: #f7f7f7;
  color: #0072c5 !important;
}
.side-menu .nav-pills > li.active > a,
.side-menu .nav-pills > li.active > a:hover,
.side-menu .nav-pills > li.active > a:focus {
  background: #0072c5;
  color: #fff;
}
.side-menu .nav-pills > li.active > a:hover {
  background: #0072c5 !important;
  color: #fff !important;
}

ul.nav li.side-title strong a {
  color: #e66819;
}

/*Label "Admin" en sidebar*/
.side-menu .label {
  font-weight: normal;
  line-height: 2.2em;
}
/*Submenú dentro de side-menu*/
.side-menu .nav-pills ul {
  padding: 5px 0 10px 10px;
  border-left: 1px solid #f7f7f7;
  /*padding-left: 10px;
	/*margin-left: 20px;*/
  list-style-type: none;
  line-height: 1.9em;
  /*background: #EFEFEF;*/
}
.side-menu .nav-pills ul li a {
  color: #777;
}
.side-menu .nav-pills ul li a:hover {
  color: #0072c5;
}
.side-menu .nav-pills ul li.active a {
  background: #fff !important;
  color: #0072c5;
}

/*Alerts top, consulta*/
.top-alert {
  padding: 0;
  margin: 0;
  background: aliceblue;
  border-bottom: 2px solid #f1f5f9;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.top-alert p {
  padding: 3px 7px;
  margin: 0;
  font-size: 85%;
  color: #738e9b;
}
.alert-info {
  background-color: #f1f5f9;
  border-color: #bdd4ea;
  color: #4c6079;
}

.breadcrumb li {
  font: normal 11px/100% Arial;
  letter-spacing: 0.5px;
}
.breadcrumb p {
  margin-bottom: 0px;
  font: normal 11px/100% Arial;
  letter-spacing: 0.5px;
}

/*Forms*/
input[type="checkbox"] {
  cursor: pointer;
}
.wrap-form {
  margin: 10px 0;
}
.wrap-form
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

/*Modal > Select*/
.modal-body
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
.modal-body label {
  margin-bottom: 5px !important;
}

/*Modal > hr*/
.modal-body hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*Modal > Well*/
/*.modal-body .well{
	padding-bottom: 3px;
}*/

/*Dashboard Row Wrap*/
.row.wrap-widgets {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/*Dashboard Tipo de Documento*/
.resumen-documentos ul {
  margin: 20px 0;
}
.resumen-documentos ul li {
  color: #777;
  margin: 0 10px;
  font-size: 0.9em;
}
.resumen-documentos ul li strong {
  font-size: 2em;
  font-weight: normal;
}
.resumen-documentos ul li span {
  font-size: 0.8em;
  text-transform: uppercase;
  color: #999;
}

/*Dashboard Side*/
.side-important {
  background: #f7f7f7;
  height: 100%;
  border: 1px solid #eee;
}

/*Dashboard Sortable*/
ul.wrap-widgets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.wrap-widgets li {
  /*cursor: move;*/
}
.widget {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.widget.ui-sortable-helper .widget-info {
  border-style: dashed;
  border-color: #ccc;
  background: #f9f9f9;
}
.widget-info {
  /*height: 250px;*/
  background: #fff;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  position: relative;
  padding-top: 30px;
  margin-bottom: 30px;
}
/*Widget Date*/
.widget-info .date {
  background: #fff;
  padding: 10px;
}
.widget-info .date h3 {
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 10px;
}
/*Widget Editar*/
.widget-info .editar a {
  color: #999;
}
/*Widget elements*/
.widget-info ul.n {
  margin: 0;
  list-style-type: none;
  padding: 10px;
  margin-bottom: 0 !important;
}
/*.widget-info ul.n strong{
	font-size: 1.3em;
}*/
.widget-info ul.n li {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 6px;
  position: relative;
  z-index: 1;
}
.widget-info ul.n li a {
  color: #555;
  display: block;
  overflow: hidden;
  padding: 0 5px;
}
.widget-info ul.n li a:hover {
  background: #f1f1f1;
}
.widget-info table {
  margin-top: -1px;
}

li.n-title {
  padding-left: 5px;
  color: #777;
}
li.n-title a {
  display: inline !important;
  padding: 0 !important;
  color: #e66819 !important;
}
li.n-title a:hover {
  background: none !important;
  color: #c94702 !important;
}
li.n-title strong {
  line-height: 1.5em;
}
.widget-info .widget-header {
  position: absolute;
  top: 0;
  height: 30px;
  width: 100%;
  background: #f5f5f5;
  padding: 7px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid #ddd;
}
.widget-info .widget-header p {
  padding-left: 0;
}
.key-bar {
  background: #f5f5f5 !important;
  display: inline-block;
  position: absolute !important;
  z-index: -1 !important;
  top: 0;
  bottom: 2px;
  right: 0;
}
.more {
  position: absolute;
  z-index: 999;
  left: 75px;
  top: -2px;
  background: none;
  outline: none;
  color: #e66819 !important;
}
.more:hover {
  background: none !important;
  color: #c94702 !important;
}
#MoreSinArm {
  left: 141px;
}
/*.expand*/
.widget-info .expand {
  padding: 5px 0 0 25px;
  margin-left: 0;
  margin-top: 0;
  background: #fff;
}
/*links dentro de .expand*/
.expand a {
  display: inline !important;
  background: none !important;
  padding: 0 !important;
  color: #0072c5 !important;
}
.expand a:hover {
  color: #2a6496 !important;
}
.expand p {
  margin: 0 0 5px 0;
}

/*Función especial para deshabilitar links*/
.disabled-links {
  pointer-events: none;
  cursor: default;
}
/*.wrap-boxes .boxes{
	padding-left: 5px;
	padding-right: 5px;
}
.wrap-boxes .boxes .well{
	border-radius: 2px;
	padding: 0;
}
.wrap-boxes .boxes .well p{
	padding: 10px;
}*/

/*Número Contador en dashboard*/
.count {
  float: right;
  font-weight: bold;
  color: #777;
}
.big {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 0 !important;
}
.big.light {
  font-weight: 100;
}
.big .small {
  font-size: 0.4em;
  line-height: 0;
  font-weight: normal;
  color: #999;
  margin-left: -5px;
}

/*Drag Well*/
/*#draggablePanelList .well {
	cursor: move;
}
.ui-sortable-helper .well{
	border-style: dashed;
	border-color: #ccc;
}*/

/*Dashboard > Panel IVA (danger)*/
.danger p {
  color: #d9534f;
}

/*Crear > Tipo de documento*/
ul.tipo-docs {
  list-style-type: none;
  padding: 0;
}
ul.tipo-docs li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
}

/*Crear > interna*/
.wrap-create {
  margin-top: 10px;
}
.wrap-create h2 {
  margin-top: 5px;
  margin-bottom: 15px;
}
.content-tab-pane {
  padding-top: 20px;
  border-top: 1px solid #ccc;
  background: #f2f2f2;
}

/*Facturación Electrónica > Ingreso de Referencias*/
#IngresoRef #emisor,
#IngresoRef #receptor,
#IngresoRef #detalle,
#IngresoRef #total,
#IngresoRef #observaciones {
  opacity: 0.5;
}

/*Eliminar Línea*/
.with-actions {
  position: relative;
}
.wrap-with-actions:hover code.act {
  visibility: visible;
}
.with-actions code.act {
  position: absolute;
  left: -32px;
  z-index: 999;
  top: -6px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 2px 0 0 2px;
  line-height: 15px;
  padding: 12px 6px;
  visibility: hidden;
  font-weight: normal;
  height: 42px;
}
code.act a {
  color: #be3535;
}
code.act a:hover {
  color: #d2322d;
}
code.act.visible {
  visibility: visible;
  height: 30px;
  left: -40px;
  padding: 6px 10px;
}

/*Progress Bar*/
.wrap-progress {
  padding: 0px 10px 10px 10px;
}
.progress {
  border-radius: 0;
  overflow: visible;
}
.progress-bar {
  padding: 0px;
  position: relative;
  box-shadow: none;
  /*color: #777;*/
  font-weight: bold;
  /*font-size: 1em;*/
}
.progress-bar-primary {
  background: #d9d9d9;
}
.progress-bar-info {
  background: #0072c5;
}

/*.progress-bar:hover{
	background: #f1f1f1;
}*/
.progress a .divider-graph {
  height: 48px;
  width: 1px;
  background: #f7f7f7;
  position: absolute;
  top: -13px;
  left: -1px;
}
.progress-bar-success {
  background-color: #5cb85c !important;
}
.progress-bar-warning {
  background-color: #d89e0e !important;
}
.progress-bar-danger {
  background-color: #be3535 !important;
}
.progress-bar-danger.medium {
  background-color: #d56767 !important;
}
.progress-bar-danger.low {
  background-color: #e29898 !important;
}
.progress-bar-danger.really-low {
  background-color: #edc0c0 !important;
}
/*Well*/
.well {
  margin-top: 0;
  margin-bottom: 30px;
  padding: 13px;
  box-shadow: none;
  border-radius: 2px;
  /*transition: all .1s linear;
	-moz-transition: all .1s linear;
	-webkit-transition: all .1s linear;*/
}
.well-white {
  background: #fff !important;
}
.well-light {
  background: #f6f7fa;
}
.well-danger {
  background: #f4d9d9 !important;
  color: #be3535 !important;
  /*border: 1px solid #d9534f;*/
}
/*.well-white:hover{
	background: #eee !important;
	border-color: #ccc;
}*/

.well-benefits .image {
  position: relative;
}
.well-benefits .image h4 {
  color: #fff;
  position: absolute;
  bottom: 0;
  margin: 0;
}
/*.well p{
	margin: 0;
}*/

/*Table*/
.table-options {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #ddd;
}
.table-options p {
  padding-top: 5px;
  margin: 0 10px 0 0;
  color: #4b4b4b;
  float: left;
}
.table-options .form-group {
  margin-bottom: 0;
}
.table-options .dropdown .form-group {
  margin-bottom: 5px;
}
.table {
  margin-bottom: 0;
}
.table > tbody + tbody {
  border-top: 3px solid #e9e9e9;
}
/*Table options para dashboard*/
.table-options.dashboard-options {
  margin-bottom: 20px;
  border-radius: 5px;
}
.table-options.dashboard-options .form-group {
  margin-bottom: 0;
}
.dashboard-options.light {
  background-color: #f9f9f9;
}
/*Table > Row Filters (filtros)*/
.row-filters {
  border-bottom: 2px solid #ddd;
}

/*Table Collapse*/
tr.clickable td {
  cursor: pointer;
}
tr.target td.target-content {
  background: #f1f1f1;
  padding: 15px;
}
tr.target td.target-content td {
  background: #f1f1f1;
}
tr.clickable.active td {
  background: #fff !important;
}

/*Range dashboard*/
.range button.selectpicker {
  border-radius: 2px 0 0 2px !important;
  width: 180px;
  border-right: 0;
}
.range .btn-group button.selectpicker {
  width: 130px;
}
.range .btn-group.spFechas button.selectpicker {
  width: 75px;
}
.range .input-daterange input.input-sm.form-control:first-child {
  border-radius: 0;
}
.input-group-addon.middle {
  border-left: 0;
  border-right: 0;
}
.input-xs.last {
  border-radius: 0 2px 2px 0 !important;
}
#detalle .input-group-addon.middle {
  border-left: 0;
  border-right: 0;
  font-size: 1em;
  text-shadow: none;
}
/*Data Table*/
/*.scroll th, td {
	white-space: nowrap;
}
div.dataTables_wrapper {
    margin: 0 auto;
}*/

/*Table Ultra Condensed*/
.table-ultra-condensed > thead > tr > th,
.table-ultra-condensed > tbody > tr > th,
.table-ultra-condensed > tfoot > tr > th,
.table-ultra-condensed > thead > tr > td,
.table-ultra-condensed > tbody > tr > td,
.table-ultra-condensed > tfoot > tr > td {
  padding: 2px !important;
  font-size: 13px;
}

/*Table > nav-pills (ajuste de padding y font-size en .nav-pills de la tabla)*/
.table-options .nav-pills a {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 3px;
}
.table-options .nav-pills a:hover {
  background: #e1e1e1;
}

/*Table > th*/
.table th {
  background: #f5f5f5;
}
.table tr.bg-white th {
  background: #fff;
}

/*Table > td*/
.table-hover.table-dte tbody:hover td {
  background: #f5f5f5;
}

/*Table Pull Bottom Más*/
.pull-bottom {
  position: absolute;
  bottom: 7px;
  left: 5px;
  width: 100%;
}

/*Table td Conciliación*/
td.conciliacion dl {
  margin-bottom: 0;
}
td.conciliacion dt {
  float: left;
  min-width: 70px;
  font-weight: normal;
}
td.conciliacion dt,
td.conciliacion dd {
  margin-bottom: 3px;
}

/*Table Ver Referencias*/
.ver-ref {
  position: absolute;
  right: -1px;
  bottom: -1px;
  line-height: 1.4em;
  padding-left: 5px;
  padding-right: 3px;
  border-radius: 3px 0 0 0;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 10px;
  font-weight: normal;
  color: #333 !important;
}
.ver-ref:hover {
  background: #ebebeb;
}
/*Table > Select*/
.table-options .bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="span"] {
  margin-bottom: 0;
  margin-right: 10px;
}
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:focus small,
.bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled) > a small {
  color: #333 !important;
}
.w170 {
  width: 100% !important;
  max-width: 150px !important;
}

/*Pagination*/
.pagination {
  border-radius: 2px;
  margin: 0 !important;
  padding: 1rem !important;
  color: #0994ff !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  color: #0994ff;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #0994ff;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {

  background-color: #0994ff;
  border-color: #0994ff;
}
.table-options ul.pagination {
  margin: 0;
}
.table-options ul.pagination li a {
  color: #4b4b4b;
}
.table-options .pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 6px 10px 5px 10px;
}

/*Table > p.lead (párrafo destacado al interior de una tabla. Ej: No hay documentos.)*/
.table p.lead {
  margin-top: 20px;
}

/*Wrap Inner (Interna)*/
/*.wrap-inner h2,
.wrap-inner h3{
	margin-top: 0;
}
.wrap-inner ul{
	margin-bottom: 20px;
}*/

.wrap-inner {
  height: 100%;
  min-height: 100%;
  position: relative;
}

/*Emisor y Referencias > Reset ancho de "select" en el documento*/
#emisor
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn),
#referencias
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
/*Receptor > Reset ancho de "select" en el documento*/
#receptor
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
/*Receptor > Reset ancho de "select" en el documento*/
#receptor
  #empresa-receptora
  .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 80%;
}

/*Referencias y Detalle > Table (reset margen inferior de la tabla)*/
#referencias .table,
#detalle .table {
  margin-bottom: 5px;
}

/*Reset resize en textarea de "Detalle"*/
#referencias textarea,
#detalle textarea,
#observaciones textarea,
#subtotales-detalle textarea,
#comisiones textarea,
textarea.text-elastic {
  min-height: 30px;
  resize: vertical;
}
th.none {
  background: #fff !important;
  border: none !important;
  border-right: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #ddd !important;
}
#dividir-linea th.none {
  background: #fff;
  border: none;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ddd;
}
/*#subtotales-detalle .none{
	border-bottom: 1px solid #fff;
	border-left: 1px solid #fff;
}*/
tr.active th {
  background: #e7e9ec !important;
  color: #545e69 !important;
}
tr.no-border td {
  border: 1px solid #fff !important;
}
td.none {
  vertical-align: middle !important;
  padding: 0 7px !important;
  width: 27px !important;
}
td.none .glyphicon {
  top: 3px !important;
}

/*Table - Totalizador*/
tr.totalizador {
  border-left: 1px solid #fff !important;
}

/*Receptor > Reset label*/
#receptor .form-horizontal .control-label {
  text-align: left;
}

/*Contenedor Inner (Interna) para el documento*/
.doc-content {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
}

/*******-------
Simulador de documento o vistas de resumen
-------*******/
.doc-content-material {
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
  margin: 30px 0;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
}
.doc-content-material h2 {
  margin: 10px 0;
  font-size: 33px;
}
.doc-content-material .resumen h3 {
  margin-top: 0;
}

/*h4 (Subtítulo)*/
h4.sub-section {
  margin-bottom: 20px;
  margin-top: 0;
}

/*Reset margin-bottom en select de documento*/
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="span"] {
  margin-bottom: 0px !important;
}

/*Rut, tipo y número del documento*/
.wrap-tipo-doc {
  border: 3px solid #4b4b4b;
  padding: 0 5px;
}
.wrap-tipo-doc h3 {
  text-align: center;
  text-transform: uppercase;
}

/*Dirección Regional SII en documento*/
.direccion-sii {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 0;
}

/*Contenedeor Inner (Interna) para el historial del documento*/
.doc-historial {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 40px 20px 40px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
}

/*Box para adjuntar archivos (drag and drop)*/
.ajax-upload-dragdrop {
  border: 1px dashed #ccc;
  background: #fff;
  padding: 10px 10px 7px 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.ajax-upload-dragdrop:hover {
  cursor: pointer;
  background: #f9f9f9;
}
.ajax-upload-dragdrop p {
  padding: 20px 0;
  font-size: 1.3em;
  line-height: 1.3em;
  text-align: center;
}

/*Reset listgroup*/
.list-group-item:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

/*Reset margen superior de alert en un mensajes*/
.container-message .alert {
  margin-top: 10px;
  padding: 10px;
}
/*Reset Panel Group y Body*/
.container-message .panel-heading,
.container-message .panel-body {
  padding: 5px;
}
.container-message p {
  margin-bottom: 0;
}
.panel-group .panel {
  border-radius: 2px;
  overflow: visible;
}
/*Small (hora del mensaje)*/
.container-message span small {
  padding-left: 20px;
}
/*padding para el contenedor de todos los mensajes*/
.wrap-all-messages {
  padding-left: 40px;
  padding-right: 10px;
}

/*Borde izquierdo de la timeline*/
.wrap-messages {
  border-left: 3px solid #f3f3f3;
  min-height: 100%;
  padding-bottom: 15px;
  margin-top: -15px;
}
.wrap-messages .container-message,
.wrap-messages .panel,
.wrap-messages .alert {
  margin-bottom: 7px;
}

/*Fecha en timeline*/
p.time {
  line-height: 1.9em;
  color: #4b4b4b;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 5px;
}

/*Marcador (circulo) en timeline*/
span.box-time {
  height: 17px;
  width: 17px;
  height: 22px;
  width: 22px;
  border-radius: 300px;
  background: #f3f3f3;
  border: 2px solid #fff;
  display: block;
  margin-left: -12px;
  float: left;
  margin-right: 10px;
  text-align: center;
  color: #5cb85c;
}

.box-time.active {
  background: #5cb85c;
  color: #fff;
}

/*Texto al inicio del timeline (Línea de tiempo)*/
p.time.start {
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 0;
}
.start span.box-time {
  background: #f1f1f1;
  border: 2px solid #fff;
  margin-left: -17px;
  margin-bottom: -1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 31px;
  text-align: center;
  color: #aaa;
  height: 31px;
  font-size: 14px;
  border-radius: 100px;
  line-height: 22px;
}

/* -------------------- Contenedor del mensaje -------------------- */
.container-message {
  margin: 5px 0 16px 13px;
  position: relative;
}

/* -------------------- Dropdown de opción marcar como privado o público -------------------- */
.cont-opt-mensajes a.btn.btn-default.dropdown-toggle {
  line-height: 1.4em;
}

/*Texto al inicio del timeline (Línea de tiempo)*/
p.time.start {
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 0;
}
.start span.box-time {
  background: #f1f1f1;
  border: 2px solid #fff;
  margin-left: -17px;
  margin-bottom: -1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 31px;
  text-align: center;
  color: #aaa;
  height: 31px;
  font-size: 14px;
  border-radius: 100px;
  line-height: 22px;
}

/* -------------------- Contenedor del mensaje -------------------- */
.container-message {
  margin: 5px 0 16px 13px;
  position: relative;
}

/* -------------------- Dropdown de opción marcar como privado o público -------------------- */
.cont-opt-mensajes a.btn.btn-default.dropdown-toggle {
  line-height: 1.4em;
}

/* -------------------- Contenedor de imagen en mensajes (Historial) -------------------- */
.avatar {
  float: left;
  width: 30px;
  height: 30px;
  margin: 10px 7px 0px 10px;
}
.avatar img {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
}

/*  -------------------- Factoring > Búsqueda avanzada (filtros colapsables) -------------------- */
#busqueda-avanzada .form-group,
#busqueda-avanzada-emitidas .form-group,
#busqueda-avanzada-adquiridas .form-group {
  margin-top: 0;
  margin-bottom: 0;
}
#busqueda-avanzada .form-group label,
#busqueda-avanzada-emitidas .form-group label,
#busqueda-avanzada-adquiridas .form-group label {
  font-weight: normal;
}
#busqueda-avanzada .btn-group.bootstrap-select.btn-group-sm,
#busqueda-avanzada-emitidas .btn-group.bootstrap-select.btn-group-sm,
#busqueda-avanzada-adquiridas .btn-group.bootstrap-select.btn-group-sm {
  width: 100%;
}

/* -------------------- Factoring > Comparador de ofertas -------------------- */
table.compare {
  table-layout: fixed;
}
table.compare th.title {
  vertical-align: bottom;
  font-weight: normal;
}
table.compare td {
  width: 33%;
}
table.compare .no-b-lb {
  background: none !important;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
table.compare .no-b-lt {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
}
table.compare .no-bg {
  background: none !important;
}
table.compare .btn-sm {
  margin-top: 5px;
}

/* -------------------- Factoring > Asministrar > Integraciones -------------------- */
.service-icon {
  margin-top: -4px;
}

.alert .lead {
  line-height: 1.6em;
}
.lead li {
  margin-bottom: 10px;
}

/*-------------------- Index Columnas -------------------- */
.col-index {
  margin-top: 10px;
}
.col-index h3 {
  font-size: 1.2em;
  line-height: 1.3em;
  margin: 0 0 5px 0;
}

/* -------------------- Mi cuenta > Cambiar mi Plan --------------------*/
.plan {
  width: 25%;
  float: left;
  border: 1px solid #ddd;
  margin-right: -1px;
  padding: 10px;
  min-height: 350px;
  position: relative;
  background: #f9f9f9;
}
.plan.trio {
  width: 33.3% !important;
}
.plan ul {
  padding-left: 20px;
}
.plan ul li {
  margin-bottom: 5px;
}
.plan .btn,
.plan .actual {
  position: absolute;
  bottom: 10px;
}
.plan.contrata {
  min-height: 390px;
  top: -20px;
  position: relative;
  background: #fff;
}

/*-------------------- Columna Derecha para Configuraciones -------------------- */
#form-configuracion {
  margin-top: 105px;
}
.side-menu h2 {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
  color: #333;
  margin-bottom: 5px;
}
#header-configuracion {
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 40px;
}
#breadcrumb-configuracion {
  position: absolute;
  left: 0;
  right: -25px;
  padding-left: 15px;
  padding-right: 40px;
  top: 83px;
}
.header-configuracion-transition {
  -moz-transition: top 0.3s ease-out;
  -o-transition: top 0.3s ease-out;
  -ms-transition: top 0.3s ease-out;
  transition: top 0.3s ease-out;
}
#header-configuracion.header-configuracion-fixed {
  width: 74%;
  left: 26%;
  right: -25px;
  padding-left: 15px;
  padding-right: 40px;
  position: fixed;
  z-index: 999;
  top: 0;
}
/* -------------------- Footer -------------------- */
#footer {
  border-top: 1px solid #e2e5e8;
  padding: 20px 0 15px 0;
  margin-top: 30px;
}
#footer p {
  color: #999;
  text-align: center;
}

/* Reset the container */
/*@media (min-width: 1200px){
	.container{
		width: 95%;
	}
}*/

/* -------------------- Receptor > Reset ancho de "select" en el documento -------------------- */
@media (min-width: 1200px) {
  #receptor
    #empresa-receptora
    .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 315px;
  }
}
@media (min-width: 992px) {
  /*.container {
		width: 100%;
	}*/
}
@media (min-width: 768px) {
  /*.container {
		width: 100%;
	}*/
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -10px;
  }
}

/* -------------------- CSS PANCHO -------------------- */

.well.empresa {
  height: 100px;
}

.well.empresa img {
  width: 85%;
  height: auto;
  margin-right: 15px;
  display: block;
}

.net-box-img {
  width: 35%;
  float: left;
  display: inline-block;
}

.net-box-text {
  width: 65%;
  float: left;
  display: inline-block;
}

.sugeridos {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sugeridos li {
  padding: 14px 0 0 0;
}

.side-chat-modal {
  background: #f7f7f7;
  padding-bottom: 100px;
  padding-top: 0px;
  position: absolute;
  top: 0;
  right: -15px;
  bottom: 0;
}
.side-chat-modal-2 {
  background: #f7f7f7;
  padding-bottom: 30px;
  padding-top: 0px;
  position: absolute;
  top: -20px;
  right: -5px;
  bottom: 0;
  overflow-y: scroll;
  border-bottom: 30px solid #f7f7f7;
}

.date-solution {
  background: #f1f1f1;
  border-bottom: 1px solid #ddd;
  height: 50px;
  padding-left: 15px;
}

.table-dark {
  background: #f9f9f9;
}
.active-row {
  background: #fff !important;
}
.active-row td {
  font-weight: 700 !important;
}

.active-row td small {
  font-weight: 300 !important;
}
.big-num {
  font-size: 26px;
  padding-top: 0px;
  margin-top: 25px;
  display: block;
}
.big-num-2 {
  font-size: 36px;
  padding-top: 0px;
  margin-top: 25px;
  display: block;
  font-weight: 300 !important;
}
.info-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.info-list li {
  padding-bottom: 5px;
  font-size: 13px;
}
.info-list li span {
  margin: 0;
  font-size: 30px;
}
.scroll-referencia {
  height: 100px;
  overflow-y: scroll;
}
.box-indicator {
  background: #f9f9f9;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  position: relative;
  margin-bottom: 20px;
  height: 60px;
}

.box-indicator p {
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  float: left;
}

.box-indicator a {
  padding: 0;
  float: left;
}

.box-indicator h4 {
  background: #fff;
  border-right: 1px solid #e3e3e3;
  float: left;
  height: 58px;
  margin: 0;
  padding-top: 15px;
  text-align: center;
}

.box-indicator h4 a {
  text-align: center;
  padding: 0 15px;
  margin: 0;
  float: none;
}

.indicator {
  font-size: 24px;
  font-weight: 700;
}
.chat-empty {
  text-align: center;
  min-height: 500px;
}
.chat-empty p {
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.chat-empty i {
  font-size: 100px;
  padding-top: 50px;
  color: #ddd;
}
.checkbox-inline label {
  font-weight: 400;
}
#emision-collapse {
  overflow: visible;
}

#emision-collapse.panel-group .panel + .panel {
  margin-top: -1px;
  overflow: visible;
}
#emision-collapse .panel-heading {
  padding: 0;
}
#emision-collapse .panel-title a {
  display: block;
  padding: 10px;
}
#emision-collapse .panel-group {
  overflow: visible !important;
}

/*Grilla Documentos Emitidos*/
/*.wrap-status{
	display: inline-block;
	margin-bottom: 2px;
}
.wrap-status .label{
	position: relative;
	z-index: 2;
	margin-right: 10px;
	font-size: 11px;
	padding: 3px 15px;
}
.line{
	background-color: #ddd;
	width: 95%;
	height: 2px;
	position: relative;
	top: 13px;
	z-index: 1;
}*/
.table-boleta {
  background: #fff !important;
}

#boleta-collapse {
  overflow: visible;
}

#boleta-collapse.panel-group .panel + .panel {
  margin-top: -1px;
  overflow: visible;
}
#boleta-collapse .panel-group {
  overflow: visible !important;
}
.table-boleta {
  background: #fff !important;
}

.divider-block-left {
  border-left: 1px solid #ddd;
  margin-top: -15px;
  margin-bottom: -15px;
  min-height: 65px;
  padding-top: 2px;
}
/* -------------------- Eliminar Línea right -------------------- */

.with-actions code.act-right {
  position: absolute;
  right: -35px;
  z-index: 999;
  top: -26px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 2px 0 0 2px;
  line-height: 15px;
  padding: 8px 6px;
  visibility: hidden;
  font-weight: normal;
  height: 35px;
}
code.act-right a {
  color: #be3535;
}
code.act-right a:hover {
  color: #d2322d;
}
.wrap-with-actions:hover code.act-right {
  visibility: visible;
}
.total-boleta {
  font-size: 20px !important;
}
#doc-emitidos.table-options {
  padding: 0 10px;
}

/*Gestión Acuse*/
/*.acuse-recibo-comercial .cartola-info{
	min-height: 284px;
}*/

.nexo {
  position: absolute;
  z-index: -1;
  height: 10px;
  width: 40px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  top: 55px;
  right: -20px;
}
.scan-box {
  text-align: center;
  padding: 20px 0;
  font-size: 18px;
}
.list-rules {
  list-style: none;
}
.resumen-box {
  min-height: 187px;
  margin-left: -1px;
}
.resumen-box .big-num {
  font-weight: 200;
  font-size: 36px;
  padding-top: 30px;
}
.resumen-box small {
  font-size: 20px;
}
.light {
  font-weight: 400;
}
#aceptacion .panel {
  overflow: visible;
}

tr.active-new {
  border-left: 5px solid #0072c5;
}
.h200 {
  min-height: 200px !important;
}
.nonradius {
  border-radius: 0 !important;
}
.hovered tr:hover {
  background: #ccc;
}
.doc-modal-box {
  height: 500px;
  overflow-y: scroll;
}

/*Activación > Wizard*/
/*Wizard*/
/*#steps {
  margin: 0 auto;
  width: 197px;
}
.step {
  width: 40px;
  height: 40px;
  background-color: #eee;
  display: inline-block;
  border-color: transparent;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
  color: #999;
}
.step:first-child {
  line-height: 40px;
}
.step:nth-child(n+2) {
  margin: 0 0 0 111px;
  -ms-transform:translate(0, -4px);
  -webkit-transform:translate(0, -4px);
  transform: translate(0, -4px);
}
.step:nth-child(n+2):before {
  width: 75px;
  height: 3px;
  display: block;
  background-color: white;
  -ms-transform:translate(-95px, 21px);
  -webkit-transform:translate(-95px, 21px);
  transform: translate(-95px, 21px);
  content: '';
}
.step:after {
  width: 150px;
  display: block;
  -ms-transform: translate(-55px, 3px);
  -webkit-transform: translate(-55px, 3px);
  transform: translate(-55px, 3px);
  content: attr(data-desc);
  font-weight: 400;
  font-size: 13px;
  color: #999;
}
.step:first-child:after {
  -ms-transform: translate(-55px, -1px);
  -webkit-transform: translate(-55px, -1px);
  transform: translate(-55px, -1px);
}
.step.active {
  background: #0072C5;
  color: #fff;
}
.step.active:before {
  background: linear-gradient(to right, #58bb58 0%, #0072C5 100%);
}
.step.active:after {
  color: #0072C5;
}
.step.done {
  background-color: #58bb58;
  border-color: #58bb58;
  color: white;
}
.step.done:before {
  background-color: #58bb58;
}*/

.input-inline input {
  display: block;
  float: left;
}
.modal-header.begin {
  /*background: #0072C5;*/
  border-bottom: none;
  background: orange;
  color: #fff;
}
.modal-header.begin .close {
  color: #fff;
  text-shadow: 0 1px 0 #2a6496;
  opacity: 1;
}
p.begin {
  padding: 20px;
  /*background: #0072C5;*/
  background: orange;
  color: #fff;
  text-align: center;
}
/*Carousel Activación*/
#carousel-activacion .item {
  height: 350px;
}
#carousel-activacion .carousel-control {
  background: none;
  color: #0072c5;
  text-shadow: none;
}
#carousel-activacion .carousel-caption {
  color: #333;
  text-shadow: none;
  padding-bottom: 40px;
}
#carousel-activacion .carousel-indicators {
  margin-bottom: 0;
}
#carousel-activacion .carousel-indicators li {
  background: #ccc;
}
#carousel-activacion .carousel-indicators .active {
  background: #0072c5;
}

/*Bienvenida*/
.step-left {
  border-right: 1px solid #ddd;
  height: 350px;
}
.step-center {
  height: 350px;
}
.step-right {
  border-left: 1px solid #ddd;
  height: 350px;
}
.step-left .fa,
.step-center .fa,
.step-right .fa {
  font-size: 3.5em;
  padding: 7px 0;
  color: #0072c5;
}
.btn-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*Status Activación*/
.status-activacion {
  position: relative;
}
.status-activacion-info {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 2em;
}
.status-activacion-info .label {
  font-size: 1em;
  margin-bottom: 20px;
}
.disabled {
  opacity: 0.5;
}
.disabled .fa {
  color: #ccc;
}

/*Cartola stuff*/
.cartola-btn {
  background: linear-gradient(#028cdf, #0072c5);
  border-radius: 7px;
  border: 1px solid #d0e5f2;
  color: #fff;
  display: block;
  font-size: 1.9em;
  float: left;
  margin-right: 15px;
  padding: 5px 10px 0 10px;
}
.cartola-btn:last-child {
  margin-right: 0;
}
.cartola-btn:hover {
  background: linear-gradient(#018add, #036eae);
  color: #fff;
}

/*Factoring > Asministrar > Integraciones*/
.service-icon {
  margin-top: -4px;
}

/*Reset Date Picker*/
.datepicker table tr td,
.datepicker table tr th {
  border-radius: 2px !important;
}
.datepicker.dropdown-menu {
  border-radius: 2px !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #0072c5 !important;
  color: #fff !important;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background: #0072c5 !important;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:focus,
.datepicker table tr td.today:hover:focus,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today.disabled:hover:focus,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.today,
.open .dropdown-toggle.datepicker table tr td.today:hover,
.open .dropdown-toggle.datepicker table tr td.today.disabled,
.open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
  background: #0072c5 !important;
  color: #fff !important;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background: #333 !important;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  border-radius: 0 !important;
}

/*Comunicaciones Template*/
.big-title {
  font-size: 6em;
  font-weight: 100;
}
.body-text {
  padding: 10px 200px 0 200px;
}
.lead-lg {
  font-weight: 300;
  font-size: 1.7em;
}
.body-text img {
  width: 100%;
  margin: 20px 0 30px 0;
  border: 1px solid #f3f3f3;
  padding: 10px;
}

/* Canal: New Inicio */
.start-options {
  background: #fafafa;
  border: #f1f5f9 1px solid;
  margin-bottom: 15px;
  padding: 10px 20px;
  transition: 0.2s ease;
}
.start-options:hover {
  background: #f7f7f7;
}
.start-options p {
  color: #777;
  font-size: 0.95em;
  padding-bottom: 10px;
}

.progress-small {
  height: 5px !important;
}

.user-pic {
  border: 1px #ddd solid;
  /*box-shadow: 0 2px 5px -1px rgba(0,0,0,0.25);*/
  margin: 0 15px 0 0;
  padding: 1px;
}
.user-pic + p {
  margin-bottom: 6px;
}

/*.controversial {
	height: 106px;
	padding: 10px 15px;
}
	.controversial p {
		font-size: 14px;
		font-weight: 300;
	}
	.controversial a {
		color: inherit;
		font-weight: bold;
	}
	.controversial a:hover {
		color: #992a2a;
	}*/

.trial {
  padding: 30px 0 0 0;
}
.trial p {
  color: #777;
  font-size: 16px;
  font-weight: 300;
  float: left;
  top: 5px;
  left: 10px;
  position: relative;
  width: 100px;
}
.timeleft {
  position: relative;
  right: 40px;
}
a.btn-go {
  display: inline-block;
  border-radius: 2px;
  padding: 4px 20px 6px 20px !important;
  margin: 13px 0 0 0;
  border: 1px solid #299710;
  color: #fff !important;
  background: #2da411;
  background: linear-gradient(#51c137, #2da411) !important;
  transition: none;
  font-weight: 300;
}
span.counter {
  background: linear-gradient(#028cdf, #0072c5) !important;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #f9f9f9;
  float: left;
  font-size: 2em !important;
  font-weight: bold;
  padding: 5px 10px;
}

/*Actividad: Cartola electrónica */
.cartola-info {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #777;
  min-height: 187px;
  padding: 20px 25px;
}
.cartola-info h3,
.cartola-info h4 {
  color: #555;
  font-size: 1.2em;
}
.cartola-info h3 {
  font-weight: bold;
  margin-bottom: -1px;
  padding-top: 10px;
}
.cartola-info h4 {
  font-weight: 300;
  margin-bottom: -1px;
}
.cartola-info.right {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 20px 0 0 0;
}
.cartola-info ul li {
  font-size: 0.9em;
  margin-right: 5px;
}
.cartola-info ul li strong {
  font-size: 1.85em;
  font-weight: 300;
}
.cartola-info.right p {
  border-top: 1px solid #ddd;
  font-size: 9px;
  padding: 10px 30px 0 30px;
}
/*.Contenido de cartola*/
.cartola-content .widget-body h3 {
  border-bottom: 1px solid #ddd;
  font-size: 1.2em;
  padding: 35px 0 13px 0;
}
.cartola-content .widget-body #tipodoc,
.cartola-content .widget-body #estadorecep,
.cartola-content .widget-body #aceptacioncom,
.cartola-content .widget-body #estadoarm {
  margin: -55px 0 -15px 0;
}
.cartola-content .table-options p {
  font-size: 0.9em;
  padding-left: 20px;
}
.cartola-content table tbody th {
  padding-left: 5px;
}

/* -------------------- Estilos para dividir niveles de componentes en el set -------------------- */

section.gris {
  width: 100%;
  background-color: #eeeeee;
}

section.blanco {
  background-color: #ffffff;
}

/* -------------------- ELEMENTOS DE LA INTERFAZ IC  -------------------- */

.nivel,
.nivel-sm {
  padding: 10px 0;
}
.nivel-2x,
.nivel-md {
  padding: 20px 0;
}
.nivel-3x,
.nivel-lg {
  padding: 30px 0;
}
.nivel-4x,
.nivel-xl {
  padding: 40px 0;
}

/* page-header (título+breadcrumb)*/
.page-header {
  background-color: #fff;
  padding: 15px 0 10px 0;
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.page-header.has-navbar-sub {
  margin-bottom: 0;
}
.page-header h2 {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
  color: #333;
  margin-bottom: 5px;
}
.page-header p {
  /*font-weight:lighter;*/
  font-size: 12px;
  color: #999;
  margin-bottom: 0;
}
.page-header ol,
.page-header ul {
  margin: 0;
}
.page-header small {
  font-size: 10px;
  line-height: 100%;
}
.page-header-fixed {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 40px;
}
.side-menu .page-header {
  padding-top: 0;
}

/* Secuencia */
.secuencia .well {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}
.secuencia h4 {
  margin-top: 0;
  font-weight: bold;
}
.secuencia .paso {
  border-right: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  height: 100%;
  border-bottom: 2px solid;
}
.secuencia .paso.disabled {
  color: #999;
}
.secuencia .paso.disabled.finalizado {
  color: #3c763d;
}
.secuencia .paso.disabled.finalizado i {
  color: #3c763d;
}
.secuencia .paso.text-primary {
  border-bottom: 2px solid #0072c5;
}
.secuencia .paso.final {
  border-right: 0;
}

/* -------------------- FONDOS -------------------- */

#bienvenida {
  background: url(/assets/img/bg-construccion2.jpg);
  background-size: cover;
  padding: 60px 0;
  color: #fff;
}

#nuevos-productos,
.nuevos-productos {
  background: url(/assets/img/bg-construccion2.jpg);
  background-size: cover;
  padding: 60px 0;
  color: #fff;
}

/* -------------------- CDA -------------------- */

#buscador-cda-lg {
  background: url(/assets/img/video-representante-legal.jpg) bottom;
  background-size: cover;
  padding: 50px 0;
  color: #fff;
}

#buscador-cda-sm {
  background: url(/assets/img/video-representante-legal.jpg) bottom;
  background-size: cover;
  padding: 30px 0;
  color: #fff;
}

/* -------------------- BCI CORNER FINANCIAMIENTO -------------------- */
.franja {
  /* background:url(../img/franja-bci.jpg); */
  background-size: cover;
  height: 10px;
}

#footer-bci {
  background: #333;
  background-repeat: no-repeat;
  padding: 40px 0;
  /*overflow:auto;*/
}

#footer-bci p {
  color: #fff;
  font-size: 11px;
}

/* -------------------- CLASES AUXILIARES -------------------- */

/* ---------- Alturas Fijas para Divs .a100---------- */
.a100 {
  height: 100px;
}
.a150 {
  height: 150px;
}
.a200 {
  height: 200px;
}
.a250 {
  height: 250px;
}
.a300 {
  height: 300px;
}
.a350 {
  height: 350px;
  padding-top: 25px;
}
.a400 {
  height: 400px;
}
.a450 {
  height: 450px;
}
.a500 {
  height: 400px;
}
.a600 {
  height: 400px;
}

.text-danger20 {
  color: rgba(190, 53, 53, 0.2);
}
.text-danger40 {
  color: rgba(190, 53, 53, 0.4);
}
.text-danger60 {
  color: rgba(190, 53, 53, 0.6);
}
.text-danger70 {
  color: rgba(190, 53, 53, 0.7);
}
.text-danger80 {
  color: rgba(190, 53, 53, 0.8);
}
.text-danger90 {
  color: rgba(190, 53, 53, 0.9);
}
.text-danger100 {
  color: rgba(190, 53, 53, 1);
  /*#BE3535**/
}

.table-footer {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 0 0 3px 3px;
  border: 1px solid #ddd;
  line-height: 0;
  margin-bottom: 20px;
}
ul.list-inline {
  margin-bottom: 0;
}
.separador {
  border-right: 1px solid #ddd;
}
.separador-l {
  border-left: 1px solid #ddd;
}

ul.normal {
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
}

ul.normal li {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
}
ul.normal li:last-child {
  border-bottom: none;
}

ul.datos {
  list-style: none;
  padding: 0 0 15px 0;
  margin: 0;
}

ul.datos li {
  display: block;
  text-align: right;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
}
ul.datos li:last-child {
  border: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

ul.datos li.doble {
  padding-bottom: 20px;
}

ul.datos li strong,
ul.datos li em {
  float: left;
}

/* ----- ESTILOS ICONSTRUYE 2.0 ----- */

.vitrina {
  /*background: url(../img/three.jpg);*/
  background: #eee;
  background-size: cover;
  padding: 30px 0px;
}

span.pipe {
  margin: 0 15px 0 15px;
}

/* ----- PARA ALERTAS TIPO POPOVER ----- */
/*.popoveralerta {
  position: absolute;
  top: -20px;
  left: 350px;
  display: block;
  z-index: 1010;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
          box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popoveralerta.top {
  margin-top: -10px;
}
.popoveralerta.right {
  margin-left: 10px;
}
.popoveralerta.bottom {
  margin-top: 10px;
}
.popoveralerta.left {
  margin-left: -10px;
}
.popoveralerta-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-weight: bold;
  line-height: 18px;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popoveralerta-content {
  padding: 9px 14px;
}
.popoveralerta > .arrow,
.popoveralerta > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popoveralerta > .arrow {
  border-width: 11px;
}
.popoveralerta > .arrow:after {
  content: "";
  border-width: 10px;
}
.popoveralerta.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, .25);
  border-bottom-width: 0;
}
.popoveralerta.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}
.popoveralerta.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, .25);
  border-left-width: 0;
}
.popoveralerta.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}
.popoveralerta.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, .25);
}
.popoveralerta.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popoveralerta.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, .25);
}
.popoveralerta.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}*/

/* ----- Panels Data ----- */

.pd .panel-heading {
  font-size: 14px;
}
.pd .panel-heading span {
  float: right;
  display: block;
  font: italic 11px/20px Arial;
}

.pd .panel-heading span .btn {
  font-style: normal;
}

.pd table tr td {
  text-align: center;
}
.pd td small {
  font-size: 13px;
  color: #999;
}
.pd td a small {
  color: #ff5722;
}

.pd .panel-body {
  text-align: center;
}

.pd span.badge {
  background: none;
  color: #777;
  font: bold 16px/100% Arial;
}

.pd h2,
.pd h3,
.pd h4,
.pd h5 {
  /*color:#428bca;*/
  color: #777;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}
.pd table tr td h3 a {
}

.pd td tr.dr h2 {
  margin-top: 20px;
}
.pd .panel-body p {
  margin-bottom: 20px;
}
.pd p,
.pd small,
.pd strong,
.pd em {
  color: #666;
  margin-bottom: 10px;
  display: block;
}

.pd tr td.principal {
  text-align: left;
  padding-left: 30px;
  width: 320px;
  background: #f5f5f5;
  padding-right: 30px;
}
.pd table tr td.principal h2 {
  font-size: 38px;
  margin-bottom: -20px;
  font-weight: 500;
  color: #555;
}
.pd table tr td.principal h3 {
  margin-bottom: 20px;
  color: #555;
  font-weight: normal;
  font-size: 18px;
}
/*paneles laterales en ciclos*/
.ciclos {
  /*background: #AB2900;*/
  width: 25%;
}
.ciclos01 {
  background: #d33200;
  width: 20%;
}
.ciclos02 {
  background: #fa3c00;
  width: 20%;
}
/*.ciclos h4, .ciclos01 h4, .ciclos02 h4, .ciclos a, .ciclos01 a, .ciclos02 a {
	color: #FFFFFF;
}*/
.acumulados {
  background: #ffddd3;
  text-align: center;
}
.acumulados-factura {
  background: #ffcebf;
  text-align: right;
}
.acumulados-factura a {
  color: #333333;
}
.blured {
  /* background-image: url(../ic/img/blured01.jpg); */
  height: 250px;
}
td .aviso {
  color: #ff5722;
  border: 2px solid #ff9371;
}
/* ---------- Divisores (borders) de tabla ----------*/

/* ----- divisor top, right, bottom, left----- */

.pd td.bt {
  border-top: 1px solid #ddd;
}
.pd td.br {
  border-right: 1px solid #ddd;
}
.pd td.bb {
  border-bottom: 1px solid #ddd;
}
.pd td.bl {
  border-left: 1px solid #ddd;
}

/* Aplica border a todos los lados de los td */
.pd table tr.ball td {
  border-right: 1px solid #ddd;
  padding: 10px 0 30px 0;
}

/* quita border-right a un td especifico hijo de tr.ball */
.pd tr.ball td.sb {
  border-right: none;
}
.pd td.br h2 {
  margin-top: 80px;
}

/*----- Filtros -----*/
.filtros {
  margin-bottom: 15px;
  background-color: #ffffff;
}
.filtros.well,
.filtros.panel-heading {
  padding: 10px 15px;
}
.filtros .row {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.filtros label {
  font-family: "Roboto", sans-serif;
  width: 100%;
  color: #333333;
  font-size: 12px;
}
.filtros .col-xs-10 {
  border-right: 1px solid #e3e3e3;
}
.filtros .col-xs-2,
.filtros .col-xs-3,
.filtros .col-xs-4,
.filtros .col-xs-5,
.filtros .col-xs-6,
.filtros .col-xs-7,
.filtros .col-xs-8,
.filtros .col-xs-9 {
  padding-top: 1px;
  padding-bottom: 2px;
}
.filtros .input-daterange {
  padding-top: 0;
  padding-bottom: 0;
}
.filtros .botones .btn-group {
  margin: 18px 0px 0px 0 !important;
}
.filtros .botones .btn-primary {
  background-color: #0994ff;
  border: 1px solid #0994ff;
  border-radius: 2px !important;
  margin-left: 10px !important;
}

.filtros .btn-link {
  color: #0994ff;
  font-family: "Roboto", sans-serif;
}

.filtros .botones .btn-default {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
}
.filtros .botones .btn.dropdown-toggle.selectpicker.btn-default {
  margin-top: 0px;
}
.filtros .botones.col-xs-2 {
  padding-top: 12px;
}
.filtros .boton.col-xs-2 {
  padding-top: 6px;
}

/*Página de Error*/
.error-default {
  padding: 100px 0;
}
.error-default .icon {
  font-size: 4em;
  text-align: center;
  color: #ccc;
}

table.proceso {
  background: #fff;
}
table.proceso td ul li strong {
  text-align: left;
}
table.proceso span.badge {
  background: #fff;
  color: #666;
}
table.proceso tr td strong {
  font-weight: normal;
  font-size: 12px;
}
/*Financiamiento Bci*/
/********************************************************************/
/*tabla colpasable*/

.table > tbody.tabla-collapse + tbody.tabla-collapse {
  border-top: none;
}
tr.collapse.in {
  display: table-row;
}
tbody.tabla-collapse td span.cliente {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
tbody.tabla-collapse td span.datos {
  display: block;
}
tbody.tabla-collapse td.td-collapse {
  padding: 0px;
  /*border: 0 none;*/
}
tbody.tabla-collapse tr td {
  vertical-align: middle;
}
tbody.tabla-collapse td.clickable,
tbody.tabla-collapse td.check {
  border-right: 1px solid #ddd;
}
tbody.tabla-collapse td.clickable,
tbody.tabla-collapse td.gestion {
  border-left: 1px solid #ddd;
}
i.alto {
  color: #5cb85c !important;
}
i.medio {
  color: #d89e0e !important;
}
i.bajo {
  color: #be3535 !important;
}
i.neutro {
  color: #dddddd !important;
}
/*nav tab buttom collapse*/
.panel.with-nav-tabs > .panel-heading {
  padding: 10px 15px 0 5px;
}
.panel.with-nav-tabs .tab-content .tab-pane .panel-heading {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}
.panel.with-nav-tabs .nav-tabs {
  border-bottom: none;
  padding-left: 0px;
}
.panel.with-nav-tabs .nav-justified {
  margin-bottom: -1px;
}
.panel.with-nav-tabs .tab-content {
  border: none;
}
.collapse-btn-table ul.nav {
  float: left;
  left: 45%;
  list-style-type: none;
  margin-top: -30px;
  padding: 0;
  position: relative;
}
.collapse-btn-table ul.nav li a {
  padding: 5px 15px;
  cursor: pointer;
}
/*** Agenda  financiamiento****/
.agenda td.principal {
  width: 30% !important;
}
/*.highcharts-container{
	border:1px solid #ddd;
	margin-bottom: 20px;
}*/
.listas-agregar .panel-body {
  padding: 0px;
}
.listas-agregar .list-group,
.listas-agregar .table-max-heigh {
  margin-bottom: 0px;
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
}
.list-extensive {
  max-height: 20vh;
  min-height: 20vh;
  overflow-y: scroll;
}
.listas-agregar .list-group .list-group-item {
  border-left: none;
  border-right: none;
}
.listas-agregar .input-group.buscador,
.listas-agregar .buscador {
  padding: 10px;
}
.badge.up {
  margin-left: -8px;
  padding: 3px 6px;
  position: relative;
  top: -9px;
  font-size: 10px;
  background-color: #fa3e3e;
  font-weight: 400;
}
/********************************************************************/
/*** tag lista filtros***/

.well.tag-filtros .container-tag {
  margin: 2px;
}
/*.well.tag-filtros{
	padding: 0 15px;
}
.well.tag-filtros .col-xs-11{
	border-right: 1px solid #e3e3e3;
}
.well.tag-filtros .col-xs-11 ul.list-inline{
padding: 15px 0px;
}*/

/********************************************************************/
/*** Toggle on offf***/
.modal-body label.toggle-off,
.modal-body label.toggle-on {
  margin-bottom: 0px !important;
}

/********************************************************************/
/*** PANEL con nav tab***/
.with-nav-tabs.panel-default .nav-tabs > li > a,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  color: #777;
}
.with-nav-tabs.panel-default .nav-tabs > .open > a,
.with-nav-tabs.panel-default .nav-tabs > .open > a:hover,
.with-nav-tabs.panel-default .nav-tabs > .open > a:focus,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  color: #777;
  background-color: #ddd;
  border-color: transparent;
}
.with-nav-tabs.panel-default .nav-tabs > li.active > a,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:focus {
  color: #555;
  background-color: transparent;
  border-color: #ddd;
  border-bottom-color: transparent;
}
.with-nav-tabs.panel-default .nav-tabs > li.dropdown .dropdown-menu {
  background-color: #f5f5f5;
  border-color: #ddd;
}
.with-nav-tabs.panel-default .nav-tabs > li.dropdown .dropdown-menu > li > a {
  color: #777;
}
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > li
  > a:hover,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > li
  > a:focus {
  background-color: #ddd;
}
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a:hover,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a:focus {
  color: #fff;
  background-color: #555;
}

/*====================
Tren de estados I
======================*/
.wizard {
  margin-bottom: 3px;
}
.wizard p {
  padding: 1px 3px;
  margin-right: 0px;
  background: #e9e9e9;
  position: relative;
  display: inline-block;
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 0px;
}
.wizard p:before {
  width: 0;
  height: 0;
  border-top: 9px inset transparent;
  border-bottom: 9px inset transparent;
  border-left: 7px solid #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.wizard p:after {
  width: 0;
  height: 0;
  border-top: 9px inset transparent;
  border-bottom: 9px inset transparent;
  border-left: 7px solid #e9e9e9;
  position: absolute;
  content: "";
  top: 0;
  right: -7px;
  z-index: 2;
}
.wizard p:first-child:before,
.wizard p:last-child:after {
  border: none;
}
.wizard p:first-child {
  padding: 1px 3px 1px 7px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.wizard p:last-child {
  padding: 1px 7px 1px 3px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.wizard .badge {
  margin: 0 0 0 10px;
  position: relative;
  top: 0px;
  font-size: 9px;
  padding: 3px 5px;
  background: transparent;
  color: #999;
  font-size: 10px;
}
.wizard p:first-child .badge {
  margin-left: 0;
}
.wizard .current {
  background: #007acc;
  color: #fff;
}
.wizard .current:after {
  border-left-color: #007acc;
}
.wizard p.ok {
  color: #449d44;
  background: #eaf6ea;
}
.wizard p.ok .badge {
  background: #449d44;
}
.wizard p.ok:after {
  border-left-color: #eaf6ea;
}
.wizard p.error {
  color: #d9534f;
  background: #fbeeed;
}
.wizard p.error .badge {
  background: #d9534f;
}
.wizard p.erroe:after {
  border-left-color: #fbeeed;
}
.wizard p.warning {
  color: #d89e0e;
  background: #fdf3dd;
}
.wizard p.warning .badge {
  background: #d89e0e;
}
.wizard p.warning:after {
  border-left-color: #fdf3dd;
}
/*=======
secuancia
=========*/
ul.secuencias {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ul.secuencias li {
  display: table-cell;
  width: 1%;
}
ul.secuencias li div.pasos {
  padding: 2px 3px 2px 20px;
  background: #efefef;
  font-size: 10px;
  line-height: 13px;
  margin-right: 3px;
  position: relative;
}

ul.secuencias li div.pasos span {
  display: block;
}
ul.secuencias li div.pasos:before {
  width: 0;
  height: 0;
  border-top: 15px inset transparent;
  border-bottom: 15px inset transparent;
  border-left: 13px solid #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
ul.secuencias li div.pasos:after {
  width: 0;
  height: 0;
  border-top: 15px inset transparent;
  border-bottom: 15px inset transparent;
  border-left: 13px solid #efefef;
  position: absolute;
  content: "";
  top: 0;
  right: -13px;
  z-index: 2;
}
ul.secuencias li:first-child div.pasos:before,
ul.secuencias li:last-child div.pasos:after {
  border: none;
}

ul.secuencias li:first-child div.pasos {
  border-radius: 4px 0px 0px 4px;
  padding: 2px 3px 2px 10px;
}
ul.secuencias li:last-child div.pasos {
  border-radius: 0 4px 4px 0;
}
/*secuenciaos en detalle modal*/
.modal-body-column ul.secuencias {
  margin-bottom: 15px;
}
.modal-body-column ul.secuencias li div.pasos {
  padding: 10px 5px 10px 30px;
  background: #efefef;
  font-size: 14px;
  line-height: 21px;
  margin-right: 3px;
  position: relative;
}
.modal-body-column ul.secuencias li:first-child div.pasos {
  padding: 10px 5px 10px 20px;
}
.modal-body-column ul.secuencias li div.pasos:before {
  border-top: 31px inset transparent;
  border-bottom: 31px inset transparent;
  border-left: 13px solid #bbb;
}
.modal-body-column ul.secuencias li div.pasos:after {
  border-top: 31px inset transparent;
  border-bottom: 31px inset transparent;
}
.modal-body-column ul.secuencias li:first-child div.pasos:before,
.modal-body-column ul.secuencias li:last-child div.pasos:after {
  border: none;
}
/********************************************************************/
/*** input-group hacks ***/

.input-group-inline {
  display: inline-table;
  vertical-align: middle;
}
.input-group-inline .input-group-addon,
.input-group-inline .input-group-btn,
.input-group-inline .form-control {
  width: auto !important;
}
/*====================
daterangepicker
======================*/
.daterangepicker-input {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 12px;
  border-radius: 3px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  float: left;
  text-align: left;
}
.daterangepicker-input-xs {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 12px;
  border-radius: 3px;
  height: 25px;
  display: inline-block;
  overflow: hidden;
  float: left;
  text-align: left;
}
.input-group .daterangepicker-input,
.input-group .daterangepicker-input-xs {
  border-radius: 0px 2px 2px 0px;
}

/*====================
tabla scroll
======================*/
.tabla-scroll {
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.tabla-scroll thead.fixed tr {
  display: block;
  width: 97%;
  width: calc(100% - 18px);
}
/*tbody scroll*/
.tabla-scroll tbody.scrolling {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
}
.tabla-scroll tbody.scrolling tr {
  width: 97%;
  width: calc(100% - 18px);
}
/*tfoot scroll*/
.tabla-scroll tfoot.totales {
  display: block;
}
.tabla-scroll tfoot.totales tr {
  width: 97%;
  width: calc(100% - 18px);
}
.tabla-scroll th.no-border-r {
  border-right: none;
}
.tabla-scroll th.no-border-l {
  border-left: none;
}

.tabla-scroll tfoot.totales tr th {
  background: #777;
  color: #fff;
  font-weight: normal;
  border-color: #999;
}
.tabla-scroll > thead > tr > td.active,
.tabla-scroll > tbody > tr > td.active,
.tabla-scroll > tfoot > tr > td.active,
.tabla-scroll > thead > tr > th.active,
.tabla-scroll > tbody > tr > th.active,
.tabla-scroll > tfoot > tr > th.active,
.tabla-scroll > thead > tr.active > td,
.tabla-scroll > tbody > tr.active > td,
.tabla-scroll > tfoot > tr.active > td,
.tabla-scroll > thead > tr.active > th,
.tabla-scroll > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #ddd;
  border-color: #c4c4c4;
}
.tabla-scroll > tbody + tbody {
  border-top: 1px solid #e9e9e9;
}
/*============================
popover
=============================*/
.popover {
  border-radius: 3px;
  max-width: 800px;
}
.popover-content {
  /*padding: 9px 14px;*/
  padding: 9px;
  font-size: 12px;
  line-height: 16px;
}
.popover-lg {
  min-width: 450px;
}
.tab-info .popover {
  min-width: 450px;
}
.popover-content-lg {
  padding: 15px;
}
.popover-footer {
  padding: 10px 14px;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
  text-align: right;
}

/*====================
Homologación modal a IC
======================*/
p.lead {
  font-size: 21px;
  margin-bottom: 20px;
  font-weight: 200;
  line-height: 1.4em;
}
/*====================
Formulario de Creación
======================*/
.col-form {
  width: 80%;
}
.col-resumen {
  width: 20%;
}
.col-form,
.col-resumen {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.resumen-fijo {
  position: fixed;
  width: 18%;
}
.resumen-fijo .well-light-orange {
  background: #fff;
  /* margin-left: -31px; */
  /* margin-top: 10px; */
  border-radius: 3px;
  padding: 15px 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
}
.well-light-orange dd,
.well-light-orange ul li {
  margin-bottom: 5px;
}
.panel .creacion {
  padding: 20px 15px 10px 10px;
  position: relative;
  border-radius: 0;
  margin-bottom: 10px;
}
.creacion .panel-heading {
  background: #ffffff;
}
.versalita {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 1px;
}
.font-baja {
  text-transform: lowercase;
}
.title-section-form h3 {
  background-color: #fff;
  display: inline-block;
  vertical-align: top;
  z-index: 2;
  position: relative;
  padding: 7px 10px 2px 0;
  left: 0px;
  font-size: 13px;
  letter-spacing: 0.9px;
  font-weight: 400;
}
.title-section-form {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}
.checkout-step {
  border-top: 1px solid #eee;
  font-size: 14px;
  /* padding: 10px 15px; */
  padding: 10px 15px 10px 10px;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
}
.checkout-step-body {
  padding-left: 46px;
  padding-top: 20px;
  margin-bottom: 10px;
}
.checkout-step-number {
  border-radius: 50%;
  border: 1px solid #0072c5;
  display: inline-block;
  font-size: 14px;
  height: 32px;
  margin-right: 10px;
  padding: 5px;
  text-align: center;
  width: 32px;
  color: #0072c5;
}
/*.title-section-form:before {
	content: "";
	background: #ddd;
	display: block;
	height: 1px;
	position: absolute;
	left: 15px;
	right: 180px;
	top: 40%;
	z-index: 1;
}
.modal-body .title-section-form:before {
	content: "";
	background: #ddd;
	display: block;
	height: 1px;
	position: absolute;
	left: 15px;
	right: 15px;
	top: 52%;
	margin-top: -1px;
	z-index: 1;
}*/
.spFechas .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  min-width: 80px;
  border-radius: 3px 0 0 3px;
}
.radio-inline,
.checkbox-inline {
  line-height: 1.8em;
}
.form-control {
  box-shadow: none;
}
/*-------
Nuevo timeline
 -------*/
.wrap-timeline {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.timeline-item {
  border-left: 2px solid #f0906e;
  padding-bottom: 15px;
  position: relative;
}
/*.timeline-item:before {
  position: absolute;
      top: 4px;
      left: 4px;
      content: "";
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid red;
      display: inline-block;
}*/
.timeline-item.last {
  border-left: 0;
}
span.box-time-orange {
  height: 24px;
  width: 24px;
  border-radius: 300px;
  background: #0072c5;
  border: 2px solid #fff;
  display: block;
  margin-left: -13px;
  float: left;
  margin-right: 10px;
  text-align: center;
  color: #ffffff;
}
.timeline-item.last span.box-time-orange {
  margin-left: -8px;
}
.timeline-item-content {
  background: #fdf8f8;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #777;
}
.timeline-item-content.approved {
  background: #f8eae7;
  color: #ff5722;
}
.timeline-item-content.approved .fa {
  /*font-size: 15px;*/
  color: #0072c5;
}
.timeline-item-content.approved p {
  color: #38b5ff;
}
.timeline-item-content.rejection {
  color: #d9534f;
  background: #fbeeed;
}
.timeline-item-content.rejection .fa {
  font-size: 20px;
  color: #d9534f;
}
.timeline-item-content.rejection p {
  color: #d9534f;
}
.time-in-timeline {
  line-height: 1.9em;
  color: #ff5722;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 0;
}
.timeline-item-content h3 {
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}
span.box-time-blue {
  height: 32px;
  width: 32px;
  border-radius: 300px;
  background: #0072c5;
  border: 2px solid #fff;
  display: block;
  margin-left: -16px;
  float: left;
  margin-right: 10px;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 16px;
}
.agregar-object-hover {
  margin-left: 20px;
  text-align: center;
  position: relative;
}
.aprobador-add {
  display: block;
  border: 2px dashed #ddd;
  border-radius: 3px;
  opacity: 1;
  /*transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;*/
  font-size: 15px;
  text-decoration: none;
  margin-left: 20px;
  padding: 5px 5px;
}
.agregar-object-hover a .hiddenbox {
  display: block;
  border: 2px dashed #ddd;
  border-radius: 3px;
  opacity: 0;
  /*transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;*/
  font-size: 1px;
  text-decoration: none;
  margin-left: 15px;
  padding: 10px 5px;
}
.agregar-object-hover:hover a .hiddenbox {
  opacity: 1;
  font-size: 14px;
  padding: 15px 5px;
  margin: 10px 15px 15px 15px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.agregar-object-hover a i.icon-agregar {
  position: absolute;
  top: -13px;
  left: -1px;
  visibility: visible;
  opacity: 1;
  font-size: 16px;
  color: #5cb85c;
}
a i.icon-agregar {
  font-size: 19px;
  color: #5cb85c;
}
.panel .panel-timeline {
  margin-left: 10px;
  background: #f5f5f5;
  /*-webkit-box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.1);
	box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.1);*/
}
.panel .panel-timeline i {
  color: #0072c5;
  font-size: 24px;
}
.panel-timeline .panel-heading {
  border: none;
  background: #ebebeb;
}
.panel-timeline .panel-body {
  padding: 8px 15px;
}
.panel-timeline .panel-heading .time-in-timeline {
  line-height: 0.9em;
}
/*******
  Detalle con el panel de la izquierda collapsable
*******/
.panel-width-collapse-side {
  margin: 0;
  min-height: 600px;
}
.navbar-primary {
  bottom: 0px;
  left: 15px;
  position: absolute;
  top: 0px;
  width: 300px;
  z-index: 8;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /*border-right: 1px solid #ddd;*/
  padding: 15px;
  margin-left: -1px;
}
.navbar-primary ul.datos li {
  padding-bottom: 0px;
  margin-bottom: 1px;
  border-bottom: none;
}
.content-collapse-side {
  padding-right: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.content-collapse-side .versalita {
  margin-bottom: 10px;
  font-size: 10px;
  letter-spacing: 0.7px;
  font-weight: bold;
}
.navbar-primary.collapsed {
  width: 31px;
}
.navbar-primary.collapsed .glyphicon {
  font-size: 22px;
}
.navbar-primary.collapsed .nav-label {
  display: none;
}
.btn-expand-collapse {
  position: absolute;
  display: block;
  top: 0;
  z-index: 8;
  right: 0px;
  bottom: 0;
  padding: 3px 0;
  border-top: none;
  border-bottom: none;
  border-left: 2px solid #0072c5;
  border-right: 2px solid #f8eae7;
  color: #0072c5;
  font-size: 16px;
  text-align: center;
  width: 30px;
  background: #fff;
  text-decoration: none;
  margin-right: -1px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 600px;
}
.btn-expand-collapse .fa {
  /*position: fixed;*/
  top: 150px;
  -webkit-transition: -webkit-transform 0.45s ease;
  transition: transform 0.45s ease;
}
.collapsed .btn-expand-collapse .fa {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.45s ease;
  transition: transform 0.45s ease;
}

.collapsed .btn-expand-collapse {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
  background: #fff !important;
  color: #0072c5;
  text-decoration: none;
}
.btn-expand-collapse:active {
  background-color: #111;
}
.navbar-primary-menu,
.navbar-primary-menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.collapsed .navbar-primary-menu {
  display: none;
}
/*******
  Detalle con el panel de la derecha collapsable
*******/
.panel .arrow-collapse img {
  max-width: 25px;
  margin: 0 auto -8px 20px;
  opacity: 1;
  left: 30;
}
.panel a.collapsed .arrow-collapse img {
  display: none;
  opacity: 0;
  /*margin: 13px auto -17px; downn */
  margin: -2px auto -9px;
}
.main-content {
  margin-left: 300px;
}
.main-content-inner {
  padding: 15px;
}
.collapsed + .main-content {
  margin-left: 30px;
}
/*******
  Detalle edición de gasto
*******/
.detalle-gasto {
  border-left: 1px solid #ddd;
}
.detalle-gasto-right {
  border-right: 1px solid #ddd;
}
.slide .carousel-control.left {
  background-image: none !important;
  background-image: none;
}
.slide .carousel-control.right {
  left: auto;
  right: 0;
  background-image: none;
  background-image: none;
}
.slide .carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40%;
  opacity: 1;
  filter: alpha(opacity=100);
  font-size: 10px;
  color: #0072c5;
  text-align: center;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.slide .carousel-control .glyphicon-chevron-right,
.slide .carousel-control .glyphicon-chevron-left {
  font-size: 20px;
}
/*******
  Inputs modal
*******/
.modal .form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  box-shadow: none;
  background: #f9f9f9;
  border-color: #e7e7e7;
  color: #999;
  font-weight: 300;
}
.icon-circle-lg {
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #ffffff;
  display: inline-block;
  font-size: 18px;
  height: 48px;
  /*margin-right: 10px;*/
  padding: 10px;
  text-align: center;
  width: 48px;
  color: #0072c5;
}
.icon-circle-md {
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #ffffff;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  padding: 2px;
  text-align: center;
  width: 32px;
  color: #0072c5;
}
.icon-circle-sm {
  border-radius: 50%;
  border: 1px solid #eee;
  background: #ffffff;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-size: 12px;
  height: 24px;
  /*margin-right: 3px;*/
  padding: 3px;
  text-align: center;
  width: 24px;
  color: #0072c5;
  margin-top: -1px;
}
.icon-circle-xs {
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #ffffff;
  display: inline-block;
  font-size: 11px;
  text-align: center;
  height: 22px;
  /*margin-right: 3px;*/
  padding: 0;
  text-align: center;
  width: 22px;
  color: #0072c5;
}
.icon-circle-sm.active {
  border-radius: 50%;
  /*border: 1px solid #ddd;*/
  background: #0072c5;
  display: inline-block;
  font-size: 14px;
  padding: 6px;
  text-align: center;
  font-size: 12px;
  height: 24px;
  /*margin-right: 3px;*/
  padding: 5px;
  text-align: center;
  width: 24px;
}
.icon-circle-sm.active i {
  color: #ffffff;
}
.icon-circle-sm.active-success {
  border-radius: 50%;
  /*border: 1px solid #ddd;*/
  background: #5cb85c;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-size: 12px;
  height: 24px;
  /*margin-right: 3px;*/
  padding: 4px;
  text-align: center;
  width: 24px;
}
.icon-circle-sm.active-success i {
  color: #ffffff;
}
.icon-circle-md.active-success {
  border-radius: 50%;
  /*border: 1px solid #ddd;*/
  background: #5cb85c;
  display: inline-block;
  font-size: 14px;
  padding: 3px;
  text-align: center;
  font-size: 18px;
  height: 32px;
  /*margin-right: 3px;*/
  text-align: center;
  width: 32px;
}
.icon-circle-md.active-success i {
  color: #ffffff;
}

.icon-circle-md.active-danger {
  border-radius: 50%;
  /*border: 1px solid #ddd;*/
  background: #d9534f;
  display: inline-block;
  font-size: 14px;
  padding: 3px;
  text-align: center;
  font-size: 18px;
  height: 32px;
  color: #ffffff;
  /*margin-right: 3px;*/
  text-align: center;
  width: 32px;
}
.icon-circle-sm.active-danger {
  border-radius: 50%;
  /*border: 1px solid #ddd;*/
  background: #d9534f;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-size: 12px;
  height: 24px;
  /*margin-right: 3px;*/
  padding: 4px;
  text-align: center;
  width: 24px;
}
.icon-circle-sm.active-danger i {
  color: #ffffff;
}
.icon-circle-xs.active-danger {
  border-radius: 50%;
  border: 1px solid #d9534f;
  background: #d9534f;
  display: inline-block;
  font-size: 8px;
  text-align: center;
  height: 10px;
  /*margin-right: 3px;*/
  padding: 0;
  text-align: center;
  width: 10px;
  color: #fff;
  font-weight: 700;
}
.icon-circle-xs.active-info {
  border-radius: 50%;
  border: 1px solid #ffc107;
  background: #ffc107;
  display: inline-block;
  font-size: 8px;
  text-align: center;
  height: 10px;
  /*margin-right: 3px;*/
  padding: 0;
  text-align: center;
  width: 10px;
  color: #fff;
  font-weight: 700;
}
.checkout-step-title-table {
  font-size: 22px;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
  margin: 0px;
  text-decoration: none;
}
.checkout-step-title {
  font-size: 18px;
  font-weight: 300;
  vertical-align: middle;
  display: inline-block;
  margin: 0px;
  text-decoration: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  box-shadow: none;
  background: #f9f9f9;
  border-color: #e7e7e7;
  color: #999;
}
/*******
   Creación de Usuario
 *******/
.bg-radio-collapse2 {
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.chip {
  display: inline-block;
  height: 26px;
  /*font-size: 13px;*/
  font-size: 75%;
  font-weight: 500;
  color: #00314b;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 0.25em;
  /*color: #0072C5;*/
  color: #00314b;
  line-height: 26px;
  padding: 0 12px;
  border-radius: 0.25em;
  /*background-color: #E9F6FF;*/
  background-color: #d5f0ff;
  margin-bottom: 1rem;
  margin-right: 1rem;
  /*border: 1px solid #C1E8FF;*/
}
.chip .close {
  cursor: pointer;
  float: right;
  font-size: 12px;
  line-height: 26px;
  padding-left: 8px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
/*******
   Estilos para el historial
 *******/
.wrap-timeline {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.timeline-item {
  border-left: 2px solid #0072c5;
  padding-bottom: 15px;
}
.time-in-timeline {
  line-height: 1.9em;
  color: #0072c5;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 5px;
}
.timeline-item-content {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #777;
}
.timeline-item-content.approved {
  /*background: #f1f5f9;*/
  background: #fff;
  color: #0072c5;
  border: 2px solid #f1f5f9;
}
.timeline-item-content.no-approved {
  /*background: #f1f5f9;*/
  background: #fff;
  color: #777;
  border: 2px solid #f5f5f5;
}
.timeline-item-content.rechazada {
  /*background: #f1f5f9;*/
  background: #fff;
  color: #d9534f;
  border: 2px solid #fbeeed;
}
.timeline-item-content h3 {
  font-size: 12px;
  margin: 0;
  font-weight: 500;
}
/*******
  Table collpase
*******/
.panel-category .panel-heading {
  padding: 3px 15px;
  background: #f9f9f9;
}
.panel-category .panel-heading h3 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 10px 0;
}
.panel-category .list-group-item,
.panel-dashboard .list-group-item {
  padding: 5px 15px;
}
.panel-category .panel-heading p.panel-title {
  text-transform: uppercase;
  margin: 5px 0;
  font-weight: 700;
  font-size: 12px;
}
.total-fixed {
  position: fixed;
  bottom: 90px;
  background: #fff;
  border: 1px solid #eee;
  padding: 15px 20px;
  border-radius: 2px;
  right: 40px;
  z-index: 999;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.total-fixed h3 {
  font-size: 10px;
  margin: 0;
  color: #777;
  text-align: left;
}
.total-fixed h2 {
  margin: 0;
  font-size: 20px;
  text-align: right;
}
.overlap-row span.mostrar {
  visibility: hidden;
}
.overlap-row:hover span.mostrar {
  visibility: visible;
}
.overlap-row a:hover {
  text-decoration: none;
}
.accordion-toggle {
  text-decoration: none;
  text-transform: uppercase;
}
.accordion-toggle i.arrow {
  float: right;
  -webkit-transition: -webkit-transform 0.65s ease;
  transition: transform 0.65s ease;
}
.accordion-toggle.collapsed i.arrow {
  float: right;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.65s ease;
  transition: transform 0.65s ease;
}
/*******
  List category
*******/
.btop {
  border-top: 2px solid #ddd;
}
.position {
  padding: 15px 0 0 10px !important;
}
ul.list-category {
  /*border-bottom: 1px solid #ddd;*/
  margin-bottom: 20px;
  /*padding-bottom: 10px;*/
}
ul.list-category li {
  display: inline-block;
  /*margin-right: 10px;*/
  margin-top: 10px;
}
.altura-label {
  line-height: 22px;
}
/*******
  Panel Dashboard
*******/
.panel-dashboard h3 {
  font-size: 22px;
}
.panel-dashboard {
  min-height: 220px;
}
.panel-dashboard p {
  font-size: 1.1em;
  font-weight: 500;
}
.panel-default a.panel-links {
  text-decoration: none;
}
.icon-border {
  border-radius: 50%;
  border: 3px solid #0072c5;
  display: inline-block;
  font-size: 36px;
  height: 72px;
  /*margin-right: 10px;*/
  padding: 12px;
  text-align: center;
  width: 72px;
  color: #0072c5;
  margin-top: 20px;
}
.big {
  /*font-size: 2.2em;*/
  /*font-weight: 500;*/
}
.highcharts-container
 /*******
   Vista Rendición
 *******/
 .img-rounded {
  margin: 0 auto;
  width: 100%;
  max-width: 70px;
}
.img-rounded img {
  width: 100%;
  height: auto;
  border: 2px solid #ddd;
  padding: 2px;
}
.panel .uploader {
  border: 2px dashed #ddd;
}
.upload-panel a:hover .uploader {
  background: #f5f5f5;
  text-decoration: none;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.panel-position {
  position: relative;
}
span.close-up {
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #ffffff;
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  padding: 6px;
  text-align: center;
  width: 24px;
  font-size: 12px;
  height: 24px;
  margin-right: 3px;
  padding: 3px;
  text-align: center;
  width: 24px;
  color: #0072c5;
  right: 0px;
  position: absolute;
  top: -10px;
}
/*******
     Vista Gastos
   *******/
.panel-groups .input-group-addon,
.panel-groups .form-control {
  border-radius: 0;
  border: none;
}
.panel-groups .input-group {
  border: 1px solid #ddd;
}
/*******
  Flujo de Aprobación
*******/
.wrap-all-flujo {
  padding: 30px 17px;
}
span.box-flujo {
  height: 32px;
  width: 32px;
  border-radius: 300px;
  background: #f1f5f9;
  border: 2px solid #d5efff;
  display: block;
  margin-left: -16px;
  float: left;
  margin-right: 10px;
  text-align: center;
  color: #5cb85c;
  font-size: 14px;
  color: #0072c5;
  padding: 5px;
}
.wrap-messages-flujo {
  border-left: 3px solid #d5efff;
  min-height: 100%;
  padding-bottom: 0;
  margin-top: -15px;
}
.comentario-flujo {
  background: #d5efff;
  border-radius: 4px;
  padding: 10px;
  margin-left: 10px;
  margin-top: -7px;
}
.comentario-flujo:after {
  position: absolute;
  top: 22px;
  left: 19px;
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d5efff;
  display: inline-block;
}
.row-active {
  background: #f1f5f9;
  border-bottom: 1px solid #d5efff;
  border-top: 1px solid #d5efff;
  border-right: 2px solid #0072c5;
}
/* ---------- ESTILOS HEREDADOS DESDE NEW-IC -------------------- */
/*============================
Alineación vertical de tablas
==============================*/
.table.valignmiddle td,
.table.valignmiddle th {
  vertical-align: middle;
}
td.valignmiddle {
  vertical-align: middle !important;
}

/*================
Tamaño de fuentes
==================*/
.f9 {
  font-size: 9px !important;
}

/*======================================
Estilo para campo de documento y pdf417
========================================*/
.img-pdf417 {
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #ccc;
}

/*===========================
Estilo para radio button
==============================*/
.modal .input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.modal .input-group-xs > .input-group-btn > .btn {
  min-width: 60px;
}

/*** Tamaño minimo para input combinados ***/
span#combinado.input-group-addon.middle {
  min-width: 10px;
}

/*=====================
filtros
=======================*/
.well.tag-filtros {
  margin-top: -31px;
}
.panel-body.filtros {
  padding: 0 15px;
}
/*filtros -xs*/
.filtros-xs .col-xs-2,
.filtros-xs .col-xs-3,
.filtros-xs .col-xs-4,
.filtros-xs .col-xs-5,
.filtros-xs .col-xs-6,
.filtros-xs .col-xs-7,
.filtros-xs .col-xs-8,
.filtros-xs .col-xs-9 {
  padding-top: 1px;
  padding-bottom: 2px;
}
.filtros .botones.col-xs-2 {
  border-left: 1px solid #e3e3e3;
  left: -1px;
}
.filtros-xs .botones .btn-group,
.filtros-xs .botones .btn {
  margin: 10px 0px 0px 0;
}
.filtros-xs .botones-proveedor .btn-group,
.filtros-xs .botones-proveedor .btn {
  margin: 20px 0px 0px 0;
}
.filtros-xs .input-group-xs .daterangepicker-input {
  height: 25px;
  font-size: 11px;
  line-height: 1.5;
  padding: 2px 5px;
}
.filtros-xs label {
  margin-bottom: 3px;
}
.btn-filtros {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -1px;
}
.btn-filtros a {
  background: #fff;
  color: #4b4b4b;
  border: 1px solid #e3e3e3;
  padding: 3px 15px;
  border-radius: 0px 0px 10px 10px;
  font-size: 11px;
}
.btn-filtros a:hover {
  text-decoration: none;
}

.btn-filtros a i {
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-filtros a.down i {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.filtros-xs .imput-chek-radio .input-group-addon,
.filtros .imput-chek-radio .input-group-addon {
  background: #fff;
}
.filtros-xs .imput-chek-radio .input-group-addon input[type="radio"],
.filtros-xs .imput-chek-radio .input-group-addon input[type="checkbox"] {
  margin-top: 4px;
}
.filtros-xs .imput-chek-radio .input-group-addon:first-child,
.filtros .imput-chek-radio .input-group-addon:first-child {
  border-right: 1px solid #ccc;
}

/*========================
  Tabla para indicadores
==========================*/
.panel-kpi .panel-body {
  padding: 10px;
}
.panel-kpi .versalita {
  margin: 0;
  font-size: 0.9em;
  color: #777;
  letter-spacing: 2px;
}
.list-kpi {
  list-style-type: none;
  padding-left: 15px;
}
.list-kpi li {
  padding: 7px 0;
}
.list-kpi li a {
  color: #ff5722;
}
.table-kpi {
  vertical-align: middle;
  color: #444;
}
.table-kpi td {
  /*border-right: 1px solid #e8e8e8;*/
}
.table-kpi td:last-child {
  border-right: none;
}
.table-kpi .big {
  font-size: 2.6em;
  font-weight: bold;
  margin: 10px 0;
}
/*Fuente reducida a un 50% con respecto a la clase big*/
.table-kpi .medium {
  font-size: 1.3em;
  font-weight: bold;
  margin: 10px 0;
}
.table-kpi .lightgray {
  color: #666;
}
.table-kpi .big-filter {
  font-size: 2.6em;
  font-weight: bold;
  margin: 3px 0;
}
.table-kpi .legend {
  float: left;
  font-size: 0.9em;
  padding-top: 7px;
}
.table-kpi .btn-rounded {
  margin-top: 5%;
  border: 2px solid #0994ff;
  color: #0994ff;
  font-size: large;
}
.table-kpi .btn-rounded:hover {
  margin-top: 5%;
  background: #0994ff;
  border: 2px solid #0994ff;
  color: #ffffff;
}
.table-kpi .btn-rounded-m0 {
  margin-top: 0px;
}
.wrap-kpi {
  padding-right: 50px;
  padding-left: 15px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.table-kpi td:hover .wrap-kpi {
  color: #000;
}
.superbig {
  font-size: 70px;
  margin: 0;
}

/*antiguo css luego de aprobar eliminar*/
.border-lesft-default {
  border-left: 5px solid #ddd !important;
}
.border-lesft-danger {
  border-left: 5px solid #d9534f !important;
}
.border-lesft-success {
  border-left: 5px solid #449d44 !important;
}
.border-lesft-warning {
  border-left: 5px solid #d89e0e !important;
}

/*Revisar este issue @patricio.rodriguez*/
/*.tab-content {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}*/

.nav-tabs {
  margin-bottom: 0;
}

/*=====================
  Filtros nuevos en collapse dentro de la tabla
=======================*/
.table-compressed .single-line {
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.single-line {
  max-width: none;
}
.navbar-primary.navbar-primary-filters {
  top: 0px;
  transition: none;
  overflow: visible;
  left: 0px;
  border-right: none;
  margin-bottom: 30px;
  padding: 0px;
  float: left;
  width: 285px;
  position: relative;
}
.with-nav-tabs .navbar-primary.navbar-primary-filters {
  top: 0px;
}
.navbar-primary.navbar-primary-filters .title-filters {
  padding: 5px 0px 0px 15px;
}
.navbar-primary.navbar-primary-filters .title-filters h2 {
  margin: 0;
  font-size: 18px;
  padding: 0;
  font-weight: bold;
}
.navbar-primary.navbar-primary-filters.collapsed {
  display: none;
}
.navbar-primary.navbar-primary-filters .navbar-primary-menu {
  padding: 15px 0px 15px 15px;
}
.navbar-primary.navbar-primary-filters .content-collapse-side {
  padding-right: 0;
  font-size: 11px;
}
/*******
  Detalle con el panel de la izquierda collapsable
*******/
.panel-width-collapse-side {
  margin: 0;
  min-height: 660px;
}
.panel-width-collapse-side-orden {
  margin: 0;
  /*min-height: 850px;*/
  min-height: 650px;
  /*height: 100vh;*/
}
.panel-width-collapse-side-in-table {
  margin: 0 0 30px 0;
  /*min-height: 650px;*/
}
.navbar-primary {
  bottom: 0px;
  left: 15px;
  position: absolute;
  top: 0px;
  width: 300px;
  z-index: 8;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /*border-right: 1px solid #ddd;*/
  padding: 15px;
  margin-left: -1px;
  margin-left: -1px;
}

.navbar-primary ul.datos {
  padding-bottom: 0;
}
.navbar-primary ul.datos li {
  padding: 2px 0;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}
.navbar-primary .label {
  font-size: 12px;
}
.content-collapse-side {
  padding-right: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.content-collapse-side h3.versalita {
  margin: 25px 0 5px 0;
}
.navbar-primary.collapsed {
  width: 31px;
}
.navbar-primary.collapsed .glyphicon {
  font-size: 22px;
}
.navbar-primary.collapsed .nav-label {
  display: none;
}
.btn-expand-collapse {
  position: absolute;
  display: block;
  top: 0;
  z-index: 8;
  right: 0px;
  bottom: 0;
  padding: 3px 0;
  border-top: none;
  border-bottom: 2px solid #ddd;
  border-left: 2px solid #ff5722;
  border-right: 2px solid #f8eae7;
  color: #ff5722;
  font-size: 16px;
  text-align: center;
  width: 30px;
  background: #fff;
  text-decoration: none;
  margin-right: -1px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 100%;
}
.btn-expand-collapse .fa {
  /*position: fixed;*/
  top: 150px;
  -webkit-transition: -webkit-transform 0.45s ease;
  transition: transform 0.45s ease;
}
.collapsed .btn-expand-collapse .fa {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.45s ease;
  transition: transform 0.45s ease;
}

.collapsed .btn-expand-collapse {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
  background: #fff !important;
  color: #ff5722;
  text-decoration: none;
}
.btn-expand-collapse:active {
  background-color: #111;
}
.navbar-primary-menu,
.navbar-primary-menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.collapsed .navbar-primary-menu {
  display: none;
}

/*================
Nuevo estructura de Pagina Detalle de Documentos
==================*/
.detalles {
  display: flex;
  align-items: stretch;
}
/*cabecera*/
.detalle-slidebar {
  min-width: 270px;
  max-width: 270px;
  position: fixed;
  background: #fff;
  border-right: 1px solid #ddd;
  transition: all 0.3s;
  bottom: 0;
  z-index: 1;
  padding: 0px 0px 30px 0px;
  /*height: 100vh;*/
  overflow-y: auto;
  height: calc(100vh - 115px);
  height: -moz-calc(100vh - 115px);
  height: -webkit-calc(100vh - 115px);
  -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.detalle-slidebar .slidebar-element {
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
}
.detalle-slidebar .slidebar-element h2 {
  font-size: 16px;
  margin: 10px 0px;
}
.detalle-slidebar .slidebar-element h2.emp {
  font-size: 16px;
  margin: 0px 0px 10px;
  font-weight: bold;
  line-height: 1.2em;
}
.detalle-slidebar .slidebar-element h2.emp span {
  color: #999;
  font-size: 14px;
  display: block;
  font-weight: normal;
}
.detalle-slidebar .slidebar-element h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 11px;
  margin: 0px 0px 10px;
  color: #888;
}
.detalle-slidebar .edit-cabecera {
  margin-top: 10px;
}
.detalle-slidebar .edit-cabecera span.mostrar {
  float: right;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.detalle-slidebar ul.datos {
  padding-bottom: 0;
}
.detalle-slidebar ul.datos li {
  padding: 2px 0;
  font-size: 12px;
  margin-bottom: 1px;
}
/*===================
  botones de acción y nuevo calc en modal
=====================*/
.modal.right .wrap-btns {
  padding-top: 5px;
}
.modal-body-column .detalle-slidebar {
  height: calc(100vh - 65px);
  height: -moz-calc(100vh - 65px);
  height: -webkit-calc(100vh - 65px);
  padding-top: 30px;
  min-width: 250px;
  max-width: 250px;
}
.modal-body-column .detalle-slidebar.less {
  padding-top: 0px;
}
.modal-body-column .detalle-contenido {
  padding-top: 108px;
  width: calc(100% - 250px);
  width: -moz-calc(100% - 250px);
  width: -webkit-calc(100% - 250px);
}
.modal-body-column .detalle-footer {
  width: calc(100% - 250px);
  width: -moz-calc(100% - 250px);
  width: -webkit-calc(100% - 250px);
  position: fixed;
}
/*detalle-contenido Elemento a al derecha */
.detalle-contenido {
  padding: 25px 40px 100px;
  width: calc(100% - 270px);
  width: -moz-calc(100% - 270px);
  width: -webkit-calc(100% - 270px);
  right: 0;
  position: absolute;
  min-height: 100vh;
  transition: all 0.3s;
  background: #bbb;
}
/*nuevo tabs tipo material Design*/
.tabs-detalle .nav-tabs {
  border-bottom: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 100%;
  background-color: #f5f5f5;
  padding: 5px 0px 0px 15px;
  border-radius: 3px 3px 0px 0px;
}
.tabs-detalle .nav-tabs > li.active > a,
.tabs-detalle .nav-tabs > li.active > a:focus,
.tabs-detalle .nav-tabs > li.active > a:hover {
  border-width: 0;
}
.tabs-detalle .nav-tabs > li > a {
  border: none;
  color: #999;
  font-weight: bold;
  /*background: #eee;*/
  background: transparent;
  margin-bottom: 1px;
  border-bottom: none !important;
}
.tabs-detalle .nav-tabs > li.active > a {
  border: none;
  color: #ff5722;
  background: transparent;
}

.tabs-detalle .nav-tabs > li > a:hover {
  border: none;
  color: #ff8a65;
  background: transparent;
}
.tabs-detalle .nav-tabs > li > a::after {
  content: "";
  background: #ff5722;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -2px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.tabs-detalle .nav-tabs > li > a:hover::after {
  background: #ff8a65;
}
.tabs-detalle .nav-tabs > li.active > a::after,
.tabs-detalle .nav-tabs > li:hover > a::after {
  transform: scale(1);
}
.detalle-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #ddd;
  width: 100%;
  /*width: calc(100% - 250px);*/
  right: 0;
  position: absolute;
  bottom: 0;
  margin-top: 30px;
}
.detalle-footer p {
  margin-bottom: 0px;
  line-height: 2.2em;
}

/*================
END Nuevo estructura de Pagina Detalle de Documentos
==================*/
/*******
  Cotizaciones > Oferta
*******/
.td-code {
  position: relative;
  min-width: 90px;
}
.btn-add-code {
  visibility: hidden;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.table-offer tr:hover .btn-add-code {
  visibility: visible;
}
.table-offer td.td-code {
  padding-bottom: 40px;
}
.main-content {
  margin-left: 300px;
}
.main-content.main-content-filters .main-content-inner {
  border-left: 1px solid #ddd;
  margin-top: -1px;
  margin-left: -1px;
  padding: 0;
}
.main-content-inner {
  padding: 15px;
}
.collapsed + .main-content {
  margin-left: 30px;
}
.collapsed + .main-content.main-content-filters {
  margin-left: 0px;
}

/***************** Estilos para resumen con botones en la parte superior con posicion fixed *************************/

/*============================
position hacks
==============================*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.position-bottom {
  position: fixed;
  bottom: 0;
}
.position-top {
  position: fixed;
  top: 0;
}

/*============================
Background
==============================*/
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-grey {
  background-color: #f9f9f9;
}
.bg-grey2 {
  background-color: #f5f5f5;
}
.bg-dark {
  background-color: #666;
}
/*============================
Shadows
==============================*/
.shadow-top-01 {
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
}
.shadow-top-05 {
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 5);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 5);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 5);
}
.shadow-top-08 {
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
}
.shadow-bottom-01 {
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.shadow-bottom-05 {
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}
.shadow-bottom-08 {
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 08);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 08);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 08);
}

/*===================
MODAL LEFT/RIGHT SIDEBAR
===================*/
.modal.left > .modal-dialog,
.modal.right > .modal-dialog {
  position: fixed;
  margin: auto;
  width: 38%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.left > .modal-content,
.modal.right > .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none !important;
}
.modal.left > .modal-body,
.modal.right > .modal-body {
  padding: 15px 18px 80px;
}
/* Modal slidebar Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.in .modal-dialog {
  left: 0;
}
/* Modal slidebar Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.in .modal-dialog {
  right: 0;
}

/*-------content modal right-------*/
.modal-content-right {
  border-left: 1px solid #ddd;
  padding-top: 15px;
  padding-right: 30px;
}
.modal-detalle .wrap-messages {
  border-left: 0;
  min-height: 100%;
  padding-bottom: 15px;
  margin-top: -15px;
}
.modal-detalle .container-message {
  margin: 5px 0 5px 13px;
  position: relative;
}
.modal-detalle .wrap-messages .container-message,
.modal-detalle .wrap-messages .panel,
.modal-detalle .wrap-messages .alert {
  margin-bottom: 0px;
}
.modal-detalle p.time {
  margin-top: 30px;
}
/*-------Mensajes dentro del modal-------*/
.modal-detalle .wrap-all-messages {
  padding: 20px;
  margin-top: 20px;
  max-height: 430px;
  min-height: 430px;
  overflow-y: scroll;
  margin-right: 1px;
}
.modal-detalle .wrap-messages .message {
  border-left: 3px solid #f3f3f3;
  padding-bottom: 20px;
}
.modal-detalle .wrap-messages .message.last {
  border-left: 3px solid #fff;
}
.nav-tabs.nav-justified {
  padding-left: 0;
}
.modal-detalle .tab-content {
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ddd;
  max-height: 70vh;
  min-height: 70vh;
}
.modal-detalle .tab-content .tab-content {
  border: none;
  padding: 0px;
  max-height: 65vh;
  min-height: 65vh;
}
.modal-detalle .doc-modal-box {
  max-height: 65vh;
  min-height: 65vh;
  overflow-y: scroll;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0px;
  margin-left: -1px;
  /*background: #F5F5F5;*/
}
.nav-tabs.nav-justified > li:first-child > a {
  margin-left: 0px;
}
.nav-tabs.nav-justified > .active > a,
.modal-detalle .nav-tabs.nav-justified > .active > a:hover,
.modal-detalle .nav-tabs.nav-justified > .active > a:focus {
  border-bottom-color: #fff;
  background-color: #fff;
}
.modal-detalle span.box-time {
  height: 15px;
  width: 15px;
  margin-left: -9px;
}
.modal-detalle .container-message {
  background: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
}
.modal-detalle .container-message .label {
  border-radius: 999px;
  padding: 3px 10px;
}
.modal-detalle .container-message p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.modal-detalle .h500 {
  height: 500px;
}
.modal-obs {
  background: #eeeeee;
  padding: 20px 20px 10px 20px;
}
/*detalle del historial*/
.historial-detalle .wrap-all-messages {
  padding: 20px;
}
.historial-detalle .panel-body {
  padding: 10px 10px 0 10px;
  background-color: #f3f3f3;
}
.historial-detalle .container-message .label {
  border-radius: 999px;
  padding: 3px 10px;
}

/*Borde redondeado para los input con calendario asociado de un input-group*/
/*.input-group .form-control:last-child{
border-top-right-radius: 3px;
border-bottom-right-radius: 3px;
}*/

/*Borde redondeado para los input-group con calendario asociado de un input-group*/
.input-sm.last {
  border-radius: 0 3px 3px 0 !important;
}

.input-md.last {
  border-radius: 0 3px 3px 0 !important;
}

/*.form-control{
	padding: 6px 20px;
}*/

/*==============================
  Boton de aprobado y objetado
================================*/
.btn-default.btn-on.active {
  background-color: #5bb75b;
  color: white;
}

.btn-default.btn-off.active {
  background-color: #da4f49;
  color: white;
}

.textarea-type-input {
  resize: none;
  overflow: auto;
}
/*.push-indicadores {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	-webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);
	box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);
	right: 1%;
	bottom: 15%;
	padding: 10px;
	background: #FF5722;
	color: #ffffff;
	border: 1px solid #FFB098;
}
.push-indicadores:hover {
	background: #fa3c00;
	color: #ffffff;
	border: 1px solid #77C477;
}
#offcanvas-right-indicadores {
    margin-right: -380px;
    right: 0px;
    width: 380px;
    position: fixed;
    height: 100%;
    z-index: 1000;
    color: #666;
    height: 380px;
    top: 25%;
}*/
.notify-body {
  margin-left: 44px;
  /*padding-right: 10px;*/
  height: 100%;
  width: 100%;
  position: absolute;
  /*top:70%;*/
  background: #ebebeb;
  /*min-height: 380px;*/

  /*overflow: visible;
	overflow-y: auto;*/
}
.notify-content {
  /*margin-left: 44px;
	padding-right: 10px;*/
  height: 100%;
  width: 100%;
  /*position: absolute;
	top:70%;*/
  background: #ebebeb;
  /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	overflow: visible;*/
  overflow-y: auto;
}
.notify-header {
  background: #00bcd4;
  color: #ffffff;
  padding: 30px 15px 15px;
  font-weight: 400;
}
.notify-footer {
  padding: 10px 0;
  font-weight: 400;
  background: #ebebeb;
  border: 1px solid #ddd;
}
.badge-up {
  margin-left: -8px;
  padding: 5px 7px;
  position: absolute;
  top: -10px !important;
  font-size: 12px;
  background: #ffffff;
  color: #ff5722;
}

/*==============================
  Solicitud de Fondos
================================*/
.historial-destacado-content {
  background: #f1f5f9;
}

.historial-destacado-heading.panel-heading {
  background: #d5efff;
}

.historial-destacado-font {
  color: #0072c5;
}

.long-text {
  text-align: justify;
  line-height: 1.6;
}

/*==============================
  Configurar Documentos
================================*/
#campos-requeridos th.centrado {
  text-align: center;
}

/*=======================
@media print
=========================*/
@media print {
  .no-print {
    display: none;
  }

  .no-offset-print {
    width: 100%;
    margin-left: 0%;
  }
  .print-bock {
    width: 100%;
  }
  .block-right {
    padding-left: 15px;
  }

  a[href]:after {
    content: none !important;
  }

  .navbar-fixed-top {
    display: none;
  }

  .page-header,
  .page-header-fixed {
    border-bottom: none;
  }

  .w-noprint {
    width: 15%;
  }

  .nprint-paginador {
    visibility: hidden;
  }

  .ancho-print {
    width: 33%;
    float: left;
    color: #000;
  }

  .ancho-print50 {
    width: 48%;
    float: left;
    color: #000;
  }

  .m-noprint {
    margin-top: 0px;
  }

  .f18-noprint {
    font-size: 18px;
  }

  #footer {
    border-top: none;
  }

  .f10-noprint {
    font-size: 10px;
  }

  .f11-noprint {
    font-size: 11px;
  }

  /***** Crear Usuarios ****/
  .space-left {
    padding-left: 4px;
  }

  /***** Fondos Contables ****/
  .space-left2 {
    padding-right: 2px;
  }
}

/*=======================
	Menú items en el costado izquierdo
=========================*/
.menu-items-left h4 {
  padding: 0 10px;
  font-size: 14px;
}
.menu-items-left ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.menu-items-left ul li {
  margin-top: 2px;
}
.menu-items-left ul li a {
  border-right: 2px solid #fff;
  padding: 10px;
  display: block;
  text-decoration: none;
}
.menu-items-left ul li a:hover {
  background: #f5f5f5;
  border-right: 2px solid #0072c5;
}
.menu-items-left ul li.active a {
  border-right: 2px solid #0072c5;
  background: #f1f5f9;
}
table.active-first {
  border: none;
  background: #f1f5f9;
  color: #0072c5;
  border-bottom: 1px solid #eee;
}
table.active-first td.icon-right {
  border: none;
  background: #0072c5;
  color: #f1f5f9;
  border-bottom: 1px solid #eee;
}
table.active-first-off {
  border: none;
  background: #f5f5f5;
  color: #b0b0b0;
  border-bottom: 1px solid #eee;
}
a:hover.active-first-off {
  text-decoration: none;
}
/*=======================
	Sliding Mis Gastos
=========================*/
.bootsidemenu {
  z-index: 98;
}
#mis-gastos {
  height: 420px;
  width: 307px !important;
}
.bootsidemenu > .menu-wrapper {
  /*overflow: hidden !important;*/
}
.default a,
.default a:hover,
.default a:visited {
  color: #0072c5;
}
.bootsidemenu-right > .toggler {
  left: -140px;
  width: 123px;
  height: auto;
  padding: 7px;
  border-radius: 999px;
  border: none;
  background: #5cb85c;
  color: #fff;
  top: 0;
}
.bootsidemenu-right > .toggler > span {
  float: left;
  margin: 2px 7px 0 7px;
}
.bootsidemenu-right > .toggler > p {
  margin-top: 0;
  margin-bottom: 0;
}
/*Contenedor total de los gastos*/
.bootsidemenu {
  top: 37%;
  bottom: 30px;
  border-radius: 5px;
}
.bootsidemenu.bootsidemenu-right {
  /*right: 20px;*/
  margin-right: 20px;
  border-color: #ddd;
}
/*Contenedor para lo que va adentro del sliding*/
.wrapper-sliding {
  margin-top: 40px;
  padding: 20px 20px 20px 20px;
  /*overflow: visible;*/
  overflow-y: auto;
  height: 380px;
}
.header-sliding #fix {
  border-bottom: 1px solid #eee;
  background: #fff;
  border-radius: 5px 5px 0 0;
  position: fixed;
  width: 305px;
  z-index: 999;
  padding: 10px 20px;
}
.receipt-img {
  background: #f1f1f1;
  background-size: cover;
  height: 100px;
  background-position: center center;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  border-radius: 1px 1px 0 0;
}
.receipt-text {
  padding: 2px;
  height: 55px;
}
.receipt-text p {
  font-size: 12px;
  margin-bottom: 0;
}
.receipt-text p.small {
  font-size: 10px;
  font-weight: 400;
}

.slider {
  width: 100%;
}

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.slick-active {
  opacity: 0.5;
}

.slick-current {
  opacity: 1;
}
/*=======================
	Media queries
=========================*/
@media only screen and (max-width: 480px) {
  .bootsidemenu {
    min-width: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .bootsidemenu {
    min-width: 0 !important;
  }
}
.receipt-block label {
  margin: 0;
  border: 1px solid #eee;
  border-radius: 2px;
  margin-bottom: 20px;
  transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
}
.receipt-block label:hover {
  border-color: #999;
}
.receipt-block input[type="checkbox"]:empty {
  display: none;
}
.receipt-block input[type="checkbox"]:checked ~ label {
  color: #000;
  border: 1px solid #0072c5;
  box-shadow: 0 0 0 5px #dbe5f0;
}
.receipt-block label a.receipt-delete {
  visibility: hidden;
  position: absolute;
  bottom: 25px;
  /*right: 20px;*/
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  background: #fff;
  color: #ccc;
  border-radius: 999px;
  font-size: 12px;
  padding: 3px;
  padding-left: 4px;
  border: 1px solid #eee;
  line-height: 0;
  margin: 0;
}
.receipt-block label a.receipt-delete:hover {
  color: #be3535;
}
.receipt-block label:hover a.receipt-delete {
  visibility: visible;
}

/*Drag&drop Container*/
.drag-drop-container {
  border: 2px dashed #ced4dc;
  border-radius: 2px;
  padding: 50px 20px;
  cursor: pointer;
}
.drag-drop-container:hover,
.panel .uploader:hover {
  border: 2px dashed #0072c5;
  text-decoration: none;
}
.panel .uploader p,
.panel .uploader i {
  color: black !important;
}
.panel .uploader a {
  text-decoration: none;
}
.drag-drop-container p {
  margin: 0;
}
/*Upload image Container*/
.add-image-container {
  background: #fff;
  background-size: cover;
  background-position: center center;
  border: 1px solid #eee;
  height: 170px;
  border-radius: 2px;
  position: relative;
  padding: 30px;
}
.add-image-container .btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
}

.add-image-container2 {
  background: #fff;
  /*background-size: cover;
	background-position: center center;
	height: 170px;
	border-radius:2px;*/
  border: 1px solid #eee;
  position: relative;
  padding: 10px;
}
.ui-widget-message {
  font-size: 12px;
  padding: 5px 10px;
}
.add-image-container2 .btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
}
.icon-opacity {
  opacity: 0.1;
  display: block;
  text-align: center;
}
.upload-ok {
  /* background-image: url('../img/voucher.jpg'); */
}
.add-image-container .btn-cambiar {
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 100px;
  width: 40%;
  border-radius: 999px;
}
/*Dashboard Rendición de Gastos*/
.panel-init h3 {
  margin: 0 0 5px 0;
}
.panel-init ul.nav-pills li:first-child a {
  border-radius: 0 0 0 2px;
}
.panel-init ul.nav-pills li:last-child a {
  border-radius: 0 0 2px 0;
}
.panel-init ul.nav-pills li a {
  border-radius: 0;
  padding: 20px 5px;
  background: #fff;
  border-bottom: 2px solid #fff;
}
.panel-init ul.nav-pills li.active a {
  background: #fff !important;
  color: #0072c5 !important;
  border-bottom: 2px solid #0072c5;
}
.panel-init ul.nav-pills li.active a:hover {
  color: #0072c5 !important;
}
.notification-circle.red {
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  line-height: 15px;
  font-size: 10px;
  color: #fff;
  background: #d9534f;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}
/*Reset Alert Bootstrap Notify*/
.alert-notification {
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 25px;
}
.alert-notification h2 {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}

/*Alerta de linea para gastos incompletos en rendicion*/
.alert-gastos {
  margin-bottom: 0;
  font-size: 11px;
  padding: 4px 15px;
  border-radius: 0px 0px 2px 2px;
  border-left: none;
  border-right: none;
  border-bottom: none;
  text-align: center;
}

.alert-gastos-expandida {
  margin-bottom: 0;
  font-size: 11px;
  padding: 4px 15px;
  border-radius: 0px 0px 2px 2px;
  border-left: none;
  border-right: none;
  /*border-bottom: none;*/
  border-top: none;
  text-align: center;
}

/*Traer al frente elemento de botones fixed*/
.elemento-front {
  z-index: 1000;
}

/*Tabla con todas las columnas del mismo tamaño*/
.col-size-igual {
  table-layout: fixed;
}

/*===========================================
	Tabla de "Consulta realizada al SII"
=============================================*/
table#consulta-sii.borde-consulta-sii {
  border: 1px solid #5cb85c;
}

.img-consulta-sii {
  color: #5cb85c;
  vertical-align: middle;
  padding: 0px 20px;
}

.texto-consulta-sii {
  font-size: 24px;
  font-weight: bold;
  color: #5cb85c;
}

.img-label-ces-ges {
  padding: 0px 10px 0px 5px;
}

/*===========================================
	Panel activo para las rendiciones
=============================================*/
.panel-active {
  border-right: 2px solid #0072c5;
}

.test-color {
  font-weight: bold;
}

.test-color > span.ACIERTO {
  color: rgba(255, 87, 34, 1);
}

.btn-filtros-display {
  display: block;
}

@media (min-width: 768px) {
  .td-display-col {
    visibility: visible;
    display: table-cell;
  }

  .id-class-fapertura span {
    display: block;
  }
  .id-class-fapertura::after {
    content: "";
  }

  .id-class-fcierre span {
    display: block;
  }
  .id-class-fcierre::after {
    content: "";
  }

  .btn-filtros-display {
    display: none;
  }
  .btn-filtros-display.btn {
    display: none;
  }
}

.monitorContainer {
  margin-top: 2rem;
}

.lstEtapa > a {
  color: #0994ff !important;
}

.lstEtapa > a:hover {
  color: #0994ff !important;
}

.lstEtapa > a > i {
  font-size: large;
  vertical-align: center;
}

.lstEtapa > a::after {
  content: "\f054";
  font-family: FontAwesome;
  font-size: large;
  float: right;
}

.panel-kpi > div.table-kpi {
  border: 1px solid #dddddd;
}

#lstRegionCatalogo > div.bootstrap-select > div.dropdown-menu {
  right: 0;
  left: auto;
}

@media (max-width: 768px) {
  #lstRegionCatalogo > div.bootstrap-select > div.dropdown-menu {
    right: auto;
    margin-left: -5rem;
    min-width: 85px;
    max-width: 32rem;
  }

  .contCol3{
    margin-top: 3rem;
  }
}

.panel-obras > .panel-heading {
  background-color: #ffffff !important;
}

.panel-obras > .panel-footer {
  background-color: #ffffff;
  padding: 1rem;
}

.btnExcel {
  color: #0994ff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.btnExcel:hover {
  color: #0994ff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.id-class-header-table {
  background: #ffffff !important;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  height: 40px;
  color: #333333;
}

.id-class-header-table > div{
  padding-top: 5px;
}

div.monitorContainer div.panel{
  margin-bottom: 1rem !important;
}

div.monitorContainer div.panel-bg{
  height: 13rem;
  min-height: 13rem;
}

.monitorContainer .btn-primary {
  border-radius: 8px;
}

