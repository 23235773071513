/*
Theme Name: Cimientos iconstruye
Theme URI: http://iconstruye.com/
Description: iconstruye.com
Version: 3.0
Author: Francisco Calleja Pollmann
Author URI: http://iconstruye.com/
*/
/*====================================================
PROPUESTA DE ORGANIZACIÓN DE ESTILOS CSS iC
====================================================*/

/* INDICE */

body.ic{
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}
body{
  font-family: 'Roboto', sans-serif;
  background: #f9f9f9;
}
.dark{
  background: #666;
}
dd{
  margin-bottom: 5px;
}
.marco-operacional{
  padding-top: 110px;
  padding-bottom: 80px
}
/* nuevo menu colapsable 2 */
.navmenu, .navbar-offcanvas {
    width: 270px;
}
#menu {
  list-style: none;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
}
/* primer nivel*/
#menu li.title{
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0px 15px 0 10px;
  color: #777;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-weight: bold;
  border-top: 1px solid #e8e8e8;
  line-height: 0;
}
#menu li.title span{
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
}
#menu li a.active{
  color: #000 !important;
  border-right: 3px solid #FF5722;
  font-weight: 700;
}
#menu li a.first{
  padding: 10px 15px;
  /*color: #8c9eb5;
  color: #FF6E40;*/
  color: #616161;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}
#menu li a.first:hover, #menu li.active a.first {
  /*color: #0f4e7a;
  color: #FF3D00;*/
  color: #212121;
}
#menu li a.first i.arrow{
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
#menu li.active a.first i.arrow{
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}

/* label en menú*/
#menu .label{
  vertical-align: middle;
  font-size: 10px;
  background: #FF5722;
  color: #fff;
  border:none;
  margin-left: 5px;
}
.label.label-orange{
  vertical-align: middle;
  font-size: 10px;
  background: #FF5722;
  color: #fff;
  border:none;
}
/* segundo nivel*/
#menu li ul {
  list-style: none;
  padding: 0px 0px 0px 0px;
  box-shadow: inset 0 2px 6px rgba(0,0,0,.05);
  border-bottom: 1px solid #c9d3dd;
  background: #f8f8f8;
}
#menu li ul li a{
  padding: 8px 15px 8px 35px;
  display: block;
  /*color: #8c9eb5;*/
  color: #616161;
  font-size: 12px;
  text-decoration: none;
}
#menu li ul li:first-child >a{
  padding: 16px 15px 8px 35px;
}
#menu li ul li:last-child >a{
  padding: 8px 15px 16px 35px;
}
#menu li ul li a.level2:hover, #menu li ul li.active a.level2 {
  /*color: #0f4e7a;*/
  color: #212121;
}
#menu li ul li a.star{
  position: absolute;
  padding-left: 15px;
}
#menu li ul li a.star:hover{
  color:#FF5722 !important;
}
#menu li ul li a i.arrow{
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
#menu li ul li.active a i.arrow{
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
/* tercer nivel*/
#menu li ul li ul{
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 30px;
  background: #f0f0f0;
  box-shadow: inset 0 2px 6px rgba(0,0,0,.05);
  border-bottom: none;
}
#menu li ul li ul li a:hover{
  /*color: #0f4e7a;*/
  color: #212121;
}
/*legal*/
#MainMenu .legal {
  border-top: 1px solid #e0e0e0;
  bottom: 0;
  box-sizing: border-box;
  color: #212121;
  font-size: 13px;
  height: 96px;
  line-height: 24px;
  padding: 24px;
  position: absolute;
  width: 100%;
  background: #fff;
}
#MainMenu .legal ul, #MainMenu .legal ul li a{
  padding: 0px;
  font-weight: normal;
}
#MainMenu p.copyright{
  margin-bottom: 0px;
}

/*menú alternativo*/
#menu li a.primero {
  padding: 15px;
  color: #616161;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border-bottom: 1px solid #E0E0E0;
  border-top: 1px solid #E0E0E0;
}
#menu .segundo {
  padding: 10px;
  background: #f8f8f8;
}
#menu .segundo a {
  color: #212121;
  font-weight: 300;
  padding-left: 10px;
}
#menu .segundo a:hover {
  text-decoration: none;
  color: #0079C1;
}
/*Header*/
.ic .navbar-default{
    background: #FF5722;
}
.ic .navbar-default .navbar-nav>li>a:hover, .ic .navbar-default .navbar-nav>li>a:fas{
    background:#E64A19 !important;
    color: #fff;
}
.ic .navbar-default .nav .open>a, .ic .navbar-default  .nav .open>a:hover, .ic .navbar-default .nav .open>a:focus{
    background:#E64A19;
}
.ic .navbar-default li a:hover, .ic .navbar-default li a:focus, .ic .navbar-default li.active a {
    background: #E64A19  !important;
}
.ic .navbar-form input{
  width: 100% !important;
}
/*.ic .navmenu .logo{
  border-bottom:1px solid #e0e0e0;
}*/
.ic .navmenu .logo img{
  margin: 18px auto 28px auto;
  max-width: 180px;
}
.ic .navmenu .contexto-select{
  margin: 10px 15px;
}
.ic .navmenu .contexto-select .dropdown-menu>li>a {
    outline: none;
    padding: 3px 10px;
    color: #333;
    font-weight: normal;
}
.ic .navbar-nav > li {
  font-size: 13px;
}
.ic .navbar-nav > li > a {
    padding: 10px 15px;
    margin-right: 0px;
}
.ic .navbar-nav li.button-menu a{
  margin-left: -10px
}
.ic .navbar-nav > li .navbar-form{
    display: inline-table;
}
.ic .navbar-nav > li .navbar-form input.form-control{
    background: #FBE9E7 !important;
    border-color: #F4511E;
    border-right: none;
    border-radius: 2px 0px 0px 2px;
}
.ic .navbar-nav > li .navbar-form .btn{
    border-top:1px solid #F4511E;
    border-bottom:1px solid #F4511E;
    border-right:1px solid #F4511E;
    border-left: 1px solid #F4511E;
    background: #FF7043;
    color: #fff;
    margin-left: 0;
}
.ic .navbar-nav > li .navbar-form .btn:hover{
  border-left: 1px solid #F4511E;
}
/*====================
Header navbar para implementaciones en iframe de IC OLD
======================*/
.ic-old-bar{
  /*border-bottom: 1px solid #ddd;
  background: #f9f9f9;
  min-height: 30px;*/
  background: #ff5722;
  min-height: 30px;
}
.ic-old-bar a{
  color:#666;
  line-height: 29px;
  padding: 8px 8px;
  cursor: pointer;
}
.ic-old-bar a:hover{
  text-decoration: none;
  background: #E64A19 ;
}
/*====================
Header Market place
======================*/
.ic .navbar-default.navbar-mm{
    background: #29b6f6;
}
.ic .navbar-default.navbar-mm .navbar-nav>li>a:hover, .ic .navbar-default.navbar-mm .navbar-nav>li>a:focus{
    background:#039be5!important;
    color: #fff;
}
.ic .navbar-default.navbar-mm .nav .open>a, .ic .navbar-default.navbar-mm  .nav .open>a:hover, .ic .navbar-default.navbar-mm .nav .open>a:focus{
    background:#E64A19;
}
.ic .navbar-default.navbar-mm li a:hover, .ic .navbar-default.navbar-mm li a:focus, .ic .navbar-default.navbar-mm li.active a {
    background: #E64A19  !important;
}

.ic .navbar-nav > li .navbar-form input.form-control{
    background: #FBE9E7 !important;
    border-color: #F4511E;
    border-right: none;
    border-radius: 2px 0px 0px 2px;
}
/* -- Gráficos dashboard market maker -- */
.resumen-usuarios .highcharts-container {
  border: none;
}
/*=================*/
.ic .highcharts-{
  border: none;
}
.ic .page-header{
  margin-top: 40px;
  padding: 10px 0 5px 0;
}
.ic .page-header h2{
  font-size: 21px
}
.ic .page-header .badge{
  position: absolute;
  top: 3px;
  margin-left: 10px;
}
.page-header-fixed{
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.ic .navmenu ul{
    padding-top: 20px;
}
.badge.up {
    margin-left: -6px;
    padding: 5px 7px;
    position: absolute;
    top: 2px;
    font-size: 10px;
    background-color: #3F51B5;
}
/*============================
textos hacks
==============================*/
.f35{font-size: 35px !important;}
.f24{font-size: 24px !important;}
.f18{font-size: 18px !important;}
.f16{font-size: 16px !important;}
.f14{font-size: 14px !important;}
.f12{font-size: 12px !important;}
.f10{font-size: 10px !important;}
.f9{font-size: 9px !important;}

.text-orange, a.text-orange{color: #ff5722;}
a.text-orange:hover{opacity: .8;}

.text-normal{  font-weight: normal;}

.line-height-normal{line-height: normal;}

/*// Transformation*/
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }
/*// decoration*/
.line-through {text-decoration: line-through;}
/*============================
margin´s
==============================*/
.m0top{
  margin-top: 0px !important;
}
.m1top{
  margin-top: 1px !important;
}
.m2top{
  margin-top: 2px !important;
}
.m-2top{
  margin-top: -2px !important;
}
.m3top{
  margin-top: 3px !important;
}
.m4top{
  margin-top: 4px !important;
}
.m5top{
  margin-top: 5px !important;
}
.m10top{
  margin-top: 10px !important;
}
.m15top{
  margin-top: 15px !important;
}
.m30top{
  margin-top: 30px !important;
}
.m20top{
  margin-top: 20px !important;
}
.m35top{
  margin-top: 35px !important;
}
.m40top{
  margin-top: 40px !important;
}
.m50top{
  margin-top: 50px !important;
}
.m60top{
  margin-top: 60px !important;
}
.m70top{
  margin-top: 70px !important;
}
.m80top{
  margin-top: 80px !important;
}
.m125top{
  margin-top: 125px !important;
}
.m5left{
  margin-left: 5px !important;
}
.m10right{
  margin-right: 10px !important;
}
.m15right{
  margin-right: 15px !important;
}
.m20right{
  margin-right: 20px !important;
}
.m30right{
  margin-right: 30px !important;
}
.m10left{
  margin-left: 10px !important;
}
.m0bottom{ margin-bottom: 0px !important;}
.m10bottom{
  margin-bottom: 10px !important;
}
.m15bottom{
  margin-bottom: 15px !important;
}
.m20bottom{
  margin-bottom: 20px !important;
}
.m30bottom{
  margin-bottom: 30px !important;
}
.m40bottom{
  margin-bottom: 40px !important;
}
.m50bottom{
  margin-bottom: 50px !important;
}
.m60bottom{
  margin-bottom: 60px !important;
}
/*============================
borders
==============================*/
.b0bottom{
  border-bottom: 0 !important;
}
.b0top{
  border-top: 0 !important;
}
.b0left{
  border-left: 0 !important;
}
.b0right{
  border-right: 0 !important;
}
.b0none {
  border: none !important;
}
.b1top {
  border-top: 1px solid #ddd;
}
.b1top-lightgray{
  border-top: 1px solid #eee;
}
.b1left {
  border-left: 1px solid #ddd;
}
.b1right {
  border-right: 1px solid #ddd;
}
.b1bottom {
  border-bottom: 1px solid #ddd;
}
.b2 {border: 2px solid #666}
/*============================
paddings
==============================*/
.p0top{
  padding-top: 0 !important;
}
.p1top{
  padding-top: 1px !important;
}
.p10top{
  padding-top: 10px !important;
}
.p15top{
  padding-top: 15px !important;
}
.p2right{
  padding-right: 2px !important;
}
.p15right{
  padding-right: 15px !important;
}
.p30top{
  padding-top: 30px !important;
}
.p20top{
  padding-top: 20px !important;
}
.p35top{
  padding-top: 35px !important;
}
.p40top{
  padding-top: 40px !important;
}
.p50top{
  padding-top: 50px !important;
}
.p60top{
  padding-top: 60px !important;
}
.p70top{
  padding-top: 70px !important;
}
.p80top{
  padding-top: 80px !important;
}
.p115top{
  padding-top: 115px !important;
}
.p125top{
  padding-top: 125px !important;
}
.p0bottom{
  padding-bottom: 0 !important;
}
.p1bottom{
  padding-bottom: 1px !important;
}
.p10bottom{
  padding-bottom: 10px !important;
}
.p15bottom{
  padding-bottom: 15px !important;
}
.p30bottom{
  padding-bottom: 30px !important;
}
.p20bottom{
  padding-bottom: 20px !important;
}
.p35bottom{
  padding-bottom: 35px !important;
}
.p40bottom{
  padding-bottom: 40px !important;
}
.p50bottom{
  padding-bottom: 50px !important;
}
.p60bottom{
  padding-bottom: 60px !important;
}
.p70bottom{
  padding-bottom: 70px !important;
}
.p80bottom{
  padding-bottom: 80px !important;
}
.p2left{
  padding-left: 2px !important;
}
.p10left{
  padding-left: 10px !important;
}
.p15left{
  padding-left: 15px !important;
}
/*============================
position hacks
==============================*/
.relative{position: relative;}
.absolute{position: absolute;}
.fixed{position: fixed;}

.position-bottom {
  position: fixed;
  bottom: 0;
  z-index: 10;
}
.position-top {
  position: fixed;
  top: 0;
  z-index: 10;
}
/*============================
vertical align hacks
==============================*/
.valignmiddle{
  vertical-align: middle;
}

.valigntop{
  vertical-align: top;
}

.valignbottom{
  vertical-align: bottom;
}
/*============================
height - width hacks
==============================*/
.block{display: block !important;}
.w100{width: 100%}
.h100{height: 100%}

.vh10 {max-height: 10vh !important;}
.vh20 {max-height: 20vh !important;}
.vh30 {max-height: 30vh !important;}
.vh40 {max-height: 40vh !important;}
.vh50 {max-height: 50vh !important;}
.vh60 {max-height: 60vh !important;}
.vh70 {max-height: 70vh !important;}
.vh80 {max-height: 80vh !important;}
.vh90 {max-height: 90vh !important;}
.vh100 {max-height: 100vh !important;}

.alto20vh{
  max-height: 20vh;
  min-height: 20vh;
  overflow-y: auto;
}
.alto30vh{
  max-height: 30vh;
  overflow-y: auto;
}
.alto30vh-no-scroll{
  max-height: 30vh;
}
.alto40vh{
  max-height: 40vh !important;
  overflow-y: auto;
}
.alto50vh{
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: auto;
}
/*============================
Shadows
==============================*/
.shadow-top-01{
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.1);
}
.shadow-top-05{
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.5);
}
.shadow-top-08{
  -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
  box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.8);
}
.shadow-bottom-01{
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.shadow-bottom-05{
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}
.shadow-bottom-08{
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
}
/*============================
Progress hacks
==============================*/
.progress-md{
  height: 15px;
}
.progress-sm{
  height: 10px;
}
.progress-xs{
  height: 5px;
}
.progress-estimado{
  background-color: transparent !important;
  border-right: 2px solid #d89e0e;
  height: 13px;
}
.progress{
  border-radius: 999px;
  box-shadow: none;
  background-color: #eee;
}
.progress-bar{
  /*background-color: #FF5722;*/
  border-radius: 999px;
}
.progress-bar-rounded-left {
  -webkit-border-radius: 999px 0 0 999px;
  border-radius: 999px 0 0 999px;
}
.progress-bar-rounded-right {
  -webkit-border-radius: 0 999px 999px 0;
  border-radius: 0 999px 999px 0;
}
/*============================
datepicker hacks
==============================*/
.fecha-multiple .datepicker-inline, .fecha-multiple .datepicker-inline table{
  width: 100%;
}
.fecha-multiple .datepicker-inline table tr td.active.active {
  background: #ff5722 !important;
}
/*============================
<ul> listas hacks
==============================*/
.list-justified{
  width: 100%;
}
.list-justified>li {
  display: table-cell;
  width: 1%;
  float: none;
}
.list-inline>li:last-child{
  padding-right:0;
}
/*============================
list-group hacks
==============================*/
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
    color: #fff;
    background-color: #FF8A65;
    border-color: #FF8A65;
    cursor: pointer;
}
.list-group-item.active small.text-muted{
  color: #fff;
}
.list-group-link li{
  cursor: pointer;
  pointer-events: auto;
}
.panel>.list-group .list-group-item > .radio, .panel>.list-group .list-group-item > .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 10px;
}
.list-group.list-scroll-250{
  max-height: 250px;
  min-height: 250px;
  overflow-y: auto;
}

.list-group-item:hover{
    /*color: #333;*/
    color: #ff5722;
    background-color: #f5f5f5;
    cursor: pointer;
}

.list-group-item:hover small.text-muted{
  color: #ff5722;
  background-color: #f5f5f5;
  cursor: pointer;
}

/*.list-group-item a{
  color: #333;
}

.list-group-item a:hover{
  color: #333;
}

.list-group-item.active a, .list-group-item.active a:focus, .list-group-item.active a:hover {
    color: #fff;
    background-color: #FF8A65;
    border-color: #FF8A65;
    cursor: pointer;
}

.text-no-underline:link{
  text-decoration: none;
  color: #333;
}*/

/*.list-group-item:focus{
  color: #fff;
  background-color: #FF8A65 !important;
  border-color: #FF8A65;
  cursor: pointer;
}

.list-group-item:focus small.text-muted{
  color: #fff;
}*/

.icon-pre-ingreso {
     display: inline-block;
     font-size: 18px;
     text-align: center;
     color: #ff5722;
     padding: 3px;
     text-align: center;
     margin-top: 6px;
 }

.overlap-row span.mostrar {
visibility: hidden;
}
.overlap-row:hover span.mostrar {
  visibility: visible;
}

/*============================
input-group hacks
==============================*/
.input-group-inline {
    display: inline-table;
    vertical-align: middle;
}
.input-group-inline .input-group-addon, .input-group-inline .input-group-btn, .input-group-inline .form-control {
    width: auto !important;
}
.input-group-inline .bootstrap-select .btn{
    padding: 5px 25px 5px 10px;
}
.slect-button .btn:first-child{
  border-radius: 2px 0px 0px 2px;
}
.slect-button .btn:last-child{
   border-radius: 0px 2px 2px 0px;
   line-height: 1.6;
}
.form-control{
  box-shadow: none;
}
.form-control:focus {
  border: 1px solid #ff5722;
  /*box-shadow: 0px 1px 1px rgba(100, 34, 13, 0.075) inset, 0px 0px 8px rgba(100, 34, 13, 0.5);*/
  box-shadow: none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
  box-shadow: none;
  background: #f9f9f9;
  border-color: #e7e7e7;
  color: #999;
}
.radio-inline, .checkbox-inline{
  line-height: 2;
}
/*============================
Hacks elemento se seleccion de fechas y selectpicker
==============================*/
.range .btn-group.spFechas button.selectpicker {
    width: auto;
    padding-right: 25px;
}
.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 0;
    display: block;
}
.bootstrap-select.btn-group .dropdown-menu li>a {
    max-height: 40px;
}
.modal .input-group-xs>.form-control,
.input-group-xs>.input-group-addon, .modal
.input-group-xs>.input-group-btn>.btn {
  min-width: 60px;
}
/*==========================
hack selectpicker
============================*/
.has-error .bootstrap-select>.btn{
  border-color: #a94442;
}
/*============================
Row with equal height columns
==============================*/
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

/*============================
Reset Tabs en Mktplace
==============================*/
.ic .nav-tabs{
  padding-left: 0;
  border-bottom: 2px solid #e7e7e7;
}
.ic .nav-tabs>li>a{
  border-radius: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  margin-bottom: -1px;
  color: #333;
  border-bottom: 1px solid #e7e7e7 !important;
  font-weight: 700;
  padding: 10px 25px;
}
.ic .nav-tabs>li>a:hover{
  background: none;
}
.ic .nav-tabs li.active a{
  border-bottom: 2px solid #FF5722 !important;
  color: #FF5722;
}
/*============================
tablas
==============================*/
/*datatable hacks*/
table.dataTable {
  clear: both;
  margin-top: -1px !important;
  margin-bottom: 0px !important;
  max-width: none !important;
}

table.dataTable thead > tr > th {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
table.dataTable thead {background-color: #f5f5f5;}

.data-table-fixed-colum {border-right: 1px solid #ddd;}
.data-table-fixed-colum th, .data-table-fixed-colum td { white-space: nowrap; }

table.dataTable.data-table-fixed-colum > thead > tr > th{
  padding: 8px 22px 8px 8px;
  vertical-align: middle;
}
table.dataTable.data-table-fixed-colum > thead > tr > th small{ font-weight: normal; opacity: 0.8}
table.dataTable.data-table-fixed-colum > tbody > tr>td{
  vertical-align: middle;
  text-align: center;
}
table.dataTable.data-table-fixed-colum > tbody > tr>td:first-child{
  text-align: left;
}

/*tabla justified*/
.table-justified{
  table-layout: fixed
}
/*Table reset*/
.table tr.white th{
  background: #fff;
}
.table>tbody+tbody{
  border: 1px;
}
/*tabla con doble row hover*/
.table-hover-doble tr:hover, .table-hover-doble tr:hover+tr.adicional{
  background: #f5f5f5;
}
.table-hover-doble tr.adicional:hover{
  background: #f9f9f9;
}
/*END tabla con doble row hover*/
/*tabla en alert*/
.alert .table th {background: none}
.alert-success .table>thead>tr>th, .alert-success .table>tbody>tr>th, .alert-success .table>tfoot>tr>th, .alert-success .table>thead>tr>td, .alert-success .table>tbody>tr>td, .alert-success .table>tfoot>tr>td{
  border-color: #b7d99c;
}
.alert-danger .table>thead>tr>th, .alert-danger .table>tbody>tr>th, .alert-danger .table>tfoot>tr>th, .alert-danger .table>thead>tr>td, .alert-danger .table>tbody>tr>td, .alert-danger .table>tfoot>tr>td{
  border-color: #ebccd1;
}
/*tabla alto 100%*/
.row-eq-height .table {
  height: 100%;
}
.row-eq-height .table-responsive {
  height: 100%;
}
/*END tabla alto 100%*/

/*tabla alineada middle*/
.table.valignmiddle td, .table.valignmiddle th{
  vertical-align: middle;
}
td.valignmiddle{
  vertical-align: middle !important;
}
.nav-tabs.nav-justified>li>a {
    text-align: center;
    margin-bottom: -3px;
}
.bbottom{
  border-bottom:1px solid #ddd;
}
/*DASHBOARD SALFACORP*/
.advice ul.list-group{
  font-size: 11px;
}
table .well{
  margin: 0;
  padding: 5px;
}
.imagen-width {
  max-width: 220px;
  height: auto;
}

/*tabla para documentos salfa*/
.table-separador >tbody+tbody, .table-separador  tbody{
  border-bottom: 10px solid #e9e9e9;
}
.table-condensed .list-unstyled {
  margin-bottom: 1px;
}
.table-docs tr.bg-docs {
  background: #f5f5f5;
}
/*tabla con fondo transparente*/
.table-transp tr {
  background: #f5f5f5;
}
.table-transp th {
  background: #e8e8e8;
}
/*border-top en tabla*/
.btop{
  border-top: 3px solid #e8e8e8;
}
.table-docs tr.btop:first-child{
  border-top: 0;
}
td.bnone {
  border-top: 0 !important;
}
/*tabla con scroll*/
.tabla-scroll tbody.scrolling {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80vh;
}
/*tabla con head fixed*/
.ts-row-fixed {
  position: fixed;
  top: 40px !important; /*eliminar esta linea cuando se implemente para salfa*/
  visibility: hidden;
  z-index: 1020; /* 10 less than .navbar-fixed to prevent any overlap */
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}
/*tabla calendario*/
.table-calendar{
  table-layout: fixed
}
.table-calendar th{
  background-color: #FF8A65;
  color: #fff;
  font-weight: 500;
}
.table-calendar td{
  height: 85px;
  max-height: 85px;
  position: relative;
}
.table-calendar>tbody>tr>td:hover, .table-calendar>tbody>tr>td:hover{
    background-color: #f5f5f5!important;
    cursor: pointer;
}
.table-calendar>tbody>tr>td.info:hover, .table-calendar>tbody>tr>td.info:hover{
    background-color: #d9edf7!important;
    cursor: pointer;
}
.table-calendar>tbody>tr>td.cursor-no-drop:hover, .table-calendar>tbody>tr>td.cursor-no-drop:hover{
  cursor: no-drop;
  background-color:transparent !important;
}
.table-calendar >tbody>tr:hover>td{
    background-color: inherit;
}
.table-calendar td .badge-today{
  min-height: 21px;
  line-height: normal;
  background: #FF8A65;
}
/*panel lateral para anuncios en dashboard*/
.lateral {
  background: #ff5722;
}
.lateral ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.lateral h3 {
  margin: 0;
  text-align: center;
  color: #ffffff;
}
.lateral:after {
  content: '';
  display: block;
  position: absolute;
  left: 45%;
  top: 40%;
  width: 0;
  height: 0;
  border-top: 10px solid #ff5722;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

/*============================
dropdown-modal-action
==============================*/
.dropdown-menu.dropdown-modal-action {
  width: 600px;
  right: 0px !important;
  left: inherit;
  margin-top: 12px !important;
  padding: 0;
}
.dropdown-menu.dropdown-modal-action:after, .dropdown-menu.dropdown-modal-action:before {
  bottom: 100%;
  left: 90%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.dropdown-menu.dropdown-modal-action:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #f7f7f7;
  border-width: 10px;
  margin-left: -10px;
}
.dropdown-menu.dropdown-modal-action:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #ddd;
  border-width: 11px;
  margin-left: -11px;
}
.dropdown-menu.dropdown-modal-action .dropdown-header{
  margin: 0;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 3px 3px 0 0;
}
.dropdown-menu.dropdown-modal-action .dropdown-body{padding: 15px;}
.dropdown-menu.dropdown-modal-action .dropdown-footer{
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
  border-radius: 0 0 3px 3px ;
  text-align: right;
}

/*============================
 Tren de estados
=============================*/
.bs-wizard {margin-top: 30px;padding: 0 15px 15px;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 14px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 12px; padding-left: 7%; margin-bottom: 15px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 15px; height: 15px; display: block; background: #fbe8aa; top: 28px; left: 10%; margin-top: -13px; margin-left: -8px; border-radius: 50%; z-index: 3;}
.bs-wizard > .bs-wizard-step:first-child > .bs-wizard-dot{left: 10%;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 7px; height: 7px; background: #fbbd19; border-radius: 50px; position: absolute; top: 4px; left: 4px; }
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 5px; box-shadow: none; margin: 20px 0; background-color: #ddd;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa; left: 10%}
.bs-wizard > .bs-wizard-step:first-child > .progress > .progress-bar{left: 0%}
.bs-wizard > .bs-wizard-step:last-child > .progress > .progress-bar{left: 0%}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%; z-index:3;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #ddd;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
/*.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}*/
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 10%; width: 100%; z-index: 1;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 90%; z-index: 0}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
/*color  Tren de estados*/

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.verde{background-color:#8acc8a;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot.verde:after{background:#449d44;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar.verde{ background-color:#8acc8a;}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.rojo{background-color:#e3827f;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot.rojo:after{ background:#d9534f;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar.rojo{background-color:#e3827f;}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.azul{background-color: #a8d6ed;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot.azul:after{background:#768391;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar.azul{background-color: #a8d6ed;}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.naranjo{background-color:#f5cc67}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot.naranjo:after{background: #d89e0e;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar.naranjo{background-color:#f5cc67;}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.gris{background-color:#ddd;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot.gris:after{ background:#ddd;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar.gris{background-color:#ddd;}

/*END Form Wizard*/

/*============================
Wizard tabla / Tren de estados en tabla
=============================*/
td.adicional{
  border-top: 0 !important;
}
.wrap-wizard{
  background: #fff !important;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
}
.wizard {
  margin-bottom: 3px;
}
.versalita{
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-weight: bold;
  margin-bottom: 10px;
}
.wizard .versalita{
  margin-bottom: 0;
}
.wizard .versalita .popover {
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-size: normal;
}
.wizard .versalita .popover .popover-title, .wizard .popover .popover-title{
  font-size: 14px;
}

.wizard p {
  padding: 3px 3px 3px 20px;
  margin-right: 0px;
  background: #eee;
  position: relative;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 0px;
  width: 24.5%;
}
.wizard p:before {
  width: 0;
  height: 0;
  border-top: 17px inset transparent;
  border-bottom: 17px inset transparent;
  border-left: 13px solid #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.wizard p:after {
  width: 0;
  height: 0;
  border-top: 17px inset transparent;
  border-bottom: 17px inset transparent;
  border-left: 13px solid #eee;
  position: absolute;
  content: "";
  top: 0;
  right: -13px;
  z-index: 2;
}
.wizard p:first-child:before,
.wizard p:last-child:after {
  border: none;
}
.wizard p:first-child {
padding: 3px 3px 3px 7px;
  -webkit-border-radius: 4px 0 0 4px;
     -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
}
.wizard p:last-child {
padding: 3px 7px 3px 20px;
  -webkit-border-radius: 0 4px 4px 0;
     -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
}
.wizard .badge {
  margin: 0;
  position: relative;
  top: 0px;
  font-size: 9px;
  padding: 3px 5px;
  background: transparent;
  color: #999;
  font-size: 10px;
}
.wizard p:first-child .badge {
  margin-left: 0;
}
.wizard .current {
  background: #007ACC;
  color: #fff;
}
.wizard .current:after {
  border-left-color: #007ACC;
}
.wizard p.ok{
  color: #449d44;
  /*background: #eaf6ea;*/
  background-color: #dff0d8;
}
.wizard p.ok .badge{
   background: none;
   color: #449d44;
}
.wizard p.ok:after {
  border-left-color: #dff0d8;
}
.wizard p.error{
  color: #d9534f !important;
  /*background: #fbeeed;*/
  background: #f2dede;
}
.wizard p.error .badge{
  background: none;
  color: #d9534f;
}
.wizard p.error:after {
  border-left-color: #f2dede;
}
.wizard p.warning{
  color: #d89e0e;
  background: #fdf3dd;
}
.wizard p.warning .badge{
   background: none;
   color: #d89e0e;
}
.wizard p.warning:after {
  border-left-color: #fdf3dd;
}
.wizard p.info{
  color: #31708f;
  background-color: #d9edf7;
}
.wizard p.info .badge{
   background: none;
   color: #31708f;
}
.wizard p.info:after {
  border-left-color: #d9edf7;
}
.pop-pago{
  background: #449d44;
  color: #fff !important;
  padding: 1px 5px;
  border-radius: 999px;
  font-size: 0.9em;
  vertical-align: top;
}
.pop-ref, .pop-ref-cedida {
  border-radius: 999px;
  vertical-align: middle;
}
.action-to-do{
  font-size: 0.9em;
  border: 1px dashed #768391;
  padding: 5px;
  border-radius: 3px;
  margin-top: 10px;
  color: #768391;
  display: block;
}
/*Flujo aprobacion heredado de style.css*/
.comentario-flujo {
    background: #f8eae7;
}
.comentario-flujo:after{
   border-bottom: 8px solid #f8eae7;
}
.wrap-messages-flujo {
  border-left: 2px solid #f0906e;
}
span.box-flujo {
  background: #fdf8f8;
  border: 2px solid #f8eae7;
  color: #ff5722;
}
/*=============================
alert tablas /tables borders colors
==============================*/
td.document a{
  text-decoration: underline;
  font-size: 1.1em;
  margin-right: 3px;
  font-weight: bold;
}
td.document a:hover{
  color: #333;
}
td.document  ul, th.document  ul{
  margin-left: 5px;
}
td.document  ul li .badge, th.document  ul li .badge{
  margin-top: 5px;
  font-size: 9px;
  text-transform: uppercase;
  padding: 5px 7px 4px;
  border-radius: 4px;
}
.bleft{
  border-left: 1px solid #eee;
}
.badge-success{
  color: #449d44;
  background-color: #dff0d8;
}
.badge-danger{
  color: #d9534f;
  background: #f2dede;
 /* border:1px solid #f1c1c0;*/
}
.badge-info{
  color: #31708f;
  background-color: #d9edf7;
}
.badge-warning{
  color: #d89e0e;
  background: #fdf3dd;
}
.badge-orange{
  color: #fff;
  background: #FF8A65;
}
.badge-orange-inline{
  color: #ff5722;
  background: transparent;
  border:1px solid #ff5722;
}
.badge-white-inline{
  color: #ff5722;
  background: #fff;
}
.border-left{
 /* border-left: 5px solid #ddd !important;*/
 /* border-bottom: 1px solid #c0c0c0 !important;*/
}
.border-left.danger{
  color: #d9534f;
  background: #fff !important;
  -moz-box-shadow:    inset 5px 0px 0px 0px rgba(217, 83, 79, 1);
  -webkit-box-shadow: inset 5px 0px 0px 0px rgba(217, 83, 79, 1);
  box-shadow:         inset 5px 0px 0px 0px rgba(217, 83, 79, 1);
}
.border-left a i{
  font-size: 18px;
}
.border-left.danger a{
  color: #d9534f;
  text-decoration: underline;
}
.border-left.success{
  color: #449d44;
  /*background: #eaf6ea !important;*/
  background: #fff !important;
  -moz-box-shadow:    inset 5px 0px 0px 0px rgba(68, 157, 68, 1);
  -webkit-box-shadow: inset 5px 0px 0px 0px rgba(68, 157, 68, 1);
  box-shadow:         inset 5px 0px 0px 0px rgba(68, 157, 68, 1);
}
.border-left.success a{
  color: #449d44;
  text-decoration: underline;
}
.border-left.warning{
  color: #d89e0e;
  /*background: #fefaf0 !important;*/
  background: #fff !important;
  -moz-box-shadow:    inset 5px 0px 0px 0px rgba(216, 158, 14, 1);
  -webkit-box-shadow: inset 5px 0px 0px 0px rgba(216, 158, 14, 1);
  box-shadow:         inset 5px 0px 0px 0px rgba(216, 158, 14, 1);
}
.border-left.warning a{
  color: #d89e0e;
  text-decoration: underline;
}
.border-left.info{
  color: #31708f;
  /*background: #e9f5fa !important;*/
  background: #fff !important;
  -moz-box-shadow:    inset 5px 0px 0px 0px rgba(49, 112, 143, 1);
  -webkit-box-shadow: inset 5px 0px 0px 0px rgba(49, 112, 143, 1);
  box-shadow:         inset 5px 0px 0px 0px rgba(49, 112, 143, 1);
}
.border-left.info a{
  color: #31708f;
  text-decoration: underline;
}
td.border-left ul, th.border-left ul{
  margin-left: 10px;
  /*display: inline-block;
  max-width: 80%;*/
}
td.border-left h3, th.border-left h3{
  font-size: 18px;
  position: relative;
  margin: 0px;
  display: inline-block;
  top: -2px;
  left: 5px;
  vertical-align: top;
}
/********
  Tabla para indicadores
********/
.panel-kpi .panel-body{
  padding: 10px;
}
.panel-kpi .versalita{
  margin: 0;
  font-size: 1em;
  color: #777;
  letter-spacing: 2px;
}
.list-kpi{
  list-style-type: none;
  padding-left: 15px;
}
.list-kpi li{
  padding: 7px 0;
}
.list-kpi li a{
  color: #FF5722;

}
.table-kpi{
  vertical-align: middle;
  color: #444;
}
.table-kpi td{
  border-right: 1px solid #e8e8e8;
}
.table-kpi td:last-child{
  border-right: none;
}
.table-kpi .big{
  font-size: 2.6em;
  font-weight: bold;
  margin: 10px 0;
}
/*Fuente reducida a un 50% con respecto a la clase big*/
.table-kpi .medium{
  font-size: 1.3em;
  font-weight: bold;
  margin: 10px 0;
}
.table-kpi .lightgray{
  color: #666;
}
.table-kpi .big-filter {
  font-size: 2.6em;
  font-weight: bold;
  margin: 3px 0;
}
.table-kpi .legend{
  float: left;
  font-size: 0.9em;
  padding-top: 7px;
}

.table-kpi .btn-rounded-m0{
  margin-top: 0px;
}
.wrap-kpi{
  padding-right: 50px;
  padding-left: 15px;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
}
.table-kpi td:hover .wrap-kpi{
  color: #000;
}
.superbig{
  font-size: 70px;
  margin: 0;
}

/*antiguo css luego de aprobar eliminar*/
.border-lesft-default{
  border-left: 5px solid #ddd !important;
}
.border-lesft-danger{
  border-left: 5px solid #d9534f !important;
}
.border-lesft-success{
  border-left: 5px solid #449d44 !important;
}
.border-lesft-warning{
 border-left: 5px solid #d89e0e !important;
}
/* END antiguo css luego de aprobar eliminar*/

/*============================
popover
=============================*/
.popover{
  border-radius: 3px;
  color: #666;
  /*min-width: 220px;*/
  border: 1px solid rgba(0,0,0,.2);
}
.popover-content {
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
}
.popover-lg{
  min-width: 450px;
}
.popover-content-lg{
  padding: 15px;
}
.popover-footer{
  padding: 10px 14px;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
  text-align: right;
}
.popover ul.datos strong {
  margin-right: 8px;
}
.popover-content .versalita{
  display: block;
  margin-bottom: 10px;
}
.popover-content .table{
  font-size: 0.9em;
}
.popover-content .table>thead>tr>th, .popover-content .table>tbody>tr>th, .popover-content .table>tfoot>tr>th, .popover-content .table>thead>tr>td, .popover-content .table>tbody>tr>td, .popover-content .table>tfoot>tr>td{
  padding: 3px;
}
.popover .close-pop{
  position: absolute;
  right: 0;
  top: 0;
}
/*============================
badge & labels
=============================*/
.badge.step{
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 9px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #999;
  border-radius: .25em 0em 0em .25em;
}
.label.step{
  border-radius: 0em .25em .25em 0em;
  padding: 1px 4px 2px 4px;
}
.label.etiqueta-kit{
  float: right;
  margin-right: -30px;
  margin-top: 16px;
  border-radius: 3px 0px 0px 3px;
  font-size: 13px;
}
/*==========================
altos maximos scroll vertical
=============================*/
.modal-content-right .alto50vh{
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
  margin-right: 10px;
}
.modal-content-right .panel .alto50vh{
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
  margin-right: 0px;
}
/*============================
Bootstrap notify IC
=============================*/
/*mensajes de sistema boottrap notify*/
.icon-notificaction{
  position: absolute;
}
.notify-content-text{
  margin-left: 38px;
}
/*============================
Notificaciones
=============================*/
.line-top{ border-top: 1px solid #ddd;}
.line-right{ border-right: 1px solid #ddd;}
.line-left{ border-left: 1px solid #ddd;}
.line-bottom{ border-bottom: 1px solid #ddd;}

[data-notify="progressbar"] {
    margin-bottom: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 5px;
}
.w40{
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
/*----- mensaje de alerta----*/
#mensaje-alerta {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
#inner-message {
    margin: 0 auto;
}
.icon-notification {
  display: block;
  width: 5%;
  height: 100%;
  float: left;
}
/*=====================
Chat
=======================*/
.h200-chat{
    max-height: 200px;
    min-height: 200px;
    overflow-y: scroll;
}
.h180-panel {
  min-height: 180px;
  max-height: 180px;
}
.h150-panel {
  min-height: 150px;
  max-height: 150px;
}
.h120-panel {
  min-height: 120px;
  max-height: 120px;
}
.h400-chat{
    max-height: 400px;
    min-height: 400px;
    overflow-y: scroll;
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-grey{
  background-color: #f9f9f9;
}
.bg-grey-dark{
  background: #666;
}
.bg-grey-light{
  background: #999;
}
.bg-plans{
  background-color: #f1f1f1;
  padding: 5% 0;
}
.chat-list {
  list-style: none;
  background: #fff;
  padding-left: 0px;
  font-size: 13px;
  border: 1px solid #eee;
  border-bottom: none;
  border-radius: 3px;
}

.chat-list li {
  border-bottom: 1px solid #eee;
}

.chat-list li a img {
  float: left;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

 .chat-list li a {
  position: relative;
  display: block;
  padding: 10px;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
}

.chat-list li.active a {
  background-color: #f1f5fc;
}

.chat-list li a .friend-name,
.chat-list li a .friend-name:hover {
  color: #777;
}

.chat-list li a .last-message {
  width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat-list li a .time {
  position: absolute;
  top: 10px;
  right: 8px;
}

.chat-list .small {
  font-size: 85%;
}

.chat-list li a .chat-alert {
  position: absolute;
  right: 8px;
  top: 27px;
  font-size: 10px;
  padding: 3px 5px;
  line-height: 12px;
  border: none;
}
.chat-list .label-danger {
    background-color: #ff5722;
    color: #fff;
}
.chat-message {
  padding: 00px 20px 60px;
}

.chat {
    list-style: none;
    margin: 0;
}

.chat-message{
    background: #f9f9f9;
}

.chat li img {
  width: 45px;
  height: 45px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.chat-body {
  padding-bottom: 20px;
}

.chat li.left .chat-body {
  margin-left: 70px;
  background-color: #fff;
}

.chat li .chat-body {
  position: relative;
  font-size: 11px;
  padding: 10px;
  border: 1px solid #f1f5fc;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.chat li .chat-body .header {
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f5fc;
}

.chat li .chat-body p {
  margin: 0;
}

.chat li.left .chat-body:before {
  position: absolute;
  top: 10px;
  left: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-left: 1px solid #f1f5fc;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.chat li.right .chat-body:before {
  position: absolute;
  top: 10px;
  right: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-right: 1px solid #f1f5fc;
  content: '';
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.chat li {
  margin: 15px 0;
}

.chat li.right .chat-body {
  margin-right: 70px;
  background-color: #fff;
}

.chat-box {
  position: fixed;
  bottom: 0;
  left: 27%;
  right: 0;
  padding: 15px;
  border: 1px solid #eee;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
}


/*--------- burger button-------*/
#nav-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 14px 45px 6px auto;
/*  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;*/
  cursor: pointer;
  color: #fff;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
#nav-icon2 span.text-menu{
  /*margin-left: 25px;
  height: 0px;
  top: -3px;*/
  margin-left: 20px;
  height: 40px;
  width: 45px;
  background: #FF5722;
  bottom: -6px;
  padding-top: 12px;
  padding-left: 5px;
}
#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 1px 1px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 1px 0 0 1px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 5px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 10px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 0px;
  top: 5px;
  width: 100%;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(25% - 5px);
  top: 5px;
  width: 100%;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 2px;
  top: 12px;
  opacity: 0;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 3px);
  top: 12px;
  opacity: 0;
}



/*--------type ahead--------------*/
.tt-menu {
    background: #fff none repeat scroll 0 0;
    color: #000;
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/*===================
acordiones
=====================*/
.accordion-toggle i.arrow{
  font-size: 18px;
}
.accordion-toggle.collapsed  i.arrow{
  float: right;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
.accordion-toggle  i.arrow{
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
/*===================
MODAL LEFT/RIGHT SIDEBAR
===================*/
.modal.left, .modal.right{
  overflow-y: auto;
  overflow-x: auto;
}
.modal.left > .modal-dialog,
.modal.right > .modal-dialog {
  position: fixed;
  margin: auto;
  width: 38%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}
.modal.left > .modal-dialog.modal-lg,
.modal.right > .modal-dialog.modal-lg {
  width: 62vw;
}
.modal.left > .modal-dialog.modal-xl,
.modal.right > .modal-dialog.modal-xl {
  width: 90vw;
}
.modal.left  > .modal-dialog > .modal-content,
.modal.right > .modal-dialog > .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}
.modal.right .close{
    float: left;
    /*margin-right: 15px;*/
}
.modal.right .alert .close, .modal.left .alert .close{
  float: right;
}

.modal.left > .modal-dialog > .modal-content > .modal-body,
.modal.right > .modal-dialog > .modal-content >.modal-body {
  padding: 15px 15px 80px;
}
/* Modal slidebar Left*/
.modal.left.fade .modal-dialog{
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.in .modal-dialog{
  left: 0;
}
/* Modal slidebar Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.in .modal-dialog {
  right: 0;
}

.modal.left .modal-header-fixed,
.modal.right .modal-header-fixed{
  position: fixed;
  width: 100%;
  z-index: 11;
}
.modal.modal-inside-modal .modal-content{
  -webkit-box-shadow: -4px 17px 37px -10px rgba(0,0,0,0.65) !important;
  -moz-box-shadow: -4px 17px 37px -10px rgba(0,0,0,0.65)!important;
  box-shadow: -4px 17px 37px -10px rgba(0,0,0,0.65)!important;
}
.modal.modal-inside-modal .close{
  float: right;
}

/*Modal confirmaciones*/
.modal-confirm {
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-content .selectpicker{
  text-align: left;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
  background: transparent;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 15px 0 0px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #666;
  text-align: left;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .modal-footer .btn {
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}
.modal-confirm .modal-footer .btn-default{
  background: rgb(193, 193, 193);
}
.modal-confirm .modal-footer .btn-default:hover, .modal-confirm .modal-footer .btn-default:focus {
  background: #a8a8a8;
}
/*===================
  Modals
=====================*/
.modal-body-scroll {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0px;
  box-shadow: inset 0 0 10px #dadfe1;
  border-radius: 0px 0px 2px 2px;
}
.modal .alto-doc{
  margin-bottom: 0px;
  max-height: 450px;
  min-height: 450px;
  overflow-y: auto;
}
.modal .alto-doc2{
  margin-bottom: 0px;
  max-height: 250px;
  min-height: 250px;
  overflow-y: auto;
}
.modal-header .close {
    margin-top: 0px;
    font-size: 2.1em;
    opacity: .7;
}
.modal-header a.btn {
    margin-top: -3px;
    right: 0px;
}
.masive-info {
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857143px;
}
.modal .carousel-control.left{
  left: -35px;
}
.modal .carousel-control.right {
  right: -35px;
}
.modal.adjuntos .carousel-control.left{
  left: 0px;
}
.modal.adjuntos .carousel-control.right {
  right: 0px;
}
.modal .carousel-control{
  color: #fff;
  width: 30px;
  z-index: 10;
}
.bg-modal-content {
    background: #eee !important;
    padding:10px 10px 0px 10px;
}
.panel .doc-content {
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 0px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.wrap-all-messages-desktop {
  padding-left: 20px;
  padding-right: 10px;
}
.wrap-messages-desktop {
  border-left: 3px solid #f3f3f3;
  min-height: 100%;
  margin-top: 15px;
}
.modal-content-ad .close{
  position: absolute !important;
  right: 15px !important;
  top: 10px !important;
}
.modal-footer.contacto{
  background: #f9f9f9;
  padding:10px 20px;
  margin-top: 0px;
}
.modal-right-img{
    background-image: url(http://diseno.iconstruye.cl/img/bg-landing-registro.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: left -180px bottom;
    height: 100%;
    /*width: 50%;*/
    /*position: fixed;*/
}
/*==============================
Modal detalle documentos portal salfa
================================*/
/*.modal-detalle .modal-header{
  width: 99.8%;
  position: fixed;
  z-index: 1;
}*/
.modal-detalle .modal-body{
  background: #eee;
}
.wrap-modal-side-bar{
  padding-right: 0;
}
.modal-side-bar{
  background: #fff;
  padding-top: 15px;
  min-height: 535px;
}
.modal-side-bar .btn{
  margin-bottom: 3px;
}
.modal-side-bar h3 {
   margin-top: 0px;
   line-height: 1.6em;
   font-size: 21px;
   padding-left: 15px;
   padding-right: 15px;
   margin-bottom: 0;
}
.modal-side-bar h3 small{
  font-size: 14px;
  display: block;
  font-weight: 100;
}
.modal-side-bar h5{
  font-size: 12px;
  margin-top: 0px;
  padding-left: 15px;
  padding-right: 15px
}
.modal-side-bar ul {
  padding-left: 15px;
  padding-right: 15px
}
.modal-side-bar .referencias{
  padding: 0 15px;
}
.content-collpase-side .referencias .table{
  margin-top: 10px;
  width: 90%;
}
.content-collpase-side ul{
  margin-right: 10px;
}
.modal-side-bar a.head-collapse.two{margin-top: -1px;}

.modal-side-bar a.head-collapse i.arrow{
  font-size: 16px;
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;

}
.modal-side-bar a.head-collapse.collapsed i.arrow{
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}

.modal-side-bar .body-collapse, .modal-side-bar .actions-p-body{
  padding:10px 15px;
  background: #efefef;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}
.modal-side-bar .body-collapse ul li,  .modal-side-bar .actions-p-body  ul li{
  line-height: 18px;
  border-color: #ddd;
  padding-bottom: 0px;
}
.modal-side-bar .body-collapse ul li hr, .modal-side-bar .actions-p-body  ul li hr{
  margin: 8px 0px;
}

/*-------content modal right-------*/
.modal-content-right{
  border-left: 1px solid #ddd;
  padding-top: 15px;
  padding-right: 30px;
}
.modal-detalle .wrap-messages{
  border-left: 0;
  min-height: 100%;
  padding-bottom: 15px;
  margin-top: -15px;
}
.modal-detalle  .container-message{
  margin: 5px 0 5px 13px;
  position: relative;
}
.modal-detalle .wrap-messages .container-message,
.modal-detalle .wrap-messages .panel,
.modal-detalle .wrap-messages .alert{
  margin-bottom: 0px;
}
.modal-detalle p.time{
  margin-top: 30px;
}
/*-------Mensajes dentro del modal-------*/
.modal-detalle .wrap-all-messages{
  padding: 20px;
  margin-top: 20px;
  max-height: 430px;
  min-height: 430px;
  overflow-y: scroll;
  margin-right: 1px;
}
.modal-detalle .wrap-messages .message{
  border-left: 3px solid #f3f3f3;
  padding-bottom: 20px;
}
.modal-detalle .wrap-messages .message.last{
  border-left: 3px solid #fff;
}
.nav-tabs.nav-justified{
  padding-left: 0;
}
.modal-detalle .tab-content{
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ddd;
  max-height: 70vh;
  min-height: 70vh;
}
.modal-detalle .tab-content .tab-content {
    border: none;
    padding: 0px;
    max-height: 65vh;
    min-height: 65vh;
}
.modal-detalle .doc-modal-box {
    max-height: 65vh;
    min-height: 65vh;
    overflow-y: scroll;
}
.nav-tabs.nav-justified>li>a{
  border-radius: 0px;
  margin-left: -1px;
  /*background: #F5F5F5;*/
}
.nav-tabs.nav-justified>li:first-child>a{margin-left: 0px;}
.nav-tabs.nav-justified>.active>a, .modal-detalle .nav-tabs.nav-justified>.active>a:hover, .modal-detalle .nav-tabs.nav-justified>.active>a:focus {
  border-bottom-color: #fff;
  background-color: #fff;
}
.modal-detalle  span.box-time{
  height: 15px;
  width: 15px;
  margin-left: -9px;
}
.modal-detalle .container-message{
  background: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
}
.modal-detalle .container-message .label{
  border-radius: 999px;
  padding:3px 10px;
}
.modal-detalle .container-message p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.modal-detalle .h500{
  height: 500px;
}
.modal-obs {
  background: #eeeeee;
  padding: 20px 20px 10px 20px;
}
/*detalle del historial*/
.historial-detalle .wrap-all-messages {
  padding: 20px;
}
.historial-detalle .panel-body {
  padding:10px 10px 0 10px;
  background-color: #f3f3f3;
}
.historial-detalle .container-message .label{
  border-radius: 999px;
  padding:3px 10px;
}
/*===================
oc segura
=====================*/
/*panel estados*/

.resultados-oc {
  margin: auto 15%;
}
.resultados-oc .alert-warning .fa-exclamation-triangle{
  color: #eeba4f;
}
.info-oc{
  position: relative;
  margin-top: 15px;
  font-size: 12px;
  padding:0px 15px;
  text-align: center;
  color: #666;
  font-style: italic;
}
.info-oc::before{
  content: "";
  background: #ddd;
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  z-index: 1;
}
.info-oc div{
  background: #f9f9f9;
  position: relative;
  z-index: 2;
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.well-anuncio {
    padding: 0px 13px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.well-anuncio small{color: #31708f;}
.well-anuncio h3{ margin: 0px; line-height: normal; font-size: 18px;}
.well-anuncio i {
  position: absolute;
  font-size: 1.3em;
    margin-top: 12px;
    margin-left: 5px;
  transform: rotate(340deg) scale(1) skew(1deg) translate(0px);
    -webkit-transform: rotate(340deg) scale(1) skew(1deg) translate(0px);
    -moz-transform: rotate(340deg) scale(1) skew(1deg) translate(0px);
    -o-transform: rotate(340deg) scale(1) skew(1deg) translate(0px);
    -ms-transform: rotate(340deg) scale(1) skew(1deg) translate(0px);
}
.well-anuncio ul{
  margin-left: 40px;
}

/*===================
  Modals oc segura escritorio
=====================*/
.aviso-success {
  background: #3c763d;
  text-align: center;
  line-height: normal;
  color: #dff0d8;
  /*margin-bottom: 20px;*/
  padding: 15px 10px 8px 10px;
}
.aviso-success p {
  color: #dff0d8;
}
.resultados-oc .aviso-success, .resultados-oc .aviso-warning{
  text-align: left;
  padding: 20px 130px 20px 10px;
  position: relative;
  margin-bottom: 15px;
}
.resultados-oc .aviso-success a.btn, .resultados-oc .aviso-warning a.btn{
  position: absolute;
  right: 15px;
  /*margin-top: -5px;*/
}
.aviso-success small, .aviso-danger small{display: block;}
.aviso-danger {
  background: #a94442;
  padding: 15px 10px 8px 10px;
}
.aviso-danger p {
  color: #f2dede;
  text-align: center;
}
.aviso-warning {
  /*background: #8a6d3b;*/
  background: #eeba4f;
  padding: 15px 10px 8px 10px;
  text-align: center;
  line-height: normal;
  /*color: #fcf8e3;*/
  color: #8a6d3b;
}
.aviso-success p small, .aviso-warning p small, .aviso-danger p small{ font-style: italic;}
.modal-body-danger{
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    padding: 10px;
}
.modal-body-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 10px;
}
.modal-body-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
  padding: 10px;
}
.modal-body-success th, .modal-body-danger th, .modal-body-warning th {background: none}

.modal-body-success .table>thead>tr>th, .modal-body-success .table>tbody>tr>th, .modal-body-success .table>tfoot>tr>th, .modal-body-success .table>thead>tr>td, .modal-body-success .table>tbody>tr>td, .modal-body-success .table>tfoot>tr>td{
  border-color: #b7d99c;
}
.modal-body-danger .table>thead>tr>th, .modal-body-danger .table>tbody>tr>th, .modal-body-danger .table>tfoot>tr>th, .modal-body-danger .table>thead>tr>td, .modal-body-danger .table>tbody>tr>td, .modal-body-danger .table>tfoot>tr>td{
  border-color: #ebccd1;
}
.modal-body-warning .table>thead>tr>th, .modal-body-warning .table>tbody>tr>th, .modal-body-warning .table>tfoot>tr>th, .modal-body-warning .table>thead>tr>td, .modal-body-warning .table>tbody>tr>td, .modal-body-warning .table>tfoot>tr>td{
  border-color: #faebcc;
}
/*=====================
BANNER PROMO / MODULO
=======================*/
.banner-promo {
    margin-bottom: 0px;
    margin-top: 40px;
    padding: 90px 0 50px 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),url(/assets/img/bg.jpg);
    background-position: center center;
    background-size: cover;
}
.banner-promo.modulo {
    margin-bottom: 0px;
    margin-top: 40px;
    padding: 100px 0 80px 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),url(/assets/img/bg.jpg);
    background-position: center center;
    background-size: cover;
}
.banner-promo h2{
  color: #fff;
  margin: 0px;
}
.content-promo{
  background: #fff;
  border: 1px solid #ddd;
  padding: 55px 25px 40px;
  margin-top: -40px;
}
i.green {
    color: #21BA45;
}
i.red {
    color: #DB2828;
}
i.orange {
    color: #FF5722;
}
.table.table-expanded>thead>tr>th, .table.table-expanded>tbody>tr>th, .table.table-expanded>tfoot>tr>th, .table.table-expanded>thead>tr>td, .tabletable-expanded>tbody>tr>td, .tabletable-expanded>tfoot>tr>td {
  padding: 15px;
}
.table.table-expanded>thead>tr>th, .table.table-expanded>tbody>tr>th, .table.table-expanded>tfoot>tr>th, .table.table-expanded>thead>tr>td, .table.table-expanded>tbody>tr>td, .table.table-expanded>tfoot>tr>td {
  padding: 15px;
}
/*===========
Footer
=============*/
.info-block{
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  border-top: 1px solid #ddd;
  margin-top: 60px;
}
.info-block h4{
  font-size: 16px;
  margin-top: 0;
}
.info-block ul{
  list-style: none;
  padding: 0 0 20px 0;
}
.info-block ul li{
  display: block;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
}
/*=====================
Dashboard portal
=======================*/
.pd .panel-heading span ul li:last-child{
  padding-right: 0px;
}
.producto-portal{
  height: 100%;
}
.producto-portal{
  height: 100%;
  color: #333;
}
.producto-portal:hover{
  text-decoration: none;
  opacity: .8;
  cursor:pointer;
}
.producto-portal .icon-border{
  color: #ff5722;
  border-color: #ff5722;
  padding: 9px 12px;
}
.producto-portal  h3.versalita{
  color: #ff5722;
}
.otros-productos{}
.otros-productos td.otros-productos-head{
  background: #ff5722;
  color:#fff;
}
.otros-productos td.otros-productos-head:hover .wrap-kpi{
  color: #fff !important;
}
/*=====================
botones
=======================*/
.btn-back {
    border-right: none;
    padding-right: 5px;
    margin-right: 5px;
    font-size: 100%;
    color: #999;
}
.btn-back:hover{
  text-decoration: none;
  color: #0079C1;
}
/*.btn-default.active{
  box-shadow: none;
  background:#FF5722;
  color:#fff;
  border-color:#FF5722;
}*/
.btn-rounded{
  border-radius: 999px;
  /*background: #fff;
  color: #FF5722;*/
}
.btn-rounded:hover{
  /*background: #FF5722;
  color: #fff;*/
}
.btn-orange{
  background: #FF5722;
  color: #fff;
  border:1px solid #FF5722;
}
.btn-orange:hover{
  opacity: .9;
}
.btn-orange-inline{
  background: #fff;
  color: #ff5722;
  border:1px solid #FF5722;
}
.btn-orange-inline:hover{
  background: #ff5722;
  color: #fff;
}
.btn-orange:hover,
.btn-orange:focus{
  background: #FF5722;
  color: #fff;
}
.btn-ver-clave{
  position: absolute;
  right: 25px;
}
.btn-fijo{
  position: absolute;
  bottom: 15px;
}

/*=====================
filtros
=======================*/
.well.tag-filtros{
  margin-top: -31px;
}
.panel-body.filtros{
  padding: 0 15px;
}
/*filtros -xs*/
.filtros-xs .col-xs-2, .filtros-xs .col-xs-3, .filtros-xs .col-xs-4, .filtros-xs .col-xs-5, .filtros-xs .col-xs-6, .filtros-xs .col-xs-7, .filtros-xs .col-xs-8, .filtros-xs .col-xs-9 {
    padding-top: 1px;
    padding-bottom: 2px;
}
.filtros .botones.col-xs-2{
    border-left: 1px solid #e3e3e3;
    left: -1px;
}
.filtros-xs .botones .btn-group, .filtros-xs .botones .btn {
    margin: 10px 0px 0px 0;
}
.filtros-xs .botones-proveedor .btn-group, .filtros-xs .botones-proveedor .btn {
    margin: 20px 0px 0px 0;
}
.filtros-xs .input-group-xs .daterangepicker-input{
    height: 25px;
    font-size: 11px;
    line-height: 1.5;
    padding: 2px 5px;
}
.filtros-xs label{
    margin-bottom: 3px;
}
/*.well.filtros-xs{
  margin-bottom: 20px;
}*/
.btn-filtros {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -1px;
}
.btn-filtros a {
  background: #fff;
  color: #4b4b4b;
  border: 1px solid #e3e3e3;
  padding: 3px 15px;
  border-radius: 0px 0px 10px 10px;
  font-size: 11px;
}
.btn-filtros a:hover{ text-decoration: none;}

.btn-filtros a i{
-moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;}

.btn-filtros a.down i{
-ms-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.filtros-xs .imput-chek-radio .input-group-addon, .filtros .imput-chek-radio .input-group-addon {
  background: #fff;

}
.filtros-xs .imput-chek-radio .input-group-addon input[type=radio], .filtros-xs .imput-chek-radio  .input-group-addon input[type=checkbox] {
    margin-top: 4px;
}
.filtros-xs .imput-chek-radio .input-group-addon:first-child, .filtros .imput-chek-radio .input-group-addon:first-child {
    border-right: 1px solid #ccc;
}

/*===========
Filtros rápidos
=============*/
.quick{
  padding-right: 0;
}
.quick .well{
  padding: 0;
  min-height: 115px;
}
.quick .well .table{
  min-height: 115px;
}
.quick .well .table td{
  border-top: 0;
  vertical-align: middle;
}
.quick .well td{
  padding: 10px;
  text-align: center;
}
.quick .well td strong{
  font-size: 1.4em;
}
.quick .well td.active{
  background: #FF5722;
}
.quick .well td.active a{
  color: #fff;
}

/*=====================
  Filtros nuevos en collapse dentro de la tabla
=======================*/
.table-compressed .single-line {
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.single-line{
  max-width: none;
}
.navbar-primary.navbar-primary-filters{
  /*height: 100vh;*/
  /*top:52px;
  transition:none;
  overflow:visible;
  left:17px;
  border-right: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 0px;*/
  top: 0px;
  transition: none;
  overflow:visible;
  left: 0px;
  border-right: none;
  margin-bottom: 30px;
  padding: 0px;
  float: left;
  width: 285px;
  position: relative;
}
.with-nav-tabs .navbar-primary.navbar-primary-filters{
  top:0px;
  border-top:none;
}
.navbar-primary.navbar-primary-filters .title-filters{
  /*border-bottom: 1px solid #eee;*/
  border-bottom: none;
  padding: 5px 0px 0px 15px;
}
.navbar-primary.navbar-primary-filters .title-filters h2{
  margin: 0;
  font-size: 18px;
  padding: 0;
  font-weight: bold;
}
.navbar-primary.navbar-primary-filters.collapsed{
  display: none;
}
.navbar-primary.navbar-primary-filters .navbar-primary-menu{
  /*padding: 15px;*/
  padding: 15px 0px 15px 15px;
}
.navbar-primary.navbar-primary-filters .content-collapse-side{
  padding-right:0;
  font-size:11px;
}


/*===========
switch destop- Mobile
=============*/
.switch {
    position: relative;
    width: 90px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
}
.switch-checkbox {
    display: none;
}

.switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #dddddd;
    border-radius: 20px;
}

.switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.switch-inner:before, .switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.switch-inner:before {
    content: "Si";
    padding-left: 10px;
    background-color: #8ce196;
    color: #FFFFFF;
}

.switch-inner:after {
    content: "NO";
    padding-right: 10px;
    background-color: #f1f1f1;
    color: #999999;
    text-align: right;
}

.switch-switch {
    display: block;
    width: 33px;
    /*margin: 6px;*/
    background: #FFFFFF;
    border: 1px solid #dddddd;
    /*border-radius: 20px;*/
    position: absolute;
    top: 0;
    bottom: 0;
    right: 56px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s;
}

.switch-checkbox:checked + .switch-label .switch-inner {
    margin-left: 0;
}

.switch-checkbox:checked + .switch-label .switch-switch {
    right: 0px;
}
/*===========
switch-sm
=============*/
.switch.switch-sm{width: 60px;}
.switch.switch-sm .switch-switch{
  width: 23px;
  right: 37px;
}
.switch.switch-sm .switch-inner:before {padding-left: 10px;}
.switch.switch-sm .switch-inner:after {padding-right: 8px;}
.switch.switch-sm .switch-inner:before, .switch.switch-sm .switch-inner:after{
  height: 21px;
  line-height: 22px;
  font-size: 12px;
}
.switch.switch-sm .switch-inner:before {
    content: "SI";
}
.switch.switch-sm .switch-checkbox:checked + .switch.switch-xs .switch-label .switch-switch {
    right: 0px;
}
/*===========
switch-xs
=============*/
.switch.switch-xs{width: 40px;}
.switch.switch-xs .switch-switch{
  width: 16px;
  right: 25px;
}
.switch.switch-xs .switch-inner:before {padding-left: 8px;}
.switch.switch-xs .switch-inner:after {padding-right: 4px;}
.switch.switch-xs .switch-inner:before, .switch.switch-xs .switch-inner:after{
  height: 15px;
  line-height: 15px;
  font-size: 10px;
}
.switch.switch-xs .switch-inner:before {
    content: "SI";
}
.switch.switch-xs .switch-checkbox:checked + .switch.switch-xs .switch-label .switch-switch {
    right: 0px;
}
/*=====================
Contoversias
=======================*/
.page-header small{
  font-size: 65%;
}
.side-cabecera {
    border-right: 1px solid #e1e1e1;
    padding-top: 15px;
    height: 100%;
    margin-top: -20px;
    position: fixed;
}

.cabecera {
  background: #f1f1f1;
  border-bottom: 1px solid #e5e5e5;
  margin-top: -20px;
  padding: 15px 0px 15px 0px;
}
.cabecera-info {
  background: #f1f1f1;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0px 15px 0px;
}
.cabecera h3{
  font-size: 18px;
  margin: 0px;
}
.cabecera #tiempo-restante{
  font-size: 18px;
}
.cabecera ul.f12{
  line-height: 21px;
}
.lista-max{
  margin-bottom: 0px;
  max-height: 150px;
  min-height: 150px;
  overflow-y: scroll;
}
/*end experto*/
/*ul.datos li small, ul.datos li span {
    float: left;
}*/
.icon-exito{
  font-size: 72px;
  color: #FF5722;
}
/*flyout reset ic*/
#bsa_flyout{
  bottom: 15px;
}
a.bsa_creative{
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 3px 0 3px 3px;
  background-color: #fff;
  width: 480px;
  height: 120px;
}
.flyoutbox_hide #bsa_flyout{
  right: -480px;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
a.bsa_creative span.bsa_flyout-content, a.bsa_alert span.bsa_flyout-content{
  width: 350px;
  padding: 10px 30px 10px 15px;
}
a.bsa_creative img {
    width: 80px;
}
/*======================
chat controversias experto
========================*/
.chat-window{
    bottom:0;
    position:fixed;
    float:right;
}
.chat-window > div > .panel{
    border-radius: 5px 5px 0 0;
    margin-bottom: 0px;
}
.icon_minim{
    padding:2px 10px;
}
.msg__base{
  background: #e5e5e5;
  margin: 0;
  padding: 0 10px 10px;
  max-height:300px;
  overflow-x:hidden;
}
.top-bar {
  background: #666;
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
.msg_receive{
    padding-left:0;
    margin-left:0;
}
.msg_sent{
    padding-bottom:20px !important;
    margin-right:0;
}
.chat-messages {
  background: white;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width:100%;
}
.chat-messages > p {
    font-size: 13px;
    margin: 0 0 0.2rem 0;
  }
.chat-messages > time {
    font-size: 11px;
    color: #ccc;
}
.msg_ {
    padding: 10px;
    overflow: hidden;
    display: flex;
}
img {
    display: block;
    width: 100%;
}
.avatar-chat {
    position: relative;
}
.base_receive > .avatar-chat:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 5px solid #FFF;
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .avatar-chat:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid white;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.msg_sent > time{
    float: right;
}



.msg__base::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.msg__base::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

.msg__base::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

/*.btn-group.dropup{
    position:fixed;
    left:0px;
    bottom:0;
}*/
/*======================
registro ic starter
========================*/
body.registro-starter {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    height: 100%;
    background-color: #000;
}
body.registro-starter:before {
    content: "";
    position: absolute;
    background: url(/assets/img/bg-register2.jpg);
    background-size: cover;
    z-index: -1;
    height: 20%; width: 20%;


    transform: scale(5);
    transform-origin: top left;
    filter: blur(1px);
}
body.registro-starter .content-register{
  margin-top: 5%;
}
body.registro-starter img.register-logo{
  margin: 0px 0px 15px 0px;
  max-width: 260px;
}
body.registro-starter .llamado h1{
  color: #fff;
  font-weight: 100;
  font-size: 42px;
}
body.registro-starter .llamado h4{
  color: #fff;
  font-weight: 400;
}

body.registro-starter .panel .panel-heading h2, body.registro-starter .panel .panel-heading h3  {margin: 0px;}
body.registro-starter .panel .panel-body {
  padding-top: 30px;
}
.orange-bg{
  height: 100%;
  position: fixed;
  background-color: #FF5722;
  width: 45%;
}
.orange-bg:after{
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  top: 20%;
  margin-top: -10px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #FF5722;
}

.form-group-login .fa-refresh{
  position: relative;
  bottom: -29px;
  font-size: 1.5em;
  z-index: 99;
  left: -10px;
}
.list-group-sm .list-group-item {
  padding: 5px 15px;
}

.list-group-p5.list-group-item{
  padding: 5px 15px;
}

/*======================
soporte DTE
========================*/
.ic .navbar-soporte{
  background: #C33F15;
}
.ic .navbar-soporte li a:hover{
  background: #FF5722 !important;
}
/*===================
  Monitor de Obra
=====================*/
.wrap-panel-bg{
  background-position: center center !important;
  background-size: cover !important;
  width: 100%;
  position: relative;
}
.wrap-panel-bg .gradient {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.wrap-panel-bg h3{
  color: #fff;
  text-decoration: none !important;
  font-size: 1.2em;
  margin: 0;
  position: relative;
  z-index: 99;
}
a:hover .wrap-panel-bg h3{
  text-decoration: none !important;
}
.panel-bg{
  height: 187px;
  min-height: 187px;
  position: relative;
  overflow: hidden;
}
.panel-content{
  background:#fff;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.btn-follow{
  border-radius: 999px;
  border: 1px solid #FF5722;
  color: #FF5722;
  padding: 1px 10px 2px 10px;
  font-size: 10px;
}
.panel-content p{
  margin: 0;
}
.gradient {
  left: 0;
  right: 0;
  top: 0;
  height: 110px;
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(30,87,153,0)),color-stop(60%,rgba(0,0,0,.5)));
  background: -o-linear-gradient(bottom,rgba(30,87,153,0) 0,rgba(0,0,0,.5) 60%);
  background: -ms-linear-gradient(bottom,rgba(30,87,153,0) 0,rgba(0,0,0,.5) 60%);
  background: linear-gradient(to top,rgba(30,87,153,0) 0,rgba(0,0,0,.5) 60%);
  position: absolute;
  width: 100%;
}
#panel-otras-obras td{
  height: 38px;
  font-size: 12px;
  vertical-align: middle;
}
.all-link{
  cursor: pointer;
}
.table-companies h3{
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
}
.table-companies .wrap-company-logo.wrap-company-logo-sm h3{
  font-size: 1em;
}
.table-companies p{
  color: #777;
  margin: 0;
  font-size: 0.9em;
}
/*Logo de empresas*/
.table-companies td.wrap-company-logo{
  width: 70px;
}
.table-companies .wrap-company-logo.wrap-company-logo-sm{
  width: 50px;
}
.table-companies td.wrap-company-logo2{
  width: 30px;
}
/*.table-companies .company-name{
  min-width: 500px;
}*/
.table-companies .company-kpi{
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  color: #777;
}
.table-companies .company-kpi .big{
  font-size: 25px;
}
.company-logo{
  padding: 10px;
  text-align: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border:1px solid #e8e8e8;
  border-radius: 2px;
  width: 70px;
  height: 70px;
  background-color: #fff;
  float: left;
}
.company-logo.company-logo-sm{
  width: 40px;
  height: 40px;
  padding: 8px;
}
.company-logo.company-logo-sm h1{
  font-size: 16px;
}
.company-logo h1{
  margin: 0;
  color: #FF5722;
  font-size: 35px;
}
.info-obra{
  padding: 10px;
}
.info-obra ul li.versalita{
  color: #777;
  margin-bottom: 1px;
}
.info-obra ul li{
  margin-bottom: 15px;
}
.wrap-images{
  padding-left: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 0;
  /*border-left: 1px solid #ddd;*/
}
.company-header {
padding-left: 15px;
}
.company-capital{
  padding: 10px;
  text-align: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border:1px solid #e8e8e8;
  border-radius: 2px;
  width: 13%;
  height: auto;
  background-color: #fff;
  float: left;
}
.company-capital h1{
  margin: 0;
  color: #FF5722;
  font-size: 4.8em;
}
.company-name {
  padding-left: 15%;
}
.company-name h1 {
  font-size: 3.5em;
}
.adicional h3 {
  font-size: 3.2em;
}
.detail-content-left{
  padding-right: 0;
  border-right: 1px solid #ddd;
}
.detail-content-right{
  border-left: 1px solid #ddd;
  padding-top: 15px;
  padding-right: 30px;
  margin-left: -1px;
}
.tab-content-bg {
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ddd;
  /*max-height: 70vh;
  min-height: 70vh;*/
  margin-top: 2px;
}
.tab-content-md {
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ddd;
  /*max-height: 60vh;
  min-height: 60vh;*/
  margin-top: 2px;
}
hr.detalle-obra {
  margin-top: 5px;
  margin-bottom: 10px;
}
.vertical {
  border-right: 1px solid #eee;
}
.vertical p {
  margin: 15px 0;
}
.progress-obra {
  margin-top: 20px;
}
.progress-obra span{
  font-weight: 300;
  font-size: 0.95em;
}
.tooltip{
  position:relative;
  float:right;
}
.tooltip > .tooltip-inner {
  /*background-color: #ff5722;
  padding:5px 15px;
  color:#fff;
  border-radius: 2px !important;
  font-size:13px;*/
}
.orange-tooltip + .tooltip > .tooltip-inner {
  background-color: #ff5722;
  padding:5px 15px;
  color:#fff;
  border-radius: 2px !important;
  font-size:13px;
}
.orange-tooltip + .tooltip > .tooltip-arrow {
  border-bottom-color: #ff5722;
}

.category-list h4 {
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: 500 !important;
}
.category-list li {
  padding: 5px 0;
}
.modal-right {
  background-color: #f8f8f8;
  width: 217px;
  height: 90vh;
  border-left: 1px solid #eee;
}
.modal-right-small {
  background-color: #f8f8f8;
  width: 140px;
  height: 35vh;
  border-left: 1px solid #eee;
  padding-top: 20px;

}
.table-borderless td,
.table-borderless th {
    border: 0 !important;
}
.edificio-thumb{
  text-align: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border:1px solid #e8e8e8;
  border-radius: 2px;
  width: 120px;
  height: auto;
  background-color: #fff;
  float: left;
}

.edificio-thumb2{
  text-align: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border:1px solid #e8e8e8;
  border-radius: 2px;
  width: 120px;
  /*height: auto;*/
  height: 100px;
  background-color: #fff;
  float: left;
}
/*===================
  Slide portal proveedores
=====================*/
#carousel-example-generic {
  display: inline-block;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%
}
.modal-carrusel{

}
.action-bar-footer{
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  padding: 15px;
}
/* Plugin styles */
ul.thumbnails-carousel {
  padding-left: 1px;
  margin: 0;
  list-style-type: none;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
ul.thumbnails-carousel .center {
  display: inline-block;
}
ul.thumbnails-carousel li {
  margin: 10px 5px 0 5px;
  /*float: left;*/
  cursor: pointer;
  display: inline-block;
  width: 100px;
  height: auto;
  text-align: center;
  border: 2px solid #ffffff;
}

.controls-background-reset {
  background: none !important;
}
.active-thumbnail {
  opacity: 0.4;
}
.indicators-fix {
  bottom: 70px;
}
.mini-side {
  display: block;
  width: 100%;
}
/*===================
  Slide Evidencia Recepciones
=====================*/
ul.thumbnails-carousel.miniatura{
  top: 10px;
  right: 10px;
  left: auto;
}
ul.thumbnails-carousel.miniatura li{
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
  display: table;
}

/*===================
  Crear oferta
=====================*/
/*
  Totalizador Fijo
*/
.total-fixed{
  position: fixed;
  bottom: 20px;
  background: #fff;
  border: 1px solid #eee;
  padding: 15px 20px;
  border-radius: 2px;
  right: 55px;
  z-index: 999;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.total-fixed h3{
  font-size: 10px;
  margin: 0;
  color: #777;
  text-align: right;
}
.total-fixed h2{
  margin: 0;
  font-size: 20px;
  text-align: right;
  font-weight: 700;
}
.total-fixed ul {
  min-width: 200px;
}

/*******
  Detalle con el panel de la izquierda collapsable
*******/
.panel-width-collapse-side{
  margin: 0;
  min-height: 660px;
}
.panel-width-collapse-side-orden {
  margin: 0;
  min-height: 850px;
}
.panel-width-collapse-side-recepcion-tercero {
  margin: 0;
  min-height: 950px;
}
.panel-width-collapse-side-in-table{
  margin: 0 0 30px 0;
  /*min-height: 650px;*/
}
.navbar-primary {
  bottom: 0px;
  left: 15px;
  position: absolute;
  top: 0px;
  width: 300px;
  z-index: 8;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /*border-right: 1px solid #ddd;*/
  padding: 15px;
  margin-left: -1px;
  margin-left: -1px;
}
.navbar-primary ul.datos{
  padding-bottom: 0;
}
.navbar-primary ul.datos li{
  padding: 2px 0;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}
.navbar-primary .label{
  font-size: 12px;
}
.content-collapse-side{
  padding-right: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.content-collapse-side h3.versalita{
  margin: 20px 0 5px 0;
}
.content-collapse-side .cabecera-editable h3.versalita{
  margin: 20px 0 10px 0;
}
/*.content-collapse-side .cabecera-editable:hover{
  color: #666;
  cursor: pointer;
}*/
.content-collapse-side .cabecera-editable span.mostrar {
  /*visibility: hidden;*/
  float: right;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.navbar-primary.collapsed {
  width: 31px;
}
.navbar-primary.collapsed .glyphicon {
  font-size: 22px;
}
.navbar-primary.collapsed .nav-label {
  display: none;
}
.btn-expand-collapse {
  position: absolute;
  display: block;
  top: 0;
  z-index: 8;
  right: 0px;
  bottom:0;
  padding: 3px 0;
  border-top:none;
  border-bottom:2px solid #ddd;
  border-left: 2px solid #FF5722;
  border-right: 2px solid #F8EAE7;
  color: #FF5722;
  font-size: 16px;
  text-align: center;
  width: 30px;
  background: #FFF;
  text-decoration: none;
  margin-right: -1px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 100%;
}
.btn-expand-collapse .fa{
  /*position: fixed;*/
  top:150px;
  -webkit-transition: -webkit-transform .45s ease;
  transition: transform .45s ease;
}
.collapsed .btn-expand-collapse .fa{
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .45s ease;
  transition: transform .45s ease;
}

.collapsed .btn-expand-collapse{
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
  background: #fff !important;
  color: #FF5722;
  text-decoration: none;
}
.btn-expand-collapse:active {
  background-color: #111;
}
.navbar-primary-menu,
.navbar-primary-menu li {
  margin:0; padding:0;
  list-style: none;
}
.collapsed .navbar-primary-menu{ display: none;}
/*******
  Detalle con el panel de la derecha collapsable
*******/
.panel .arrow-collapse img {
    max-width: 25px;
    margin: 0 auto -8px 20px;
    opacity: 1;
    left: 30;
}
.panel a.collapsed .arrow-collapse img{
    display: none;
    opacity: 0;
    /*margin: 13px auto -17px; downn */
    margin: -2px auto -9px;
}
/*******
  Cotizaciones > Oferta
*******/
.td-code{
  position: relative;
  min-width: 90px;
}
.btn-add-code{
  visibility: hidden;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.table-offer tr:hover .btn-add-code{
  visibility: visible;
}
.table-offer td.td-code {
  padding-bottom: 40px;
}
.main-content {
  margin-left: 300px;
}
.main-content.main-content-filters {
  margin-left: 300px;
}
.main-content.main-content-filters .main-content-inner{
  border-left:1px solid #ddd;
  margin-top:-1px;
  margin-left: -1px;
  padding: 0;
}

.main-content-inner{
  padding: 15px;
}
.collapsed + .main-content {
  margin-left: 30px;
}
.collapsed + .main-content.main-content-filters{
  margin-left: 0px;
}
td.offer{
  width: 250px;
  border-left: 1px solid #ddd;
  position: relative;
  padding: 0 !important;
}
td.offer input {
  margin-bottom: 5px;
}
td.offer .offer-padding{
  padding: 20px 5px 5px 5px;
}
.pleft0{
  padding-left: 0;
}
td.offer-oc {
  width: 215px;
  border-left: 1px solid #ddd;
  position: relative;
  padding: 0 !important;
}
td.offer-oc .offer-padding{
  padding: 10px 5px 5px 5px;
}
span.line{
  position: absolute;
  right: -1px;
  top: -1px;
  background: #fff;
  border: 1px solid #ddd;
  padding: 2px 4px;
  font-size: 8px;
  font-weight: 700;
}
span.line-mis-prod{
  position: absolute;
  left: 1px;
  top: -17px;
  /*background: #f5f5f5;
  border: 1px solid #ddd;*/
  padding: 2px 4px;
  color: #4c6079;
  font-size: 10px;
  font-weight: 700;
}
span.sin-stock{
  position: absolute;
  left: -1px;
  top: -1px;
  /*background: #f5f5f5;
  border: 1px solid #ddd;*/
  padding: 2px 4px;
  font-size: 16px;
  font-weight: 700;
}
span.code-arrow{
  position: absolute;
  bottom: 30px;
  width: 70px;
  background: #ff5722;
  padding: 4px 7px;
  left: 5%;
  color: #fff;
}
span.code-arrow:after {
  border-top: 10px solid #ff5722;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -5px;
  content: "";
  position: absolute;
  left: 20%;
  /*margin-left: -10px;*/
  width: 0;
  height: 0;
}
tr.with-span-line td{
  padding-top: 17px !important;
}
.td-prelative{
  position: relative;
  padding-bottom: 65px !important;
}
.td-prelative-oc{
  position: relative;
/*  padding-bottom: 20px !important;*/
}
.td-prelative .pabsolute{
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  padding-right: 5px;
  padding-left: 5px;
}

.td-prelative-oc .pabsolute {
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  padding-right: 5px;
  padding-left: 5px;
}
.td-prelative .pabsolute input{
  width: 100%;
}
span.line.success{
  color: #fff;
  background: #5cb85c;
  border-color: #5cb85c;
}
span.line.danger{
  color: #fff;
  background: #d9534f;
  border-color: #d9534f;
}
/*******
  Esta es para los items "checkbox oferta" y "Total Neto" que se muestra línea de la oferta (cotizaciones)
*******/
.total-in-table-offer{
  margin: 0;
  padding: 5px;
  width: 100%;
  list-style-type: none;
  border-top: 1px solid #ddd;
  margin-left: -1px;
  text-align: right;
}
.table-modal {
  margin-bottom: 30px;
}
ul.datos li.h35 {
  min-height: 35px;
}
ul.datos-right {
  list-style: none;
  padding: 0 0 20px 0;
  margin: 0;
}
ul.datos-right li {
  display: block;
  text-align: left;
  /*padding-bottom: 5px;
  margin-bottom: 5px;*/
/*  border-bottom: 1px solid #eee;*/
}
/*===================
  Detalle oferta
=====================*/
span.np {
  position: absolute;
  right: 30px;
  margin-top: -6px;
  color: #449d44;
  /* background-color: #E64A19; */
  background-color: #dff0d8;
  text-align: center;
  font-size: 10px;
  min-width: 14px;
  min-height: 14px;
  padding: 2px 5px;
  border: #449d44 1px solid;
  border-radius: 0px 0px 0px 5px;
}
span.expensive {
  position: absolute;
  right: 30px;
  margin-top: -6px;
  color: #d9534f;
  /* background-color: #E64A19; */
  background-color: #f2dede;
  text-align: center;
  font-size: 10px;
  min-width: 14px;
  min-height: 14px;
  padding: 2px 5px;
  border: #d9534f 1px solid;
  border-radius: 0px 0px 0px 5px;
}
.table-np {
  height: 19px;
}
.no-adjudicada {
  filter: blur(1px);
  opacity: 0.3;
}
.txt-no-adjudicada {
  text-align: center;
  position: absolute;
  width: 100%;
  margin-top: 5%;
  font-size: 2em;
  color: #d9534f;
}
.alert-success i.icon {
  font-size: 1.6em;
  padding-right: 10px;
}

/*Configuración*/
ul.menu-left li{
  font-size: 0.8em;
}
ul.menu-left li a{
  padding: 5px 15px;
}
ul.menu-left li.active a,
ul.menu-left li.active a:hover{
  background: #FF5722 !important;
  color: #fff;
}
ul.menu-left li.title{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-weight: bold;
  color: #777;
}

/*===================
  Ficha empresa
=====================*/
.panel-md {
  height: 110px;
  min-height: 110px;
}

/*===================
  Bandeja orden de compra
=====================*/
.table-docs ul.list-unstyled a {
  text-decoration: none;
  font-weight: 500;
}

/*===================
  un heading con posición fija o pin
=====================*/
.wrap-pin{
  z-index: 999 !important;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

/*Bloque sin datos para Portal Proveedores y su versión Free*/
.empty-data{
  background: #ddd;
  width: 60px;
  height: 15px;
  display: inline-block;
}

/*CUSTOM del circle o porcentaje de completitud de perfil*/
.pie, .c100 .bar, .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill{
  background: #47a447;
  border-color: #47a447;
}
.c100{
  background: #e8e8e8;
}
.c100.big{
  font-size: 210px;
}
.c100:after{
  background: #fff;
}
.c100 span{
  color: #47a447;
}
.c100:hover > span {
  color: #398439;
}

/*MODAL de inicio en dashboard*/
.modal#init .modal-dialog{
  margin-top: 100px;
}
.modal#init .modal-footer{
  margin-top: 0;
}
.modal-init .modal-backdrop.in{
  opacity: .95;
  background: #333;
}
.modal #wrap-carousel-init .carousel-control.left{
  left: 0 !important;
  width: 70px;
}
.modal #wrap-carousel-init .carousel-control.right{
  right: 0 !important;
  width: 70px;
}
#wrap-carousel-init .item{
  height: 400px;
  padding-left: 70px;
}
#wrap-carousel-init .item h2{
  padding-top: 30px;
  margin-bottom: 30px;
}
#wrap-carousel-init .carousel-indicators li{
  background: #FF5722;
}
#wrap-carousel-init .carousel-control{
  opacity: .8;
}
.init-image-1{
  /* background-image: url("../../img/bg-landing-registro.jpg"); */
  height: 400px;
  background-size: cover;
  background-position: center center;
}
.init-image-2{
  /* background-image: url("../../img/08.jpg"); */
  height: 400px;
  background-size: cover;
  background-position: center center;
}
.init-image-3{
  /* background-image: url("../../img/bg-landing-proveedores.jpg"); */
  height: 400px;
  background-size: cover;
  background-position: center center;
}

/*------- PLANES -------*/
#plans h3{
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 800;
}
.opacity{
  opacity: .6;
}
#plans ul{
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}
#plans ul li{
  font-size: 14px;
  padding-top: 13px;
  padding-bottom: 13px;
  list-style-type: none;
}
#plans ul li .fa{
  font-size: 2.4em;
  display: block;
  float: left;
  margin-top: -8px;
  margin-right: 10px;
  color: green;
}
#plans .well{
  background: #fff;
  min-height: 500px;
}
#plans .add-ons-side{
  border-left: 1px solid #ddd;
  min-height: 500px;
}
#plans .btn{
  margin-top: 10px;
}
#plans .add-ons-side li{
  margin: 0;
  padding: 5px;
  background: #f9f9f9;
  border-radius: 2px;
  font-size: 12px;
  border:1px dashed #ddd;
  color: #777;
  margin-bottom: 10px;
}
.comparision{
  visibility: hidden;
}
#plans .well:hover .comparision{
  visibility: visible;
}

/*------- Fila bloqueada para free -------*/
.blocked{
  color: #ccc !important;
  background: #e9e9e9;
}


#plans .well-white .btn{
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#plans .well-white:hover{
  border-color: #5cb85c;
}


/*-------

Wizard Accordeon
y Arriendo

 -------*/
.checkout-wrapper{
  padding-top: 40px;
  padding-bottom:40px;
  background-color: #fafbfa;
}
.checkout{
  background-color: #fff;
  font-size: 14px;
}
.checkout-step:first-child{
  border-top: none;
}
.checkout-step {
  border-top: 1px solid #eee;
  font-size: 14px;
  /*padding: 10px 15px;*/
  padding:10px 15px 10px 10px;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
}
.checkout-step-number{
  border-radius: 50%;
  border: 1px solid #FF5722;
  display: inline-block;
  font-size: 14px;
  height: 32px;
  margin-right: 10px;
  padding: 5px;
  text-align: center;
  width: 32px;
  color: #FF5722;
}
.checkout-step-number.ok{
  border: none;
  background: #5CB85C;
  color: #fff;
}
.checkout-step-title{
  font-size: 18px;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
  margin: 0px;
  text-decoration: none;
}
.checkout-step-body{
  padding-left: 46px;
  padding-top: 20px;
  margin-bottom: 10px;
}
.checkout .with-actions code.act{
  left: -43px;
  top: -9px;
  padding: 0;
  height: auto;
  border-right: 1px solid #ddd;
}
.checkout .with-actions code.act a {
    color: slategrey;
    /*margin: 12px 5px;*/
    padding: 7px 10px;
    display: block;
}
.checkout .with-actions code.act a:hover{
  opacity: .8;
}
.checkout .with-actions code.act a:last-child{
  border-top: 1px solid #ddd;
}
.title-section-form {
  border-bottom: none;
}
.panel .title-section-form h3{
  background-color: #fff;
}
.title-section-form h3  {
  background-color: #f9f9f9;
  display: inline-block;
  vertical-align: top;
  z-index: 2;
  position: relative;
  padding: 0 10px 0 15px;
  left: -15px;
  font-size: 10px;
  letter-spacing: 0.9px;
}

.title-section-form:before {
    content: "";
    background: #ddd;
    display: block;
    /*width: 100%;*/
    height: 1px;
    position: absolute;
    left: 0px;
    right: 15px;
    top: 30%;
    margin-top: -1px;
    z-index: 1;
}
.hover-options{
  visibility: hidden;
  font-size: 14px;
}
tr:hover .hover-options{
  visibility: visible;
}
.well-light-orange{
  background: #fdf8f8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}
.well-light-orange dd, .well-light-orange ul li {
  margin-bottom: 5px;
}

.well-light-orange hr{
  margin-top: 8px;
  margin-bottom: 8px;
}
.resumen-fijo{
  position: fixed;
  width: 18%;
}
.resumen-fijo .well-light-orange{
  background: #fff;
  /*margin-left: -31px;*/
  /*margin-top: 10px;*/
  border-radius:  3px;
  padding: 15px 10px;
  margin-bottom: 10px;
  border:1px solid #ddd;
  /*-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);*/
}
.col-form, .col-resumen  {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-form {
  width: 80%;
}
.col-resumen {
  width: 20%;
}
.bootstrap-touchspin .input-group-addon{
  border-left: none;
}
.stylish-input-group .input-group-addon{
    background: white !important;
}
.stylish-input-group .form-control{
  border-right:0;
  box-shadow:0 0 0;
  border-color:#ccc;
}
.stylish-input-group button{
    border:0;
    background:transparent;
}
.search-result{
  width: 100%;
  padding: 0;
  max-height: 300px;
  overflow-x: auto;
}
.search-result li {
  border-bottom: 1px solid #ddd;
  padding: 0px;
}
.search-result li:last-child {
  border-bottom: none;
}
.search-result li a{
  padding: 7px 15px 7px 10px;
}
.search-result li a strong{
  display: block;
}
.search-result li a .data-search{
  /*padding-right: 140px;*/
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
}
.input-cantidad .input-group{
   width: 70px;
   margin-right: 10px;
   float: left;;
}

/*-------
Nuevo timeline
 -------*/
.wrap-timeline{
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.timeline-item{
  border-left: 2px solid #f0906e;
  padding-bottom: 15px;
}
.timeline-item.last{
  border-left: 0;
}
.timeline-item.last span.box-time-orange{
  margin-left: -8px;
}
.timeline-item-content{
  background: #fdf8f8;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #777;
}
.timeline-item-content.approved{
  background: #f8eae7;
  color: #FF5722;
}
.timeline-item-content.approved .fa{
  font-size: 15px;
  color: #FF5722;
}
.timeline-item-content.approved p{
  color: #FF8A65;
}
.time-in-timeline{
  line-height: 1.9em;
  color: #FF5722;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 5px;
  margin-top: 0;
}
.timeline-item-content h3{
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}
span.box-time-orange{
  height: 18px;
  width: 18px;
  border-radius: 300px;
  background: #FF5722;
  border: 2px solid #fff;
  display: block;
  margin-left: -10px;
  float: left;
  margin-right: 10px;
  text-align: center;
}
.agregar-aprobador-hover {
    margin-left: 20px;
    text-align: center;
    position: relative;
}

.agregar-aprobador-hover a .hiddenbox{
  display: block;
  border: 2px dashed #ddd;
  border-radius: 3px;
  opacity: 0;
  transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;

  font-size: 1px;
  text-decoration: none;
  margin-left: 15px;
  padding:5px 5px;

}
.agregar-aprobador-hover:hover a .hiddenbox{
  opacity: 1;
  font-size: 14px;
  padding:15px 5px;
  margin: 10px 15px 15px 15px;
  transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
}
.agregar-aprobador-hover a i.icon-agregar{
  position: absolute;
  top: -13px;
  left: -1px;
  visibility: visible;
  opacity: 1;
  font-size: 16px;
  color: #5cb85c;
}
/*===================
  Configuraciones portal proveedores
=====================*/
.configuraciones .tab-content {
  border: 1px solid #ddd;
  border-top: none;
  padding: 40px 25px;
  background-color: #fff;
}
/*===================
Archivos adjuntos - Drag & drop files2
====================*/
.drag-drop-container.with-file{
  padding: 20px 15px;
}
.drag-drop-container.with-file:hover{
  border: 2px dashed #ced4dc;
  cursor: default !important;
}
.file-added {
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
}
.file-added .file-added-head{
  margin-bottom: 10px;
  text-align: center ;
}
.file-added .delete-file{
  margin-top: 2px;
}
.file-added a.delete-file {
  opacity: .7;
  color: #666;
  font-size: 12px;
}
.file-added a.delete-file:hover{
  opacity: 1;
}
.file-added .file-caption-info{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*width: 160px;*/
  height: auto;
  margin: auto;
  font-size: 12px;
  padding-right:20px;
}
.file-added .file-caption-info i{
  margin-right: 10px;
  font-size: 18px;
}


/*===================
  PORTAL PROVEEDORES V6
=====================*/

/*===================
acordiones
=====================*/
.accordion-toggle i.arrow{
  font-size: 18px;
}

a.accordion-toggle{
  color: #848280;
}

a.accordion-toggle:hover,
a.accordion-toggle:focus{
  text-decoration: none;
  color: #ff9f40;
}
.accordion-toggle.collapsed  i.arrow{
  float: right;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}
.accordion-toggle  i.arrow{
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}

.panel-heading.bg-white{
  background-color: #fff;
}

.panel.m0bottom-panel{
  margin-bottom: 2px;
}

.panel-comentarios-detalle{
  overflow-y: auto;
  min-height: 100px
}

.message-bubble {
  padding: 10px 0px 10px 0px;
}

.message-bubble:nth-child(even) {
  background-color: #F5F5F5;
}

.panel-title .icon-plus{
  font-size: 12px;
  color: #ff5722;
}

.link-icon-plus:hover,
.link-icon-plus:link{
  text-decoration: none;
}

.panel-default.sel-categ{
  border: none;
  webkit-box-shadow: none;
  box-shadow: none;
}

.icon-plus-categ{
  float: left;
  padding-right: 4px;
  padding-top: 3px;
}

.categ-check{
  float: left;
}

.categ-label{
  margin-left: 32px;
  display: block;
  font-weight: normal;
}

.subcateg-label{
  margin-left: 16px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 0px;
  display: block;
  font-weight: 300;
}


/*Ficha Monitor de Obra*/
.container-p30lr{
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.n-resize{
  resize: none;
}

.cursor-pointer{
  cursor: pointer;
}

/*Sección de las imágenes*/
.column {
  float: left;
  width: 8%;
  padding: 0px 10px 0px 0px;
}

.more-img-content{
  position: relative;
  display: inline-block;
  text-align: center;
}

.more-img{
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  color: #8D8D8D;
  font-weight: bold;
}

.w100{
  width: 100%;
}

/*Etapas de la obra*/
ul.secuencias-mo {
padding-left: 0;
margin-bottom: 0;
list-style: none;
}
ul.secuencias-mo li{
display: table-cell;
width: 1%;
}
ul.secuencias-mo li div.pasos-mo{
padding: 4px 3px 4px 20px;
background: #efefef;
font-size: 12px;
line-height: 15px;
margin-right: 3px;
position: relative;
}

ul.secuencias-mo li div.pasos-mo.active{
padding: 4px 3px 4px 20px;
background: #C3E5C3;
font-size: 12px;
line-height: 15px;
margin-right: 3px;
position: relative;
}

ul.secuencias-mo li  div.pasos-mo span {
display: block;
}
ul.secuencias-mo li div.pasos-mo:before {
width: 0;
height: 0;
border-top: 18px inset transparent;
border-bottom: 18px inset transparent;
border-left: 13px solid #fff;
position: absolute;
content: "";
top: 0;
left: 0;
}
ul.secuencias-mo li div.pasos-mo:after{
width: 0;
height: 0;
border-top: 18px inset transparent;
border-bottom: 18px inset transparent;
border-left: 13px solid #efefef;
position: absolute;
content: "";
top: 0;
right: -13px;
z-index: 2;
}

ul.secuencias-mo li div.pasos-mo.active:after{
width: 0;
height: 0;
border-top: 18px inset transparent;
border-bottom: 18px inset transparent;
border-left: 13px solid #C3E5C3;
position: absolute;
content: "";
top: 0;
right: -13px;
z-index: 2;
}

ul.secuencias-mo li:first-child div.pasos-mo:before , ul.secuencias-mo li:last-child div.pasos-mo:after{
border: none;
}

ul.secuencias-mo li:first-child div.pasos-mo{
border-radius: 4px 0px 0px 4px;
padding: 4px 3px 4px 10px;
}
ul.secuencias-mo li:last-child div.pasos-mo{
border-radius: 0 4px 4px 0;
}

/*=======================
@media print
=========================*/
@media print {
  .no-print {display:none}

  .no-offset-print{width: 100%; margin-left: 0%;}
  .print-bock{width: 100%}
  .block-right{ padding-left: 15px;}

  a[href]:after {content: none !important;}

  .navbar-fixed-top{display: none;}

  .page-header, .page-header-fixed{
    border-bottom: none;
  }

  .w100-m0-no-print{
    width: 100%;
    margin: 0px 0px 0px 20px;
    padding: 0;
  }

  .breadcrumb-no-border{
    border-bottom: none;
  }

  .w-noprint{
    width: 15%;
  }

  .nprint-paginador{
    visibility: hidden;
  }

  .ancho-print{
    width: 33%;
    float: left;
    color: #000;
  }

  .ancho-print50{
    width: 48%;
    float: left;
    color: #000;
  }

  .m-noprint{
    margin-top: 0px;
  }

  .f18-noprint{
    font-size: 18px;
  }

  #footer{
    border-top: none;
  }

  .f10-noprint{
    font-size: 10px;
  }

  .f11-noprint{
    font-size: 11px;
  }

}


/*
Monitor de Obra (MO)
date: 20/06/2019
*/

/*MO buscador*/
.id-class-header-table {

  display: none;
}

.id-class-tipo-mobile {

  margin-top:5px;
  font-size: 11px;

  }

  .id-class-tipo-mobile::before {
  content: "Tipo: ";
  }


  .id-class-ubicacion-mobile {

  margin-top:5px;
  font-size: 11px;

  }

  .id-class-ubicacion-mobile::before {
  content: "Ubicación: ";
  }

.id-class-etapa-mobile {
  margin-top:5px;
  font-size: 11px;

}

.id-class-etapa-mobile::before {
  content: "Etapa: ";
}

.id-class-fecha-publicado-mobile {

  margin-top:5px;
  font-size: 11px;

}

.id-class-fecha-publicado-mobile::before {
  content: "Última actividad: ";
}


.id-class-img-mobile {

  display: block;
}

.id-class-row-table {

  position: relative;
  padding-bottom: 10px;
  border-bottom: rgba(100,100,100,0.2) 1px solid;

}

.id-class-row-table div:first-child {
  margin-top: 10px;
  font-size: 14px;

}

.label.label-green {
  vertical-align: middle;
  font-size: 10px;
  background: #27b70f;
  color: #fff;
  border: none;
}

.label.label-warning {
  vertical-align: middle;
  font-size: 10px;
  background: #17a2b8;
  color: #fff;
  border: none;
}

@media (min-width: 768px) {

  .id-class-header-table {display: block;background-color:rgb(245, 245, 245);color: rgb(51, 51, 51);padding-top:5px;padding-bottom:5px;font-weight: bold;}

  .id-class-row-table {

      background-color: rgba(255,255,255, 1);
      border-bottom: rgba(100,100,100,0.2) 1px solid;
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: rgba(100,100,100,0.2) 1px solid;
  }

  .id-class-row-table div:first-child {
      font-size:unset;
      margin-top: 0px;

  }

  .id-class-row-table:hover {

      background-color: #f5f5f5;
  }

  .id-class-img-mobile {

      display: none;
  }

  .id-class-etapa-mobile {
      margin-top:0px;
      position:static;
      text-align: unset;

  }

  .id-class-etapa-mobile::before {

      content: '';

  }

  .id-class-ubicacion-mobile {
      margin-top:0px;
  }

  .id-class-ubicacion-mobile::before {
      margin-top:0px;
  content: " ";
  }

  .id-class-tipo-mobile {
      margin-top:0px;
  }

  .id-class-tipo-mobile::before {
  content: " ";
  }

  .id-class-fecha-publicado-mobile {

      position:static;
      text-align: unset;

  }

  .id-class-fecha-publicado-mobile::before {

      content: '';

  }

}


@media (min-width: 574px) {


.id-class-row-table {

background-color: rgba(255,255,255, 1);
border-bottom: rgba(100,100,100,0.2) 1px solid;
margin-bottom: 0px;
padding-bottom: 0px;
border-bottom: rgba(100,100,100,0.2) 1px solid;
}

.id-class-row-table div:first-child {
font-size:unset;
margin-top: 0px;

}

.id-class-row-table:hover {

background-color: #f5f5f5;
}

.id-class-img-mobile {

display: none;
}

.id-class-etapa-mobile {

position:static;
text-align: unset;

}

.id-class-etapa-mobile::before {

content: '';

}

.id-class-fecha-publicado-mobile {

position:static;
text-align: unset;

}

.id-class-fecha-publicado-mobile::before {

content: '';

}

}


.ic .page-header { margin-top: 0px;margin-bottom: 0px; }
/*
MO detalle
*/

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 20px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: 0px;
    cursor: pointer;
    background-color: #fff;
    background-clip:unset;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
    border-radius:10px;
}

.carousel-indicators .active {
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 0px;
    width: 20px;
    height: 3px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-radius: 10px;
    background-color: rgb(255,255,255, 1);
}

.id-class-mapa-mobile { display: block;}
    .id-class-mapa-descktop { display: none;}

    .label.label-green {
        vertical-align: middle;
        font-size: 10px;
        background: #27b70f;
        color: #fff;
        border: none;
    }

    .label.label-warning {
        vertical-align: middle;
        font-size: 10px;
        background: #17a2b8;
        color: #fff;
        border: none;
    }

    .pos-btn-contcatar {


  }

@media (min-width: 640px) {
    .pos-btn-contcatar {

        position: fixed;
        right:0px;
        top:-2px;
        z-index:1050;
    }

    .id-class-mapa-mobile { display: none;}
    .id-class-mapa-descktop { display: block;}


}

.img-proyecto-MO img {

    margin:0px auto;
    width: 100%;

}

