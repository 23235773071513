.tour-backdrop{
	/*background: #fff !important;*/
	opacity: 0.7 !important;
	background: #333;
}
.popover {
	box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
	border: none;
}
.popover.tour-tour{
	border-radius: 2px;
	min-width: 360px;
}
.tour-step-backdrop{
	border: none !important;
}
#thingtwo.tour-step-backdrop{
	background: #0079C1 !important;
}
/*Background de menú en header*/
#thing3 .tour-step-backdrop{
	background: #0079C1 !important;
}
.tour-step-background{
	border-radius: 2px;
}
.popover-content {
    font-weight: 400;
    padding: 30px 20px 15px 20px;
    color: #333;
}
.popover[class*="tour-"] .popover-navigation {
	padding: 15px 20px;
}
.popover-navigation .btn-group{
	left: 95px;
}
.popover-navigation .btn-group .btn {
	font-size: 12px;
	font-weight: 500;
}
.popover-navigation .btn-group .disabled{
	border-color: #ccc;
	font-weight: 400;
	opacity: 0.4;
}
.btn-end{
	border: none;
}
.tour-tour .popover[class*="tour-"] .tour-tour .popover-navigation *[data-role="end"] {
    float: left;
}
.tour-tour .popover-title{
	padding: 12px 20px;
	font-size: 2rem;
}
.popover button.close{
	/*border: 1px solid;*/
    border-radius: 50%;
    font-weight: 300;
    line-height: 0.5;
    margin-right: 15px;
    margin-top: 10px;
}
.tour-tour .popover button.close:hover{
	color: #888;
}
.popover[class*="tour-"] .popover-navigation *[data-role="end"]{
	float: left !important;
	color: #999;
}
.tour-tour p.lead{
	font-size: 18px;
	margin-bottom: 7px;
}

