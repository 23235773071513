@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#loader-iconstruye {
  position: relative;
  z-index: 99999;
  bottom: 0px;
  background: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 999px;
  color: #FF5722;
  padding: 10px;
  width: 130px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 13px;
  line-height: 0;
}
#loader-iconstruye span{
  vertical-align: top;
  padding-top: 12px;
  display: inline-block;
  font-weight: bold;
}
#loader-iconstruye img{
  width: 22px;
  margin-right: 10px;
  display: inline-block;
}



/*Loader Cubos*/

div#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #aaaaaa;
  background-color: rgba(170,170,170,0.3);
}

div#loader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  text-align: center;
}

div#loader > div > div {
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  -moz-animation: pulse 0.9s infinite ease-in-out;
  -o-animation: pulse 0.9s infinite ease-in-out;
  -webkit-animation: pulse 0.9s infinite ease-in-out;
  animation: pulse 0.9s infinite ease-in-out;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

div#loader > div > div.bubble1 {
  width: 30px;
  height: 30px;
  background: #fd6510;
  display: inline-block;
  border-radius: 100px;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

div#loader > div > div.bubble2 {
  width: 30px;
  height: 30px;
  background: #6dbe68;
  display: inline-block;
  border-radius: 100px;
  -moz-animation-delay: 0.15s;
  -o-animation-delay: 0.15s;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

div#loader > div > div.bubble3 {
  width: 30px;
  height: 30px;
  background: #4f90c2;
  display: inline-block;
  border-radius: 100px;
  -moz-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes foldCubeAngle {
  0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      -moz-transform: perspective(140px) rotateX(-180deg);
      -ms-transform: perspective(140px) rotateX(-180deg);
      -o-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
  }

  25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0);
      -moz-transform: perspective(140px) rotateX(0);
      -ms-transform: perspective(140px) rotateX(0);
      -o-transform: perspective(140px) rotateX(0);
      transform: perspective(140px) rotateX(0);
      opacity: 1;
  }

  90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      -moz-transform: perspective(140px) rotateY(180deg);
      -ms-transform: perspective(140px) rotateY(180deg);
      -o-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
  }
}

@-moz-keyframes foldCubeAngle {
  0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      -moz-transform: perspective(140px) rotateX(-180deg);
      -ms-transform: perspective(140px) rotateX(-180deg);
      -o-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
  }

  25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0);
      -moz-transform: perspective(140px) rotateX(0);
      -ms-transform: perspective(140px) rotateX(0);
      -o-transform: perspective(140px) rotateX(0);
      transform: perspective(140px) rotateX(0);
      opacity: 1;
  }

  90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      -moz-transform: perspective(140px) rotateY(180deg);
      -ms-transform: perspective(140px) rotateY(180deg);
      -o-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      -moz-transform: perspective(140px) rotateX(-180deg);
      -ms-transform: perspective(140px) rotateX(-180deg);
      -o-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
  }

  25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0);
      -moz-transform: perspective(140px) rotateX(0);
      -ms-transform: perspective(140px) rotateX(0);
      -o-transform: perspective(140px) rotateX(0);
      transform: perspective(140px) rotateX(0);
      opacity: 1;
  }

  90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      -moz-transform: perspective(140px) rotateY(180deg);
      -ms-transform: perspective(140px) rotateY(180deg);
      -o-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
  }
}

div#loader-cube {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff !important;
  opacity: 0.3 !important;
  filter: alpha(opacity=30) !important;
  z-index: 999999999999;
}

div#loader-cube > div.cont {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}

div#loader-cube > div.cont > div[class^="cube"] {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

div#loader-cube > div.cont > div[class^="cube"]:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FF5722;
  -moz-animation: foldCubeAngle 2.4s infinite linear both;
  -o-animation: foldCubeAngle 2.4s infinite linear both;
  -webkit-animation: foldCubeAngle 2.4s infinite linear both;
  animation: foldCubeAngle 2.4s infinite linear both;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

div#loader-cube > div.cont > div.cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -moz-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotateZ(90deg);
  -o-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

div#loader-cube > div.cont > div.cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -moz-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotateZ(180deg);
  -o-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

div#loader-cube > div.cont > div.cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -moz-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotateZ(270deg);
  -o-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

div#loader-cube > div.cont > div.cube2:before {
  -moz-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

div#loader-cube > div.cont > div.cube3:before {
  -moz-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

div#loader-cube > div.cont > div.cube4:before {
  -moz-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}